import { GetterTree } from "vuex";
import { State } from "./state";

export default {
  location: (state: State) => state.location,
  locationInitData: (state: State) => state.locationInitData,
  tenantLocations: (state: State) => state.tenantLocations,
  locationCount: (state: State) => state.locationCount,
  policyPreferenceList: (state: State) => state.policyPreferenceList
} as GetterTree<State, any>;
