/* ============
 * Mutation  for the Auth module
 * ============
 *
 * The mutation types that are available
 * on the Auth module.
 */

import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";
import Vue from "vue";
import constants from "@/constants/app.properties";

export default {
  [types.LOGIN_INIT](state: State) {
    state.isAuthenticated = false;
  },
  [types.LOGIN_SUCCESS](state: State, response: any) {
    state.isAuthenticated = true;
    state.loginErrMsg = "";
    localStorage.setItem(constants.USER_ID, response.user.id);
    localStorage.setItem(constants.IS_FIRST_TIME_USER, response.user.is_password_changed);
    localStorage.setItem(constants.IS_MEDICAL_REP, response.user.is_medical_rep || "");
    localStorage.setItem(constants.TENANT_ID, response.user.tenantId);
    if (response.user.userTypesId === 2 && !response.user.is_medical_rep) {
      localStorage.setItem(constants.LOCATION_ID, response.user.locationId);
    }
    state.currentUser = response.user;
    const permissions = response.user.roleAccess;
    state.rolePermissions = Object.assign(permissions, state.defaultAcces);

  },
  [types.LOGIN_FAIL](state: State) {
    state.isAuthenticated = false;
  },

  [types.VERIFY_TOKEN_SUCCESS](state: State, response: any) {
    const authToken = response.accessToken;
    state.isAuthenticated = true;
    if (authToken) {
      state.authToken = "Bearer " + authToken;
      localStorage.setItem(constants.AUTH_TOKEN, state.authToken);
      localStorage.setItem(constants.REFRESH_TOKEN, response.refreshToken);
      Vue.prototype.$http.defaults.headers.common.Authorization = state.authToken;
    }
  },

  [types.LOGOUT_SUCCESS](state: State) {
    state.isAuthenticated = false;
    state.authToken = "";
    localStorage.removeItem(constants.AUTH_TOKEN);
    localStorage.removeItem(constants.REFRESH_TOKEN);
    localStorage.removeItem(constants.USER_ID);
    localStorage.removeItem(constants.IS_FIRST_TIME_USER);
    localStorage.removeItem(constants.IS_MEDICAL_REP);
    localStorage.removeItem(constants.LOCATION_ID);
    localStorage.removeItem(constants.TENANT_ID);
    localStorage.removeItem("isEmailUser");
    localStorage.removeItem("emmailUserMeetingId");
    state.currentUser = {};
    Vue.prototype.$http.defaults.headers.common.Authorization = "";
  },

  [types.REGISTER_USER_SUCCESS](state: State) {
    state.user = state.registerUserInitialData;
  },
  [types.REGISTER_USER_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.INIT_REGISTER_USER](state: State) {
    state.user = state.registerUserInitialData;
  },

  [types.SET_USER](state: State, user: any) {
    state.user = user;
  },

  [types.GET_PREFERENCE_SUCCESS](state: State, response: any) {
    const data = response;
    let speciality = "";
    data.UserSpeciality && data.UserSpeciality.forEach((item: any, index: any) => {
      speciality += item.speciality_name + (data.UserSpeciality.length - 1 !== index ? "," : '');
    });
    state.user = { ...response, speciality };
  },
  [types.GET_PREFERENCE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.PREFERENCE_UPDATE_SUCCESS](state: State, data: any) {
    state.currentUser.first_name = data.first_name;
    state.currentUser.last_name = data.last_name;
    state.currentUser.email = data.email;
  },
  [types.PREFERENCE_UPDATE_FAIL](state: State, error: any) {
    state.error = error;
  },

  [types.GET_PENDING_USERS_SUCCESS](state: State, response: any) {
    state.pendingUsers = response;
  },
  [types.GET_PENDING_USERS_FAIL](state: State, error: any) {
    state.error = error;
  },

  [types.BULK_UPDATE_SUCCESS]() {
    //
  },
  [types.BULK_UPDATE_FAIL](state: State, error: any) {
    state.error = error;
  },



  [types.PASSWORD_RESET_SUCCESS](state: State, response: any) {
    state.passwordReset = response;
  },
  [types.PASSWORD_RESET_FAIL](state: State, error: any) {
    state.error = error;
  },

  [types.SET_CURRENT_USER](state: State, response: any) {
    state.currentUser = response;
    const permissions = response.roleAccess;
    state.rolePermissions = Object.assign(permissions, state.defaultAcces);
  },

  [types.SET_AUTHENTICATED](state, authenticated) {
    state.isAuthenticated = authenticated;
  },

  [types.SET_CURRENT_MODULE_PERMISSIONS](state, { currentModule, modulePermissions }) {
    state.currentModule = currentModule;
    state.modulePermissions = modulePermissions;
  },

  // subscription
  [types.GET_BILLING_HISTORY_BY_ID_SUCCESS](state: State, response: any) {
    state.billingHistories = response;
  },
  [types.GET_BILLING_HISTORY_BY_ID_FAIL](state: State, error: any) {
    state.error = error;
  },

  [types.GET_SUBSCRIPTIONS_PLANS_SUCCESS](state: State, response: any) {
    state.subscriptionPlans = response;
  },
  [types.GET_SUBSCRIPTIONS_PLANS_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_SUBSCRIPTION_PLAN_SUCCESS](state: State, response: any) {
    state.selectedPlan = response;
  },
  [types.GET_SUBSCRIPTION_PLAN_FAIL](state: State, error: any) {
    state.error = error;
  },

  [types.ADD_SUBSCRPTION_PLAN_SUCCESS](state: any, response: any) {
    state.currentUser.subscription_end_date = response.subscription_end_date;
  },
  [types.ADD_SUBSCRPTION_PLAN_FAIL](state: State, error: any) {
    state.error = error;
  },

  [types.GET_USERS](state: State, users: any) {
    state.users = users;
  },
  [types.GET_USERS_COUNT](state: State, userCount: any) {
    state.userCount = userCount.count;
  },
  [types.REP_ACTIVITES_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_USERS_FOR_TRANSFER_SUCCESS](state: State, response: any) {
    state.allUsers = response;
  },
  [types.GET_HEALTH_CARE_DOMAINS](state: State, response: any) {
    state.healthcareDomains = response;
  },
  [types.CREATE_SUBSCRIPTION_AGREEMENT_AUDIT_SUCCESS](state: State, response) {
    state.currentUser = { ...state.currentUser, ...response };
  },
  [types.CREATE_SUBSCRIPTION_AGREEMENT_AUDIT_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.SHOW_LICENSE_INFO_MODAL](state: State) {
    state.showUnSubscribeModal = true;
    state.isSubscribed = false;
  },
  [types.CLOSE_LICENSE_INFO_MODAL](state: State) {
    state.showUnSubscribeModal = false;
  },
  [types.UPDATE_USER_DETAILS](state: State, response: any) {
    state.currentUser = response.user;
    const permissions = response.user.roleAccess;
    state.rolePermissions = Object.assign(permissions, state.defaultAcces);
  },
  [types.SHOW_DEFAULT_TENANT_BLOCKED_MODAL](state: State) {
    state.isCurrentTenantBlocked = true
  },
  [types.CLOSE_DEFAULT_TENANT_BLOCKED_MODAL](state: State) {
    state.isCurrentTenantBlocked = false
  },
  [types.SET_SUBSCRIPTION_TRUE](state: State) {
    state.isSubscribed = true;
  },
  [types.CREATE_ORDER_SUCCESS](state: State, response: any) {
    state.order = response.order;
  },
  [types.CREATE_ORDER_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_PRACTICE_LOCATIONS_BY_USER](state: State, response: any) {
    state.practiceLocationsByUser = response;
  },
  [types.GET_ORGANIZATION_TYPES_SUCCESS](state: State, response: any) {
    state.organizationTypes = response;
  },
  [types.SET_USER_VACCINATION_STATUS](state: State, response: any) {
    state.currentUser.vaccination_status = response.vaccination_status;
    state.currentUser.doses = response.doses;
  },
  [types.GET_SETTING_VALUE_SUCCESS](state: State, response: any) {
    state.settingValue = response;
  },
  [types.GET_SETTING_VALUE_FAIL](state: State, error: any) {
    state.settingValue = '';
    state.error = error;
  },
  [types.GET_POWERBI_SUCCESS](state: State, response: any) {
    state.powerbiRes = response;
  },
  [types.GET_POWERBI_FAIL](state: State, error: any) {
    state.powerbiRes = '';
    state.error = error;
  },
  [types.GET_POWERBI_REPORT_SUCCESS](state: State, response: any) {
    state.powerbiReports = response;
  },
  [types.GET_POWERBI_REPORT_FAIL](state: State, error: any) {
    state.powerbiReports = [];
    state.error = error;
  },
  [types.SHOW_HIPAA_AGREEMENT_MODAL](state: State) {
    state.isShowHIPAAAgreementModal = true;
  },
  [types.HIDE_HIPAA_AGREEMENT_MODAL](state: State) {
    state.isShowHIPAAAgreementModal = false;
  },
  [types.SHOW_VERIFICATION_MODAL](state: State) {
    state.showVerificationModal = true;
  },
  [types.HIDE_VERIFICATION_MODAL](state: State) {
    state.showVerificationModal = false;
  },
} as MutationTree<State>;
