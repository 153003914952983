


































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import moment from "moment";

@Component({
  components: {},
})
export default class SubscriberForm extends Vue {
  @Action public createUser: any;
  @Action public initRegisterUser: any;
  @Action public setUser: any;
  @Action private getUserById: any;
  @Action private updateUser: any;
  @Action public getLocationDropdown: any;
  @Getter public user: any;
  @Getter public locations: any;
  @Getter public currentUser: any;
  action = "add";
  isSameAsEmail = false;
  isSubmitted = false;
  $refs!: {
    userForm: InstanceType<typeof ValidationObserver>;
  };
  secondaryLocations: any = [];

  beforeMount() {
    this.setUser({});
    this.initRegisterUser();
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    const userId = this.$route.params.id;
    if (userId) {
      this.action = "edit";
      this.getUserById({
        id: userId,
        filter: {
          include: [
            {
              relation: "allotedLocations",
              scope: {
                include: [{ relation: "tenantLocation" }],
              },
            },
            {
              relation: "tenant_location",
            },
          ],
        },
        callBack: this.constructSecondaryLocations,
      });
    }
  }

  constructSecondaryLocations() {
    this.user.allotedLocations.forEach((location: any) => {
      this.secondaryLocations.push({
        id: location.tenantLocationId,
        name: location.tenantLocation.name,
      });
    });
  }

  destroyed() {
    this.initRegisterUser();
  }

  onChangeCheckbox(value: boolean): void {
    if (value && this.user.email) {
      this.setUser(this.user);
    }
    setTimeout(() => {
      this.isSameAsEmail = value;
    }, 10);
  }

  onChangeEmail(value: string): void {
    this.user.email = value;
    if (this.isSameAsEmail) {
      this.setUser(this.user);
    }
  }

  setSecondaryLocations(value: any) {
    this.secondaryLocations = value;
  }

  setLocation(value: string | number | null) {
    this.user.locationId = value;
    if (value) {
      this.user.location = this.locations.filter((location: {id: number}) => location.id == value)[0].name
    }
    
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.userForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.userForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.userForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        if (this.action === "add") {
          delete this.user.confirmPassword;
          this.user.tenantId = this.currentUser.tenantId;
          this.user.is_medical_rep = false;
          this.user.is_password_changed = false;
          this.user.userTypesId = 6;
          this.user.status = 1;
          this.user.created_at = moment().format();
          this.user.allotedLocations = this.secondaryLocations;
          this.createUser({data: this.user, callback: () => { this.$router.push({ name: "subscribers.index" }).catch(); }});
        } else {
          if (this.user.address === null) {
            this.user.address = "";
          }
          const {
            id,
            first_name,
            last_name,
            email,
            phone,
            locationId,
            address,
          } = this.user;
          const allotedLocations = this.secondaryLocations;
          this.updateUser({
            data: {
              id,
              first_name,
              last_name,
              email,
              phone,
              locationId,
              address,
              allotedLocations,
            },
            callback: () => {
              this.$router.push({ name: "subscribers.index" }).catch();
            },
          });
        }
      }
    });
  }

  onCancel(): void {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.$router.push({ name: "subscribers.index" }).catch();
      },
      () => {}
    );
  }
}
