/**
 * ==============
 * Initial state of Policy module
 */
export class State {
  public tenantPolicyInitialData = {
    policy_name: "",
    is_input_required: false,
    input_value: ""
  };
  public tenantPolicy = this.tenantPolicyInitialData;
  public tenantPolicies: any = [];
  public error: any;
  public tenantPoliciesLoading = false;
  public policiesCount = 0;
}
