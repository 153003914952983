import Vue from "vue";
import { TYPE } from "vue-toastification";
import ToasterComponent from "@/components/toaster/Toaster.vue";
import constants from "@/constants/app.properties";

export interface IToasterData {
  message: string;
  type: string;
  noAutoClose?: boolean;
}


export default {
  name: "AlertMixin",
  /**
   * The methods that the mixin used for alert functions.
   */
  methods: {
    conFirmAlert(content: any, onPrimaryCb: any, onSecondaryCb: any): void {
      Vue.prototype.$eventBus.$emit(constants.CONFIRM_ALERT, { content, onPrimaryCb, onSecondaryCb });
    },
    appToaster(data: IToasterData): void {
      const content = {
        component: ToasterComponent,
        props: {
          message: data?.message,
          type: data.type
        }
      }
      Vue.$toast.clear();
      Vue.$toast(content, {
        type: (TYPE as any)[data.type],
        timeout: data.noAutoClose ? false : data.type === 'SUCCESS' ? 3000 : 5000
      });
    }
  }
};
