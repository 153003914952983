
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

  [types.INIT_NEW_LOCATION](state: State) {
    state.locationTimeslot = state.locationTimeslotInitData;
  },
  [types.CREATE_NEW_LOCATION_SUCCESS](state: State, response: any) {
    state.locationTimeslot = response.data;
  },
  [types.GET_LOCATION_LIST_SUCCESS](state: State, response: any) {
    state.tenantLocationTimeslots = response;
  },
  [types.CREATE_NEW_LOCATION_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_LOCATION_BY_ID_SUCCESS](state: State, response: any) {
    state.locationTimeslot = response;
  },
  [types.GET_LOCATION_BY_ID_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.LOCATION_UPDATE_SUCCESS](state: State, response: any) {
    state.locationTimeslot = response;
  },
  [types.LOCATION_UPDATE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_LOCATION_COUNT](state: State, response: any) {
    state.locationTimeslotCount = response.count;
  },
  [types.SET_LOCATION_TIMESLOT](state: State, locationTimeslot: any) {
    state.locationTimeslot = locationTimeslot;
  },

} as MutationTree<State>;