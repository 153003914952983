


































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";

@Component({})
export default class VerificationForm extends Vue {
  @Action private sendVerificationCode: any;
  @Action private verifyVerificationCode: any;
  @Action private sendProfileVerificationCode: any;
  @Action private verifyProfileVerificationCode: any;
  @Getter public isAuthenticated: any;
  @Action private logout: any;

  @Prop({ default: "" }) public verificationMail!: string;
  @Prop({ default: false }) public verificationMailEntered!: boolean;

  verificationCode = "";

  sendCode() {
    let emailExpression = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (emailExpression.test(this.verificationMail)) {
      const data: any = {
        type: "email",
        identifier: this.verificationMail,
      };
      if (this.isAuthenticated) {        
        this.sendProfileVerificationCode({
          data: data,
          callback: () => {
            this.verificationMailEntered = true;
          },
        });
      } else {
        this.sendVerificationCode({
          data: data,
          callback: () => {
            this.verificationMailEntered = true;
          },
        });
      }
    } else {
      this.verificationCode = "";
      alert.methods.appToaster({
        message: "Enter a valid email id.",
        type: "ERROR",
      });
    }
  }

  verifyCode() {
    if (this.verificationCode !== "") {
      let data = {
        type: "email",
        identifier: this.verificationMail,
        verification_code: this.verificationCode
      };
      if (this.isAuthenticated) {
        this.verifyProfileVerificationCode({
          data: data,
          callback: () => {
            this.$emit("mailVerified", this.verificationMail);
          },
        });
      } else {
        this.verifyVerificationCode({
          data: data,
          callback: () => {
            this.$emit("mailVerified", this.verificationMail);
          },
        });
      }
    } else {
      alert.methods.appToaster({
        message: "Enter a valid code.",
        type: "ERROR",
      });
    }
  }

  formatMaxLength(e: number) {
    if (e.toString().length < 12) {
      return e;
    } else {
      return e.toString().slice(0, 12);
    }
  }

  goToLogin() {
    this.$router.push({ path: `/login` }).catch();
  }

  backToLogin() {
    this.logout();
  }
}
