import Proxy from "./Proxy";

class LocationTimeslotProxy extends Proxy {
  /**
   * Constructor for Location Timeslot proxy
   */
  constructor() {
    super(`/locationtimeslots`);
  }

  public updateLocationTimeslot(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined,body);
  }

  public getLocationTimeslots(data: any) {
    return this.getList(data);
  }

  public createLocationTimeslot(data: any) {
    return this.submit("post", `${this.endpoint}`,undefined, data);
  }

  public getListCount(data: any) {
    return this.submit("get", `locationtimeslots/count`,  {where: JSON.stringify(data)});
  }

  public deleteLocationTimeslot(data: any) {
    const id = data.id;
    // let body: any = JSON.stringify(data);
    // body = JSON.parse(body);
    delete data.id;
    return this.submit("delete", `${this.endpoint}/${id}`, undefined,{ data: data });
  }

}

export default LocationTimeslotProxy;
