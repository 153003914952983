
import keyMirror from "keymirror";

export default keyMirror({
  INIT_NEW_RESTAURANT: null,
  CREATE_NEW_RESTAURANT_INIT: null,
  CREATE_NEW_RESTAURANT_SUCCESS: null,
  CREATE_NEW_RESTAURANT_FAIL: null,
  GET_RESTAURANT_LIST_INIT: null,
  GET_RESTAURANT_LIST_SUCCESS: null,
  GET_RESTAURANT_LIST_FAIL: null,
  GET_RESTAURANT_BY_ID_INIT: null,
  GET_RESTAURANT_BY_ID_SUCCESS: null,
  GET_RESTAURANT_BY_ID_FAIL: null,

  RESTAURANT_UPDATE_INIT: null,
  RESTAURANT_UPDATE_SUCCESS: null,
  RESTAURANT_UPDATE_FAIL: null,
  GET_RESTAURANT_COUNT: null,
  SET_RESTAURANT: null,
  GET_POLICY_PREFERENCE_LIST_BY_RESTAURANT_NAME_SUCCESS: null,
  GET_POLICY_PREFERENCE_LIST_BY_RESTAURANT_NAME_FAIL: null
});