import Proxy from "./Proxy";

class TenantProxy extends Proxy {
    /**
     * Constructor for Tenant proxy
     */
    constructor() {
        super(`/tenant`);
    }

    public getTenants(filter: any) {
        return this.submit("get", this.endpoint, {filter: JSON.stringify(filter)});
    }
}

export default TenantProxy;
