/* ============
 * Mutation  for the Tenant Preference module
 * ============
 *
 * The mutation types that are available
 * on the Tenant Preference module.
 */

import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";
import array from '@/utils/array';

export default {
  [types.TENANT_PREFERENCE_CREATE_INIT](state: State) {
    state.tenantPreferencesLoading = true;
  },
  [types.TENANT_PREFERENCE_CREATE_SUCCESS](state: State) {
    state.tenantPreferencesLoading = false;
    state.tenantPreference = state.tenantPreferenceInitialData;
  },
  [types.TENANT_PREFERENCE_CREATE_FAIL](state: State, error: any) {
    state.tenantPreferencesLoading = false;
    state.error = error;
  },

  [types.TENANT_PREFERENCE_UPDATE_INIT](state: State) {
    state.tenantPreferencesLoading = true;
  },
  [types.TENANT_PREFERENCE_UPDATE_SUCCESS](state: State) {
    state.tenantPreferencesLoading = false;
    state.tenantPreference = state.tenantPreferenceInitialData;
  },
  [types.TENANT_PREFERENCE_UPDATE_FAIL](state: State, error: any) {
    state.tenantPreferencesLoading = false;
    state.error = error;
  },

  [types.GET_TENANT_PREFERENCE_INIT](state: State) {
    state.tenantPreference = state.tenantPreferenceInitialData;
    state.tenantPreferencesLoading = true;
  },
  [types.GET_TENANT_PREFERENCE_SUCCESS](state: State, response: any) {
    state.tenantPreferencesLoading = false;
    state.tenantPreference = response;
  },
  [types.GET_TENANT_PREFERENCE_FAIL](state: State, error: any) {
    state.tenantPreferencesLoading = false;
    state.tenantPreference = state.tenantPreferenceInitialData;
    state.error = error;
  },

  [types.FETCH_TENANT_PREFERENCE_LIST](state: State) {
    state.tenantPreferencesLoading = true;
  },
  [types.FETCH_TENANT_PREFERENCE_LIST_SUCCESS](state: State, response: any) {
    state.tenantPreferencesLoading = false;
    state.tenantPreferences = response;
  },
  [types.FETCH_TENANT_PREFERENCE_LIST_FAIL](state: State, error: any) {
    state.tenantPreferencesLoading = false;
    state.error = error;
    state.tenantPreferences = [];
  },
  [types.SET_TENANT_PREFERENCE](state: State, policy: any) {
    state.tenantPreference = policy;
  },

  [types.INIT_TENANT_PREFERENCE](state: State) {
    state.tenantPreference = state.tenantPreferenceInitialData;
  },


  [types.GET_PREFERENCES_COUNT_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_PREFERENCES_COUNT_SUCCESS](state: State, preferencesCount: any) {
    state.preferencesCount = preferencesCount.count;
  },
  [types.DELETE_TENANT_PREFERENCE_INIT](state: State) {
    state.tenantPreferencesLoading = true;
  },
  [types.DELETE_TENANT_PREFERENCE_SUCCESS](state: State, id: any) {
    state.tenantPreferencesLoading = false;
    state.tenantPreferences.splice(array.findIndex(state.tenantPreferences, { id }), 1);
  },
  [types.DELETE_TENANT_PREFERENCE_FAIL](state: State, error: any) {
    state.tenantPreferencesLoading = false;
    state.error = error;
  },

  
} as MutationTree<State>;
