import keyMirror from "keymirror";

export default keyMirror ({
    INIT_NEW_MEDICAL_REP: null,
    GET_MEDICAL_REP_BY_ID_INIT: null,
    GET_MEDICAL_REP_BY_ID_SUCCESS: null,
    GET_MEDICAL_REP_BY_ID_FAIL: null,

    MEDICAL_REP_UPDATE_INIT: null,
    MEDICAL_REP_UPDATE_SUCCESS: null,
    MEDICAL_REP_UPDATE_FAIL: null
});
