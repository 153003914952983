/**
 * ================
 * Object mixin
 * ===============
 */
import { isEmpty, clone } from "@/utils/object";

export default {
  /**
   * The methods that the mixin used for object functions
   *
   */
  methods: {
    isEmpty,
    clone
  }
};
