

























































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import LetterIcon from "../../components/letterIcon/LetterIcon.vue";
import MessageList from "../../components/messages/MessageList.vue";
import { Action, Getter } from "vuex-class";
import moment from "moment";
import _permissions from "@/constants/_permissions";
import alert from "@/mixins/alert";
import constants from "@/constants/_message";

@Component({
  components: {
    LetterIcon,
    MessageList,
  },
})
export default class Messages extends Vue {
  @Action public getMessageParticipants: any;
  @Getter public messageParticipants: any;
  @Action public getMessages: any;
  @Action public getUsers: any;
  @Getter public newUsersContactList: any;
  @Action public createMessage: any;
  @Getter public messages: any;
  @Action public getUnreadTotalCount: any;
  @Action private createMessageParticpants: any;
  @Getter private role!: any;
  @Getter public repName: any;
  @Action public setRepName: any;
  @Action public startLoading: any;
  @Action public endLoading: any;
  @Getter public rolePerm: any;
  @Getter public currentUser: any;
  perm = _permissions;

  searchText = "";
  userFilter: any = {
    limit: 999,
    skip: 0,
    where: {},
    order: ["updated_at DESC"],
  };
  previousDate = "";

  messagesFilter: any = {
    limit: 20,
    skip: 0,
    fields: {
      updated_by: false,
      created_by: false,
      updated_at: false,
      read_status: false,
    },
    order: ["updated_at DESC"],
    where: {},
  };
  messageList: any = [];

  showContacts = false;
  message = "";
  selectedChat: any = null;
  isRefreshList = false;
  mostRecentTo: any = [];
  mostRecentFrom: any = [];

  beforeMount() {
    if (this.repName !== "") {
      this.startLoading();
      this.searchText = this.repName;
      this.onChangeFilterInput();
      setTimeout(() => {
        if (this.messageParticipants && this.messageParticipants.length !== 0) {
          this.getMessagesList(this.messageParticipants[0]);
        } else if (
          this.newUsersContactList &&
          this.newUsersContactList.length !== 0
        ) {
          this.addParticipants(this.newUsersContactList[0]);
          setTimeout(() => {
            this.getMessagesList(this.messageParticipants[0]);
          }, 4000);
        }
        this.handleSearchClose();
        this.endLoading();
      }, 3000);
    } else {
      this.getMessageParticipantsList();
      this.getUnreadTotalCount();
    }
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      const splittedText = this.searchText.split(" ");
      if (this.searchText.split(" ").length > 1) {
        this.userFilter.where = {
          and: [
            { first_name: { ilike: `${splittedText[0]}` } },
            { last_name: { ilike: `%${splittedText[1]}%` } },
          ],
        };
      } else {
        this.userFilter.where = {
          or: [
            { first_name: { ilike: `%${this.searchText}%` } },
            { last_name: { ilike: `%${this.searchText}%` } },
          ],
        };
      }
      this.getMessageParticipants(this.userFilter);
    }
  }

  scroll() {
    const listElm = document.querySelector("#message-list");
    if (listElm && listElm.scrollTop == 0) {
      this.messagesFilter.skip = this.messagesFilter.skip + 20;
      this.getMessages(this.messagesFilter);
    }
  }

  @Watch("messages")
  onMessageUpdated() {
    if (this.messagesFilter.skip > 0) {
      this.messageList = this.messages.concat(this.messageList);
      this.latestMsgTo(this.messageList);
      this.latestMsgFrom(this.messageList);
    } else {
      this.messageList = [...this.messages];
      this.latestMsgTo(this.messageList);
      this.latestMsgFrom(this.messageList);
    }
  }

  getMessageDate(value: any) {
    const isSameDate = moment(value, "YYYY-MM-DDTHH:mm:ss[Z]")
      .startOf("day")
      .isSame(moment().startOf("day"));
    return isSameDate
      ? `Today`
      : moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").format("DD MMM YYYY");
  }

  contactsClicked() {
    if (this.searchText !== "") {
      this.searchText = "";
      this.onChangeFilterInput();
    }
    this.getMessageParticipantsList();
    this.showContacts = !this.showContacts;
    this.selectedChat = null;
  }

  handleSearchClose() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  getPreviousChats() {
    //TODO chat api call
  }

  getFormattedDate(value: any) {
    return moment(value.updatedAt).format("hh:mm A");
  }

  getMessageParticipantsList() {
    this.getMessageParticipants(this.userFilter);
  }

  refreshMessageScreen() {
    this.getMessagesList(this.selectedChat);
  }

  getMessagesList(selectedMessageParticipant: any) {
    if (selectedMessageParticipant !== null) {
      this.selectedChat = selectedMessageParticipant;
      this.messageParticipants.forEach((messageParticipant: any) => {
        if (messageParticipant === selectedMessageParticipant) {
          messageParticipant.unreadCount = 0;
        }
      });
      let messageParticipantsId = this.selectedChat.participantId
        ? this.selectedChat.participantId
        : this.$store.state.message.messageParticipant.id;
      this.messagesFilter.skip = 0;
      this.messagesFilter.where.messageParticipantsId = messageParticipantsId;
      messageParticipantsId && this.getMessages(this.messagesFilter);
      setTimeout(() => {
        this.getMessageParticipants(this.userFilter);
        this.getUnreadTotalCount();
        const objDiv = document.querySelector("#message-list");
        objDiv ? (objDiv.scrollTop = objDiv.scrollHeight) : "";
      }, 1000);
    } else {
      this.getMessageParticipants(this.userFilter);
      this.getUnreadTotalCount();
    }
  }

  onAddMessageParticipantsActionPerformed(participant: any) {
    //this.$store.state.message.messageParticipants.unshift(
    this.selectedChat = null;
    this.messages = [];
    this.messageList = [];
    this.selectedChat = {
      lastMessage: "",
      senderName: `${participant.first_name} ${participant.last_name}`,
      updatedAt: new Date(),
      unreadCount: 0,
      participantId: this.$store.state.message.messageParticipant.id,
      msgTo: participant.id,
      newUserChat: true,
    };
    //)
  }

  addParticipants(participant: any) {
    // this.createMessageParticpants({
    //   data: {
    //     user_id1: this.$store.state.auth.currentUser.id,
    //     user_id2: participant.id,
    //   },
    //   callback: this.onAddMessageParticipantsActionPerformed(participant),
    // });
    this.onAddMessageParticipantsActionPerformed(participant);
    this.showContacts = true;
  }

  onSendMessageActionPerformed(res?: any) {
    // this.getMessageParticipantsList();
    this.$store.state.message.messages.push({
      id: res.id,
      body: this.message,
      messageParticipantsId: this.selectedChat.participantId,
      msg_to: this.selectedChat.msgTo,
      created_at: new Date(),
    });
    setTimeout(() => {
      const objDiv = document.querySelector("#message-list");
      objDiv ? (objDiv.scrollTop = objDiv.scrollHeight) : "";
    }, 100);
    this.message = "";
    if(this.selectedChat.newUserChat){
      this.refreshMessageScreen();
      delete this.selectedChat.newUserChat;
    }
  }

  sendMessageByNewChat() {
    let messageParticipantsId = this.selectedChat.participantId
      ? this.selectedChat.participantId
      : this.$store.state.message.messageParticipant.id;
    if (!messageParticipantsId) {
      return alert.methods.appToaster({
          message: constants.SOMETHING_WENT_WRONG,
          type: "ERROR",
        });
    }
      this.createMessage({
        data: {
          body: this.message,
          messageParticipantsId: messageParticipantsId,
          msg_from: this.$store.state.auth.currentUser.id,
          msg_to: this.selectedChat.msgTo,
        },
        callback: this.onSendMessageActionPerformed,
      });
  }

  sendMessage() {
    if (this.selectedChat.newUserChat) {
      this.createMessageParticpants({
        data: {
          user_id1: this.$store.state.auth.currentUser.id,
          user_id2: this.selectedChat.msgTo,
        },
        callback: (data: any) => {
          this.selectedChat.participantId = data.id;
          this.sendMessageByNewChat();
          this.handleSearchClose();
          this.showContacts = !this.showContacts;
        },
      });
    } else {
      this.sendMessageByNewChat();
    }
  }

  latestMsgTo(data) {
    if (data.length > 0) {
      const groupedData = data
        .filter((v) => v.msg_to != this.currentUser.id)
        .reduce((result, item) => {
          const dateKey = moment(item.created_at).format("YYYY-MM-DD");
          result[dateKey] = result[dateKey] || [];
          result[dateKey].push(item);
          return result;
        }, {});

      this.mostRecentTo = Object.values(groupedData).map((dayData: any) => {
        return dayData[dayData.length - 1].id;
      });
    }
  }

  latestMsgFrom(data) {
    if (data.length > 0) {
      const groupedData = data
        .filter((v) => v.msg_to === this.currentUser.id)
        .reduce((result, item) => {
          const dateKey = moment(item.created_at).format("YYYY-MM-DD");
          result[dateKey] = result[dateKey] || [];
          result[dateKey].push(item);
          return result;
        }, {});

      this.mostRecentFrom = Object.values(groupedData).map((dayData: any) => {
        return dayData[dayData.length - 1].id;
      });
    }
  }
}
