import types from "./mutation-types";
import loadingTypes from "@/store/mutation-types";
import UserTypesProxy from "@/proxies/userTypesProxy";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const getUserTypes = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new UserTypesProxy().getUserTypesList(data).then((response: any) => {
    commit(types.GET_USER_TYPES_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.GET_USER_TYPES_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const getPermissions = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new UserTypesProxy().getPermissions(data).then((response: any) => {
    commit(types.GET_USER_PERMISSIONS_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    callBack();
  }).catch((error: any) => {
    commit(types.GET_USER_TYPES_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const getRolePermissions = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new UserTypesProxy().getRolePermissions(data.id, data.filter).then((response: any) => {
    if (data.filter.where && data.filter.where.roleAccess) {
      commit(types.GET_USER_ROLE_PERMISSIONS_SUCCESS, response);
    } else {
      commit(types.GET_USER_ROLE_PERMISSION_SUCCESS, response);
    }
    commit(loadingTypes.END_LOADING);
    data.callBack();
  }).catch((error: any) => {
    commit(types.GET_USER_TYPES_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const createOrUpdateRolePermissions = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  const id = data.id;
  delete data.id;
  new UserTypesProxy().createOrUpdateRolePermissions(id, data).then((response: any) => {
    commit(types.GET_USER_ROLE_PERMISSIONS_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.USER_ROLE_PERMISSIONS_UPDATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.GET_USER_TYPES_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

export default {
  getUserTypes,
  getPermissions,
  getRolePermissions,
  createOrUpdateRolePermissions
};