/**
 * ==============
 * Initial state of Tenant Preference module
 */
export class State {
  public tenantPreferenceInitialData = {
    preference_name: "",
    is_input_required: false,
    input_value: ""
  };
  public tenantPreference = this.tenantPreferenceInitialData;
  public tenantPreferences: any = [];
  public error: any;
  public tenantPreferencesLoading = false;
  public preferencesCount = 0;
}
