/* ============
 * Actions for the Tenant Preference module
 * ============
 *
 * The actions that are available on the
 * Tenant Preference module.
 */
import types from "./mutation-types";
import TenantPreferenceProxy from "@/proxies/TenantPreferenceProxy";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";
import loadingTypes from "@/store/mutation-types";

const getTenantPreferences = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new TenantPreferenceProxy()
    .getTenantPreferences(data)
    .then((response: any) => {
      commit(types.FETCH_TENANT_PREFERENCE_LIST_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.FETCH_TENANT_PREFERENCE_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const createTenantPreference = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new TenantPreferenceProxy()
  .create(data)
    .then((response: any) => {
      commit(types.TENANT_PREFERENCE_CREATE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.PREFERENCE_CREATED_SUCCESS, type: "SUCCESS" });
      callback();
    })
    .catch((error: any) => {
      commit(types.TENANT_PREFERENCE_CREATE_FAIL, error.error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const updateTenantPreference = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new TenantPreferenceProxy()
    .updateTenantPreference(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      if (data && data.status === false) {
        alert.methods.appToaster({ message: msgConstants.PREFERENCE_DELETED_SUCCESS, type: "SUCCESS" });        
      } else {
        alert.methods.appToaster({ message: msgConstants.PREFERENCE_UPDATED_SUCCESS, type: "SUCCESS" });
      }
      callback();
    })
    .catch(() => {
      alert.methods.appToaster({ message: msgConstants.PREFERENCE_UPDATED_FAIL, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const initPreference = ({ commit }: any) => {
  commit(types.INIT_TENANT_PREFERENCE);
};

const getTenantPreferenceCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new TenantPreferenceProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_PREFERENCES_COUNT_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_PREFERENCES_COUNT_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getTenantPreference = ({ commit }: any, id: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.GET_TENANT_PREFERENCE_INIT);
  new TenantPreferenceProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_TENANT_PREFERENCE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch(({ error }: any) => {
      commit(types.GET_TENANT_PREFERENCE_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const setTenantPreference = ({ commit }: any, preference: any) => {
  commit(types.SET_TENANT_PREFERENCE, preference);
};



export default {
  getTenantPreferences,
  updateTenantPreference,
  createTenantPreference,
  initPreference,
  setTenantPreference,
  getTenantPreference,
  getTenantPreferenceCount
};
