

















































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";

@Component({
  components: {},
})
export default class RestaurantForm extends Vue {
  @Action public createRestaurant: any;
  @Action public initNewRestaurant: any;
  @Action private getRestaurantById: any;
  @Action private updateRestaurant: any;
  @Getter public restaurant: any;
  @Getter public currentUser: any;
  action = "add";
  isSubmitted = false;
  $refs!: {
    restaurantForm: InstanceType<typeof ValidationObserver>;
  };

  beforeMount() {
    this.initNewRestaurant();
    const restaurantId = this.$route.params.id;
    if (restaurantId) {
      this.action = "edit";
      this.getRestaurantById(restaurantId);
    }
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.restaurantForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.restaurantForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.restaurantForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        if (this.action === "add") {
          const { name, phone, email = '', address = '', city = '', state = '', zipcode = '', url = '' } = this.restaurant;
          this.createRestaurant({
            data: {
              name,
              phone,
              email,
              address,
              city,
              state,
              zipcode,
              url,
            },
            callBack: this.backToRestaurants
          });
        } else {
          if(this.restaurant.url == null) {
            this.restaurant.url = '';
          }
          if(this.restaurant.email == null) {
            this.restaurant.email = '';
          }
          const { id, name, phone, email, address, city, state, zipcode, url, comment } =
            this.restaurant;
          this.updateRestaurant({
            data: {
              id,
              name,
              phone,
              email,
              address,
              city,
              state,
              zipcode,
              url,
              comment
            },
            callBack: this.backToRestaurants 
          });
        }
      }
    });
  }

  backToRestaurants() {
    this.$router.push({ name: "restaurant.index" }).catch();
    this.$refs.restaurantForm.reset();
  }

  onCancel(): void {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.$router.push({ name: "restaurant.index" }).catch();
      },
      () => {}
    );
  }
}
