
























import { Vue, Prop, Component, Emit, Watch } from "vue-property-decorator";

@Component
export default class RadioButton extends Vue {
  @Prop() private rules!: string;
  @Prop() private inputValue!: string;
  @Prop() private label!: string;
  @Prop() private options!: any[];
  value: any = this.inputValue;
  @Prop() isSubmitted!: boolean;
  @Prop({ default: "text" }) textField!: string;
  @Prop({ default: "value" }) valueField!: string;
  @Prop({default: false}) disabled!: boolean;

  @Emit("getInputValue")
  public onChangeInput() {
    return this.value;
  }

  @Watch("inputValue")
  changeInputValue(newValue: any, oldValue: any): void {
    if (newValue !== oldValue && this.value !== this.inputValue) {
      this.value = this.inputValue;
    }
  }
}
