import Proxy from './Proxy';

class NewsAnnouncementProxy extends Proxy {
    constructor(){
        super(`/news-announcements`)
    }

    public getNewsAnnouncements(filter: any){
        return this.submit("get", this.endpoint, {filter: JSON.stringify(filter)});
    }

    public getNewsAnnouncementCount(data: any){
        return this.submit("get", `${this.endpoint}/count`, {where: JSON.stringify(data)});
    }

    public updateNewsAnnouncement(data:any){
        const id = data.id;
        let body: any = JSON.stringify(data);
        body = JSON.parse(body);
        delete body.id;
        return this.submit("patch", `${this.endpoint}/${id}`,undefined,  body)
    }
}

export default NewsAnnouncementProxy;