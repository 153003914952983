import Proxy from "./Proxy";

class MeetingProxy extends Proxy {
  /**
   * Constructor for Meeting proxy
   */
  constructor() {
    super(`/meetings`);
  }

  public publishMeetingById(id: any) {
    return this.submit("put", `${this.endpoint}/${id}/publish`);
  }

  public confirmMeetingById(id: any) {
    return this.submit("put", `${this.endpoint}/${id}/confirm`);
  }

  public getBrands(data: any): any {
    return this.submit("get", `/brands`, {filter: JSON.stringify(data)});
  }

  public getCategories(data: any): any {
    return this.submit("get", `/categories`,  {filter: JSON.stringify(data)});
  }

  public getTopics(data: any): any {
    return this.submit("get", `/topics`,  {filter: JSON.stringify(data)});
  }

  public getLocationDropdown(data: any): any {
    return this.submit("get", `/tenantlocations/dropdown`, {filter: JSON.stringify(data)});
  }

  public getTenantLocation(data: any): any {
    return this.submit("get", `/tenantlocations/list`,  {filter: JSON.stringify(data)});
  }

  public getPhysicians(data: any): any {
    return this.submit("get", `/physicians`, {filter: JSON.stringify(data)});
  }

  public getMeetingByGuestUser(id: any): any {
    return this.submit("get", `guest-user/meetings/${id}`);
  }

  public transferMeeting(id: number, data: any): any {
    return this.submit("patch", `/meetings/${id}/transfer`, undefined,data);
  }

  public generateMeetings(data: any): any {
    return this.submit("post", `/meetings/generate-appointments`, undefined,data);
  }

  public meetingRuleValidation(data: any): any {
    return this.submit("post", `/meetings/validation`, undefined,data);
  }

  public cancelMeeting(data: any) {
    const id = data.id;
    delete data.id;
    return this.submit("patch", `/meetings/${id}/cancel`, undefined,data)
  }

  public getRecommendationList(data: any) {
    return this.submit("get", `/meetings/${data}/recommendation`);
  }

  public approveRequestMeetingTransferRequest(data: any) {
    const id = data.id;
    delete data.id;
    return this.submit("patch", `/meetings/${id}/approval-request`, undefined,data);
  }

  public updateTransferMeetingRequest(data: any) {
    return this.submit("patch", `/meetings/bulkupdate`, undefined,data);
  }

  public messageMeetingOrganizer(data: any): any {
    return this.submit("post", `/meetings/message`, undefined,data);
  }

  public getMeetingList(params: any): any {
    return this.submit("get", `/meetings`, params);
  }

  public updatePastMeetingById(data: any): any {
    const {id,...updateObj} = data;
    return this.submit("patch", `${this.endpoint}/${id}/update`, undefined,updateObj);
  }

}

export default MeetingProxy;
