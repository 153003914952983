



































import { Vue, Component } from "vue-property-decorator";
import VHeader from "./header/Header.vue";
import SideBar from "./sideBar/SideBar.vue";
import VLoader from "../Loader/Loader.vue";
import SubscriptionInfoModal from "../subscriptionInfoModal/SubscriptionInfoModal.vue";
import BlockedPracticeModal from "../blockedPracticeModal/BlockedPracticeModal.vue";
import { Action, Getter } from "vuex-class";
import HIPAAAgreementModal from "@/components/hipaaAgreementModal/HIPAAAgreementModal.vue";
import VerificationModal from "@/components/verificationModal/VerificationModal.vue";

@Component({
  components: {
    VHeader,
    SideBar,
    VLoader,
    SubscriptionInfoModal,
    BlockedPracticeModal,
    HIPAAAgreementModal,
    VerificationModal
  },
})
export default class AppLayout extends Vue {
  @Getter currentUser: any;
  @Action showHipaaAgreementModal: any;
  @Action hideVerificationModal: any;
  @Getter isShowHIPAAAgreementModal: any;
  @Getter public showVerificationModal!: boolean;
  @Action public setUser: any;
  @Getter public user: any;
  @Action public updatePreference: any;
  @Action public getLoggedInUser: any;

  get isLogin() {
    return ["/login","/forgotPassword"].includes(this.$route.path);
  }

  get isRegister() {
    return this.$route.meta?.requiresAuth
      ? this.$route.name !== "landing.index"
      : false;
  }

  verified() {
    this.hideVerificationModal();
    this.user.userPreferences = { is_email_verified: true, is_phone_verified: false };
    this.setUser(this.user);
    this.updatePreference({id: this.currentUser.id ,userPreferences: {
      is_email_verified: true,
      is_phone_verified: false
    }});
  }
}
