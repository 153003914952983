































import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

@Component({
  components: {
    dateTimePicker: Datetime
  }
})
export default class DateTimePicker extends Vue {
  @Prop() private inputValue!: string;
  @Prop() label!: string;
  @Prop() pickerType!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({ default: "" }) format!: string;
  @Prop({ default: "" }) minDatetime!: string;
  @Prop({ default: "" }) rules!: string;
  @Prop({ default: "" }) timezone!: string;

  value: any = this.inputValue;

  @Emit("getInputValue")
  public onChangeInput() {
    return this.value;
  }

  @Watch("inputValue")
  changeInputValue(newValue: any, oldValue: any): void {
    if ((newValue !== oldValue) && (this.value !== this.inputValue)) {
      this.value = this.inputValue;
    }
  }
  
  toggleDatePicker(isOpen: boolean) {
    const body = document.body;
    if (isOpen) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }
  }
}
