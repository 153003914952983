







































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Table extends Vue {
  @Prop({ default: "" }) public fields: any;
  @Prop({ default: "" }) public items: any;
}
