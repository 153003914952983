















































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import { deleteTeamAlert } from "@/utils/notificationContent";
import TeamForm from "./TeamForm.vue";
import constants from "@/constants/app.properties";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    TeamForm,
  },
})
export default class TeamList extends Vue {
  @Action public getTeams: any;
  @Action public getTeamCount: any;
  @Action public getTeamById: any;
  @Action public setTeam: any;
  @Action public updateTeam: any;
  @Action public deleteTeamById: any;
  @Getter public teamCount: any;
  @Getter public teams: any;
  @Getter public team: any;
  @Getter public teamInitData: any;
  @Getter public currentUser!: any;
  @Getter public rolePerm: any;
  perm = _permissions;

  ref: any = "appModalRef";
  teamFilter: any = {
    limit: 20,
    skip: 0,
    where: { status: { eq: true}},
    include: [
      { relation: "teamMembers",
        scope: {
          where: {userId: localStorage.getItem(constants.USER_ID)},
          include: [{ relation: "user" }]
    } },
    ],
  };
  appointmentTypeFilter: any = {
    where: {
      status: { eq: true },
    },
    order: "id ASC",
  };
  pageOptions = [10, 20];
  currentPage = 1;
  searchText = "";

  beforeMount() {
    this.getTeamList();
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.teamFilter.where = {
        and: [
          {
            name: { ilike: `%${this.searchText}%` },
          },
          {
            status: { eq: true },
          },
        ],
      };
      this.teamFilter.skip = 0;
      this.getTeamList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  getTeamList() {
    this.getTeams({query: this.teamFilter});
    this.getTeamCount(this.teamFilter.where ? { where: this.teamFilter.where, isCustom: true } : {});
  }

  addTeam() {
    this.setTeam({
      name: null,
      user_id: [],
      status: true,
    });
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  actionPerformed(isPageRefresh: any) {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    if (isPageRefresh) {
      this.getTeamList();
    }
  }

  editTeam(team: any): void {
    this.setTeam(team);
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  deleteTeam(id: number): void {
    alert.methods.conFirmAlert(
      deleteTeamAlert,
      () => {
        this.deleteTeamById({
          data: { id: id, status: false },
          callback: this.getTeamList,
        });
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.teamFilter.skip = 0;
    this.getTeamList();
  }

  onPageChange(currentPage: any) {
    this.teamFilter.skip = (currentPage - 1) * this.teamFilter.limit;
    this.getTeamList();
  }
}
