

























import { Vue, Prop, Component, Emit, Watch } from "vue-property-decorator";

@Component(({
  name: 'FormInput'
}))
export default class FormFileInput extends Vue {
  @Prop({ default: "" }) private rules!: string;
  @Prop() private inputValue!: any;
  @Prop({ default: "" }) private placeholder!: string;
  @Prop()  isSubmitted!: boolean;
  @Prop({ default: "" }) private accept!: string;
  @Prop({ default: false}) disabled!: boolean;
  value: any = this.inputValue || null;

  @Emit("getInputValue")
  public onChangeInput() {
    return this.value;
  }

  @Watch("inputValue")
  changeInputValue(newValue: any, oldValue: any): void {
    if ((newValue !== oldValue) && (this.value !== this.inputValue)) {
      this.value = this.inputValue;
    }
  }

  formatNames(files: any) {
    return files[0] ? files[0].name : "";

  }
}
