
import keyMirror from "keymirror";

export default keyMirror({
  INIT_NEW_LOCATION: null,
  CREATE_NEW_LOCATION_INIT: null,
  CREATE_NEW_LOCATION_SUCCESS: null,
  CREATE_NEW_LOCATION_FAIL: null,
  GET_LOCATION_LIST_INIT: null,
  GET_LOCATION_LIST_SUCCESS: null,
  GET_LOCATION_LIST_FAIL: null,
  GET_LOCATION_BY_ID_INIT: null,
  GET_LOCATION_BY_ID_SUCCESS: null,
  GET_LOCATION_BY_ID_FAIL: null,

  LOCATION_UPDATE_INIT: null,
  LOCATION_UPDATE_SUCCESS: null,
  LOCATION_UPDATE_FAIL: null,
  GET_LOCATION_COUNT: null,
  SET_LOCATION_TIMESLOT: null,

  TIME_SLOT_DELETED_INIT: null,
  TIME_SLOT_DELETED_SUCCESS: null,
  TIME_SLOT_DELETED_FAIL: null,
});