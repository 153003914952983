





























































import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({})
export default class SubscriptionInfoModal extends Vue {
  @Action closeInfoModal!: any;
  @Action private signOut: any;
  @Getter isShowModal!: boolean;
  @Getter currentUser: any;
  @Getter isCurrentTenantBlocked: any;

  get subscriptionDate() {
    return moment.utc(
      this.currentUser.tenant?.subscription_start_date,
      "YYYY-MM-DD[T]HH:mm[Z]"
    ).format('MMM DD, YYYY');
  }

  closeModal() {
    const currentTime = moment.utc(
      moment().format("YYYY-MM-DD"),
      "YYYY-MM-DD"
    );
    let subscriptionTime = moment.utc(
      moment(this.currentUser.tenant?.subscription_start_date,
      "YYYY-MM-DD[T]HH:mm[Z]").format("YYYY-MM-DD"),
      "YYYY-MM-DD"
    );
    if(this.currentUser.tenant.subscription_grace_days) {
      subscriptionTime = subscriptionTime.add(this.currentUser.tenant.subscription_grace_days, 'days')
    }
    if (currentTime.isSameOrAfter(subscriptionTime, 'day')) {
      this.signOut();
      this.closeInfoModal();
    } else {
      this.closeInfoModal();
    }

  }

  navigateToSubscription() {
    this.closeInfoModal();
    this.$router.push({ path: '/subscription'})
  }

}
