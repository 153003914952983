



















import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class RegisterSuccess extends Vue {
  onHome(): void {
    this.$router.push({ name: "login.index" });
  }
}
