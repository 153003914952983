import keyMirror from "keymirror";

export default keyMirror({
  GET_PRACTICE_LIST_SUCCESS: null,
  GET_PRACTICE_LIST_FAIL: null,
  GET_PRACTICE_COUNT: null,

  ADD_PRACTICE_FAIL: null,

  REMOVE_PRACTICE_SUCCESS: null,
  REMOVE_PRACTICE_FAIL: null,

  CHANGE_PRACTICE_FAIL: null,

  GET_DROPDOWN_PRACTICE_LIST_SUCCESS: null,
  GET_DROPDOWN_PRACTICE_LIST_FAIL: null,

  ENABLE_DISABLE_PRACTICE_SUCCESS: null,
  ENABLE_DISABLE_PRACTICE_FAIL: null
});