









































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import Variables from "@/constants/_variables";
import moment from "moment";
import appConstants from "@/constants/app.properties";
import _ from "lodash";
import DatePicker from "@/components/datepicker/index.vue";
import VerificationForm from "@/components/verificationModal/VerificationForm.vue";
import AppModal from "@/components/baseComponents/Modal/Index.vue"

@Component({
  components: {
    DatePicker,
    VerificationForm,
    AppModal
  }
})
export default class RegisterForm extends Vue {
  @Action public onRegisterUser: any;
  @Action public initRegisterUser: any;
  @Action public setUser: any;
  @Action private getPreference: any;
  @Action private updatePreference: any;
  @Action private getTenants: any;
  @Getter public user: any;
  @Getter public tenants: any;
  @Action public getOrganizationTypes: any;
  @Getter public organizationTypes: any;
  @Action public getUserById: any;
  @Action public updateVaccinationStatus: any;
  @Getter public currentUser: any;
  @Action public startLoading: any;
  @Action public endLoading: any;
  @Action public downloadVaccinationCertification: any;
  @Action public deleteVaccinationCertificate: any;
  @Action private getLoggedInUser: any;
  @Action public downloadSignedHipaaForm: any;
  @Action public updateUnapprovedUserDetails: any;
  
  action = "add";
  isSameAsEmail = false;
  tempTenantName = 'New York Cancer Center';

  private passwordVisibility: any = {
    newPassword: {
      type: Variables.PASSWORD_TYPE.password,
      icon: Variables.PASSWORD_ICON.show
    },
    confirmPassword: {
      type: Variables.PASSWORD_TYPE.password,
      icon: Variables.PASSWORD_ICON.show
    }
  };

  isSubmitted = false;
  $refs!: {
    appModalRef: HTMLFormElement; 
    registerForm: InstanceType<typeof ValidationObserver>;
    vaccinationForm: InstanceType<typeof ValidationObserver>;
  };
  isVaccinationSubmitted = false;
  showEmailFeild = true;

  fileName = null;
  fileAcceptCriteria = "jpeg,png,application/pdf";
  fileRules: any = `mimes:${this.fileAcceptCriteria}|size:10240`;
  fileAccept = "jpeg,png,pdf";
  userMail = '';

  vaccinationTypes = [
    {
      text: "Yes",
      value: 1,
    },
    {
      text: "No",
      value: 2,
    },
    {
      text: "Decline to answer",
      value: 0,
    },
  ];
 

  repVaccination: any = {
    id: 0,
    vaccination_status: '',
    vaccination_certificate_url: "",
    last_date_vaccination: ''
  };

  repOrganizationTypes: any = [];
  showHipaaDetails = true;

  beforeMount() {
    this.setUser({});
    this.initRegisterUser();
    this.getDropDownValues();
    const userId = localStorage.getItem(appConstants.USER_ID);
    if (userId) {
      this.action = "edit";
      this.getUserById({
        id: userId, filter: {
        include: [
          {
            relation: "userOrganizationTypes",
            scope: {
              fields: [
                "organizationTypeId",
                "userId"
              ],
              include: [
                {
                  relation: "organization_type",
                  scope: {
                    fields: [
                      "id",
                      "name"
                    ]
                  }
                },
              ]
            }
          },
          {
            relation: "userPreferences",
            scope: {
              fields: [
                "userId",
                "is_email_verified",
                "is_phone_verified"
              ],
            }
          }
        ]
      }
    });
    this.getUserDetails();
    } 
   
    if(this.action !== "edit") {
      localStorage.clear();
    }
  }

  destroyed() {
    this.initRegisterUser();
  }

  getUserDetails() {
    setTimeout(() => {
      this.startLoading();
    }, 600);
    setTimeout(() => {
      if(this.user.id && this.user.vaccination_status !== null && this.user.vaccination_status !== '') {
        this.repVaccination.vaccination_status = this.user.vaccination_status;
        this.repVaccination.last_date_vaccination = this.user.last_date_vaccination;
        this.repVaccination.vaccination_certificate_url = this.user.vaccination_certificate_url;
      }
      if(this.user.userOrganizationTypes) {
        this.user.userOrganizationTypes.forEach((userOrganizationType: any) => {
          this.repOrganizationTypes.push(userOrganizationType.organization_type);
        });
        this.setOrganizationTypes(this.repOrganizationTypes);
      }
      this.endLoading();
    }, 2000);
  }

  togglePasswordVisibility(field: any) {
    if (this.passwordVisibility[field].type === Variables.PASSWORD_TYPE.text) {
      this.passwordVisibility[field].type = Variables.PASSWORD_TYPE.password;
      this.passwordVisibility[field].icon = Variables.PASSWORD_ICON.show;
    } else {
      this.passwordVisibility[field].type = Variables.PASSWORD_TYPE.text;
      this.passwordVisibility[field].icon = Variables.PASSWORD_ICON.hide;
    }
  }

  setOrganizationTypes(values: any) {
    this.repOrganizationTypes = values;
    const groupedById = _.groupBy(values, value => value.id);
    const result = Object.keys(groupedById);
    this.user.organization_types = result;
    this.setUser(this.user);
  }

  onChangeCheckbox(value: boolean): void {
    if (value && this.user.email) {
      this.setUser(this.user);
    }
    setTimeout(() => {
      this.isSameAsEmail = value;
    }, 10);
  }

  onChangeEmail(value: string): void {
    this.user.email = value;
    if (this.isSameAsEmail) {
      this.setUser(this.user);
    }
  }

  openEmailVerifyModal() {
    let emailExpression = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if(this.user.email && emailExpression.test(this.user.email)) {
      this.userMail = this.user.email;
      const appModal = this.$refs.appModalRef;
      appModal.show();
    } else {
      alert.methods.appToaster({message: 'Enter a valid email id.', type: 'ERROR'});
    }
  }


  mailVerified(val: string) {
    if(!this.currentUser.id) {
      const appModal = this.$refs.appModalRef;
      appModal.hide();
      this.user.email = val;
      if(this.user.userPreferences) {
          this.user.userPreferences.is_email_verified = true;
          this.setUser(this.user);
        }
        this.showEmailFeild = false;
        setTimeout(() => {
          this.showEmailFeild = true;
        }, 200);
    } else {
        this.user.email = val;
        this.user.vaccination_status = this.repVaccination.vaccination_status;
        this.user.last_date_vaccination = this.repVaccination.last_date_vaccination;
        if(this.user.userPreferences) {
          this.user.userPreferences.is_email_verified = true;
          this.setUser(this.user);
        }
        this.showEmailFeild = false;
        const appModal = this.$refs.appModalRef;
        appModal.hide();
        this.getDropDownValues();
        setTimeout(() => {
          this.showEmailFeild = true;
        }, 200);
    }    
  }

  getDropDownValues() {
    this.getOrganizationTypes({where:{status:true}, fields: { id: true, name: true }});
    this.getTenants({where: { is_active: true }, fields: { id: true, name: true }});
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.registerForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR"
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.registerForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter(error => error["value"].length);
        if (errors.length) {
          this.$refs.registerForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        }
        return;
      } else {
        if (this.action === "add") {
          this.isVaccinationSubmitted = true;
          this.$refs.vaccinationForm.validate().then((success: any) => {
            if (!success) {
              alert.methods.appToaster({
                message: constants.FORM_ERROR,
                type: "ERROR"
              });
              const firstErrorFocused: HTMLFormElement = Object(
                document.querySelector(".error:first-of-type")
              );
              if (
                isNotEmpty(firstErrorFocused) &&
                !firstErrorFocused.classList.contains("IZ-select")
              ) {
                firstErrorFocused.querySelector("input");
                firstErrorFocused.focus();
              }
              const errors = Object.entries(this.$refs.vaccinationForm.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error["value"].length);
              if (errors.length) {
                this.$refs.vaccinationForm.refs[errors[0]["key"]].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center"
                });
              }
              return;
            } else {
              delete this.user.confirmPassword;
              this.user.created_at = moment().format();
              this.onRegisterUser({ data: this.user, callback: this.uploadVaccinationCertificate});
            }
          });
        } else {
          this.checkNullFeilds();
          const { id, first_name, last_name, email, phone, address, city, state, pharma_company, products,zipcode,territory, organization_types, security_code } =
            this.user;
          if(this.showHipaaDetails) {
            this.updatePreference({
              id,
              first_name,
              last_name,
              email,
              phone,
              address,
              pharma_company,
              products,
              zipcode,
              territory,
              city,
              state,
              organization_types
            });
          } else {
            this.updateUnapprovedUserDetails({
              data: { id, first_name, last_name, phone, address, city, state, pharma_company, products, zipcode, territory, organization_types, security_code },
              callback: this.uploadVaccinationDetails
            });
          }
        }
      }
    });
  }

  uploadVaccinationDetails() {
    this.uploadVaccinationCertificate(this.user.id);
  }

  checkNullFeilds() {
    if (this.user.address === null) {
      this.user.address = "";
    }
    if (this.user.zipcode === null) {
      this.user.zipcode = "";
    }
    if (this.user.territory === null) {
      this.user.territory = "";
    }
    if (this.user.products === null) {
      this.user.products = "";
    }
    if (this.user.phone === null) {
      this.user.phone = "";
    }
    if (this.user.city === null) {
      this.user.city = "";
    }
    if (this.user.state === null) {
      this.user.state = "";
    }
    if(this.repVaccination.vaccination_certificate_url === null) {
      this.repVaccination.vaccination_certificate_url = '';
    }
  }

  updateVaccinationDetails() {
    if(this.showHipaaDetails) {
      this.updateProfileVaccinationStatus();
    } else {
      this.onFormSubmit();
    }
  }

  updateProfileVaccinationStatus() {
    this.isVaccinationSubmitted = true;
    this.$refs.vaccinationForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR"
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.vaccinationForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter(error => error["value"].length);
        if (errors.length) {
          this.$refs.vaccinationForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        }
        return;
      } else {
        if(this.currentUser.tenant.required_reps_vaccination && this.repVaccination.vaccination_status !== 1) {
          alert.methods.appToaster({ message: 'Vaccinaton is mandatory to continue booking appointments', type: "ERROR" });
          return;
        }
        if(this.currentUser.tenant.tenantpreferences.required_fields.vaccination_certificate_url &&(this.repVaccination.vaccination_certificate_url === null || this.repVaccination.vaccination_certificate_url == '')) {
          alert.methods.appToaster({ message: 'Vaccinaton card is mandatory to continue booking appointments', type: "ERROR" });
          return;
        }
        this.repVaccination.id = this.currentUser.id;
        const vaccinationStatus = this.repVaccination;
        if(vaccinationStatus.vaccination_status === 2) {
          vaccinationStatus.last_date_vaccination = '';
        }else if(vaccinationStatus.vaccination_status === 0) {
          vaccinationStatus.last_date_vaccination = '';
        }
        if(vaccinationStatus.vaccination_certificate_url === null) {
          vaccinationStatus.vaccination_certificate_url = '';
        }
        this.updateVaccinationStatus({
          data: vaccinationStatus,
          callback: () => {
            this.getLoggedInUser();
            this.$router.push({ name: "meeting.list" }).catch();
          },
        });
      }
    });
  }

  uploadVaccinationCertificate(value: any) {
    this.repVaccination.id = value;
    if(this.repVaccination.vaccination_certificate_url === null) {
      this.repVaccination.vaccination_certificate_url = '';
    }
    let vaccinationStatus = this.repVaccination;
    vaccinationStatus.isFromRegister = true;
    this.updateVaccinationStatus({ data: vaccinationStatus, callback: () => {
      this.$router.push({
        name: "registerSuccess.index"
      }).catch();
    }});
  }

  setFileObj(value: any) {
    this.repVaccination.vaccination_certificate_url = value;
  }

  onDeleteFieldsAtUpdate() {
    delete this.user.created_at;
    delete this.user.updated_at;
    delete this.user.created_by;
    delete this.user.updated_by;
    if (this.user.subscription_plan !== undefined) {
      delete this.user.subscription_plan;
    }
    if (this.user.subscription_end_date !== undefined) {
      delete this.user.subscription_end_date;
    }
    if (this.user.expired_at !== undefined) {
      delete this.user.expired_at;
    }
    if (this.user.security_code !== undefined) {
      delete this.user.security_code;
    }
  }

  onCancel(): void {
    if (this.action === "add" || !this.showHipaaDetails) {
      alert.methods.conFirmAlert(
        cancelMeetingAlert,
        () => {
          this.$router.push({ name: "login.index" }).catch();
        },
        () => {}
      );
    } else {
      this.$router.push({ name: "meeting.list" }).catch();
    }
  }

  downloadCertificate(certificateUrl: any) {
    this.downloadVaccinationCertification(certificateUrl);
  }

  downloadHipaaForm(hipaaFilrUrl: any) {
    this.downloadSignedHipaaForm(hipaaFilrUrl);
  }

  deleteCertificate() {
    this.deleteVaccinationCertificate({data: this.currentUser.id, callback: () => {
      this.repVaccination.vaccination_certificate_url = '';
      this.getUserById({
        id: this.currentUser.id, filter: {
        include: [
          {
            relation: "userOrganizationTypes",
            scope: {
              fields: [
                "organizationTypeId",
                "userId"
              ],
              include: [
                {
                  relation: "organization_type",
                  scope: {
                    fields: [
                      "id",
                      "name"
                    ]
                  }
                }
              ]
            }
          },
          {
            relation: "userPreferences",
            scope: {
              fields: [
                "userId",
                "is_email_verified",
                "is_phone_verified"
              ],
            }
          }
        ]
      }
    });
   // this.getLoggedInUser();
  }
    });
  }

  get isEnabledHipaa(){
    return this.currentUser.tenant && this.currentUser.tenant.tenantpreferences && 
    this.currentUser.tenant.tenantpreferences.required_fields && 
    this.currentUser.tenant.tenantpreferences.required_fields.enable_hipaa_form;
  }

  get isSubscribed():boolean {
    if(this.user?.subscription_end_date){
      return moment().isAfter(this.user.subscription_end_date, 'days')
    } 
    return true;   
  }

  get isEnabledSubscription(){
    return this.currentUser.tenant && this.currentUser.tenant.enable_subscription;
  }

  backToSubscription() {
    this.$router.push({ name: "subscription.index" }).catch();
  }
}
