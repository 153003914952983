import Proxy from "./Proxy";

class UserTypesProxy extends Proxy {
  /**
   * Constructor for usertypes proxy
   */
  constructor() {
    super(`/user-types`);
  }

  public getUserTypesList(data: any) {
    return this.getList(data);
  }

  public getPermissions(data: any) {
    return this.submit('get', `/permissions`, {filter: JSON.stringify(data)});
  }

  public getRolePermissions(id: number, data: any) {
    return this.submit('get', `roles/${id}/permissions`, {filter: JSON.stringify(data)});
  }

  public createOrUpdateRolePermissions(id: number, data: any) {
    return this.submit('patch', `roles/${id}/permissions`, undefined, data);
  }
}

export default UserTypesProxy;