






















import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import * as pbi from "powerbi-client";

@Component({
    components: {}
})
export default class Dashboard extends Vue {
    @Action public getPowerBIReport: any;
    @Action public getReportList: any;
    @Getter public powerbiReports: any;
    @Getter public role!: any;
    @Getter private currentUser!: any;
    @Getter public powerbiRes: any;
    @Action public startLoading: any;
    @Action public endLoading: any;
    @Getter public user: any;

    searchReport: any = '';
    embedConfig: any = {
        type: 'report',
        accessToken: '',
        embedUrl: '',
        tokenType: pbi.models.TokenType.Embed,
        permissions: pbi.models.Permissions.All,
        pageView: 'fitToWidth',
        settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false
            
        }
    };
    powerbi: any = {
        reportId: '',
        datasetId: '',
        role: 1,
        username: ''
    }
    repDashboard:any  = {};
    powerbiReportList = [];

    beforeMount() {
        if (this.currentUser && this.currentUser.id) {
            this.getReports();
        }
    }

    @Watch('currentUser')
    getReports() {
        if (this.role === 'SUPER_ADMIN' || this.role === 'USER') {
            this.getReportList({callBack: this.getReport});
            // if (this.powerbiReports.length) {
            //     this.powerbi.username = this.currentUser.email;
            //     this.powerbi.role = this.currentUser.userTypesId;
            //     this.searchReport = this.powerbiReports[0].id;
            //     this.setReport(this.searchReport);
            // }
      }
    }

    getReport() {
        if (this.powerbiReports.length) {
            this.repDashboard = this.powerbiReports.find((v:any)=>v.name === 'Rep Dashboard');
           // this.powerbiReportList = this.powerbiReports.filter((v:any)=>v.name !== 'Rep Dashboard');
            this.powerbi.username = this.currentUser.email;
            this.powerbi.role = this.currentUser.userTypesId;
            this.searchReport = this.role === 'USER' ? this.repDashboard.id : this.powerbiReports[0].id;
            this.setReport(this.searchReport);
        }
    }

    setReport(value: any): any {
        this.searchReport = value;
        const obj = this.role === 'USER' ? this.repDashboard : this.powerbiReports.find((v: any) => v.id == value);
        this.powerbi.username = this.currentUser.email;
        this.powerbi.reportId = obj.id;
        this.powerbi.datasetId = obj.datasetId;
        this.getEmbededUrl(this.powerbi);
    }

    getEmbededUrl(data: any) {
        this.getPowerBIReport({ data, callback: (res: any) => this.initReports(res) });
    }

    initReports(res: any) {
        this.embedConfig.embedUrl = res.data.embedUrl || '';
        this.embedConfig.accessToken = res.data.token || '';
        let reportContainer = document.getElementById("report-container") as HTMLElement;
        // Get the report container element
        let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        powerbi.reset(reportContainer)
        // Embed Power BI report when Access token and Embed URL are available
        let report = powerbi.embed(reportContainer, this.embedConfig) as pbi.Report;
        // Clear any other loaded handler events
        report.off("loaded");
        // Clear any other rendered handler events
        report.off("rendered");
        // Handle embed errors
        report.on("error", function () {
            report.off("error");
        });
    }

}
