




















import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class BlockedPracticeModal extends Vue {
  @Getter isCurrentTenantBlocked!: boolean;
  @Getter dropdownPracticeList: any;
  @Getter currentUser: any;

  get practiceName() {
    if (this.dropdownPracticeList.length) {
      return this.dropdownPracticeList.filter((practice: any) => practice.default && practice.is_blocked)[0]?.tenant?.name;
    }
    return '';
  }

  hideModal(): void {
    this.$bvModal.hide("block-modal");
  }

}
