








































































































































import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { Action } from "vuex-class";
import Variables from "@/constants/_variables";
import alert from "@/mixins/alert";

@Component({
  components: {}
})
export default class ForgotPassword extends Vue {
  user: any = {
    email: "",
    password: "",
    confirmPassword: "",
    security_code: "",
    userId: 0,
    phone: "",
    code: 0,
  };
  isSubmitted = false;
  @Action onForgotPasswordMail: any;
  @Action private verifyVerificationCode: any;
  otp = false;
  username = false;

  beforeMount() {
    if (this.$route.query.s) {
      this.user.security_code = this.$route.query.s;
    }
    if(this.$route.query.d){
      this.user.userId = this.$route.query.d;
    }
  }

  $refs!: {
    forgotPasswordForm: InstanceType<typeof ValidationObserver>;
  };

  private passwordVisibility: any = {
    password: {
      type: Variables.PASSWORD_TYPE.password,
      icon: Variables.PASSWORD_ICON.show,
      title: "Show"
    },
    confirmPassword: {
      type: Variables.PASSWORD_TYPE.password,
      icon: Variables.PASSWORD_ICON.show,
      title: "Show"
    }
  };

  private togglePasswordVisibility(field: any) {
    if (this.passwordVisibility[field].type === Variables.PASSWORD_TYPE.text) {
      this.passwordVisibility[field].type = Variables.PASSWORD_TYPE.password;
      this.passwordVisibility[field].icon = Variables.PASSWORD_ICON.show;
      this.passwordVisibility[field].title = "Show";
    } else {
      this.passwordVisibility[field].type = Variables.PASSWORD_TYPE.text;
      this.passwordVisibility[field].icon = Variables.PASSWORD_ICON.hide;
      this.passwordVisibility[field].title = "Hide";
    }
  }

  onForgotPassword() {
    this.isSubmitted = true;
    this.$refs.forgotPasswordForm.validate().then((success: any) => {      
      if (!success) {
        return;
      } else {
        if (this.user.security_code) {
          let data:any = {
              password: this.user.password,
              security_code: this.user.security_code
            };
            
            if(+this.user.userId > 0) {
              data.userId = +this.user.userId;
            }

            if(this.user.phone){
              data.phone = this.user.phone;
            }

            if(this.user.email){
              data.username = this.user.email;
            }

          this.onForgotPasswordMail({
            data, callback: () => {
              this.onCancel();
             }
          });
        } else if(!this.otp && !this.username) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const phoneRegex = /^(\+1\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
          const trimmedInput = this.user.email.trim();

          if(!emailRegex.test(trimmedInput) && !phoneRegex.test(trimmedInput)){
            alert.methods.appToaster({message: 'Enter a valid email address', type: 'ERROR'});
          }

          // Validate email
          if (emailRegex.test(trimmedInput)) {
            this.user.email = this.user.email.toLowerCase();
            this.user.email = this.user.email.trim();
            this.onForgotPasswordMail({data:{ email: this.user.email },callback:()=>{}});
          }

          // // Validate US phone number
          // if (phoneRegex.test(trimmedInput)) {
          //   let phone = ('' + this.user.email).replace(/\D/g, '');
            
          //   if(phone.length === 11 && phone[0] === '1'){
          //     phone = phone.substring(1);
          //   }

          //   if(phone.length === 10){
          //     const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
          //     if (match) {
          //       phone = `+1 (${match[1]}) ${match[2]}-${match[3]}`;
          //       this.user.phone = phone;
          //     }
          //   } 
          //   this.username = true; 
          //   this.user.email = "";          
          // }  
          
        } 
        // else if(!this.otp && this.username && this.user.email) {

        //   if(this.user.phone && this.user.email){
        //     this.onForgotPasswordMail({data:{ phone: this.user.phone, username: this.user.email },callback:()=>{
        //       this.otp=true;
        //     }});
        //   }
          
        // }else if(this.otp && this.username){
        //   let data = {
        //      type: "mobile",
        //     identifier: this.user.phone,
        //     verification_code: this.user.code,
        //     username: this.user.email
        //   };
        //   this.verifyVerificationCode({
        //   data: data,
        //   callback: (token:string) => {
        //     this.user.security_code = token;
        //     this.otp = false;
        //     this.isSubmitted = false;
        //     this.username = false;
        //   },
        // });
        // }
      }
    });
  }

  onCancel() {
    this.$router.push({ name: "login.index" });
  }

  onBack() {
    this.otp = false;
    this.user.email = "";
    this.user.phone = "";
    this.username = false;
    this.isSubmitted = false;
  } 

  resendCode() {
    this.onForgotPasswordMail({data:{ phone: this.user.phone, username: this.user.email },callback:()=>{
              this.otp=true;
    }});
  }
}
