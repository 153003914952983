import Proxy from "./Proxy";

class AuthProxy extends Proxy {
  /**
   * Constructor for Auth proxy
   */
  constructor() {
    super(`/users`);
  }

  public login(data: any) {
    return this.submit("post", `/login`, undefined, data);
  }

  public getLoggedInUser() {
    return this.submit("get", "/logged-in-user");
  }

  public registerUser(data: any) {
    return this.submit("post", this.endpoint, undefined, data);
  }

  public updatePreference(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }


  public updateFavoriteuser(data: any) {
    return this.submit("patch", this.endpoint, {where: JSON.stringify(data.params)}, data.body);
  }

  public resetPassword(data: any) {
    return this.submit("patch", `/password-reset`, undefined, data);
  }

  public onForgotPasswordMail(data: any) {
    return this.submit("post", `/forgot-password`, undefined, data);
  }

  public getAccessTokenByRefreshToken(data: any) {
    return this.submit("post", `/refresh-token`, undefined, data);
  }

  public getPendingUsers(filter: any) {
    return this.submit("get", `/pending-users`,  {filter: JSON.stringify(filter)});
  }

  public getUsers(data: any) {
    return this.getList(data);
  }

  public getUserListForDropdown(data: any) {
    return this.submit("get", `users/list`,  {filter: JSON.stringify(data)});
  }

  public getListCount(data: any) {
    return this.submit("get", `users/count`,  {where: JSON.stringify(data)});
  }

  public bulkUpdate(data: any) {
    const filter = {
      "where": {
        "id": { "inq": data.ids }
      }
    };
    return this.submit("patch", `${this.endpoint}/bulkupdate`, {filter: JSON.stringify(filter)}, data);
  }

  // Subscription
  public getBillingHistoryById(id: any) {
    return this.submit("get", `${this.endpoint}/${id}/subscriptions`);
  }

  public addSubscriptionPlan(data: any) {
    const userId = data.userId;
    delete data.userId;
    return this.submit("patch", `${this.endpoint}/${userId}/subscription`, undefined, data);
  }

  public addOrder(data: any) {
    return this.submit('post', '/subscriptionplans/createorder', undefined, data);
  }

  public getSubscriptionPlans() {
    const filter = {
      where: {
        status: true
      }
    };
    return this.submit("get", `/subscriptionplans`, {filter: JSON.stringify(filter)});
  }

  public getSubscriptionPlanById(id: string) {
    return this.submit("get", `/subscriptionplans/${id}`);
  }

  public transferMeeting(data: { fromUser: number, toUser: number }) {
    return this.submit('post', '/meetings/bulk-transfer', undefined, data)
  }
  public getHealthCareDomains() {
    return this.submit("get", `/healthcaredomains`);
  }

  public paymentForPlan(data: any) {
    return this.submit("post", '/subscriptionplans/payment', undefined, data)
  }

  public addAgreementAudit(data: any) {
    return this.submit("patch", `/users/${data.id}/agreement`, undefined,{ is_agreed_subscription: data.is_agreed_subscription })
  }

  public addOrUpdatePracticeLocation(data: any) {
    return this.submit("post", '/user-locations', undefined, data);
  }

  public getPracticeLocationByUser(filter: any) {
    return this.submit("get", `/user-locations`, {filter:JSON.stringify(filter)});
  }

  public getOrganizationTypes(filter: any) {
    return this.submit("get", `/organization-types`, {filter: JSON.stringify(filter)})
  }

  public updateVaccinationStatus(id: any, data: any, headers?: any) {
    return this.submit("patch", `/users/${id}/vaccination`, undefined, data, headers);
  }

  public deleteVaccinationCertificate(id: string) {
    return this.submit("delete", `${this.endpoint}/vaccination/${id}`);
  }

  public switchUser(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `/user-types/${id}/user`, undefined, body);
  }

  public resetUserPassword(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `/users/${id}/password-reset`, undefined, body);
  }

  public getSettingValue(filter: any) {
    return this.submit("get", `/settings`,  {filter: JSON.stringify(filter)})
  }

  public getPowerBIReport(data: any) {
    return this.submit("post", `/powerbi`, undefined, data);
  }

  public getReportList() {
    return this.submit("get", `/powerbi/reports`)
  }

  public submitHIPAAForm(id: any, data: any, headers?: any) {
    return this.submit("patch", `/users/${id}/hipaa`, undefined, data, headers);
  }

  public sendVerificationCode(data: any) {
    return this.submit("post", `/verification`, undefined, data)
  }

  public verifyVerificationCode(data: any) {
    return this.submit("post", `/verification`, undefined, data)
  }

  public sendProfileVerificationCode(data: any) {
    return this.submit("post", `/profile/verification`, undefined, data)
  }

  public verifyProfileVerificationCode(data: any) {
    return this.submit("post", `/profile/verification`, undefined, data)
  }

  public updateUnapprovedUserDetails(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `/profile/${id}`, undefined, body);
  }

  public signOut(data: any = {}) {
    return this.submit('post', `/logout`, undefined, data);
  }

  public updateUserDetails(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}/details`, undefined, body);
  }


}

export default AuthProxy;
