/* ============
 * Actions for the Tenant module
 * ============
 *
 * The actions that are available on the
 * Tenant module.
 */
import types from "./mutation-types";
import TenantProxy from "@/proxies/TenantProxy";
import loadingTypes from "@/store/mutation-types";


const getTenants = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new TenantProxy()
    .getTenants(data)
    .then((response: any) => {
      commit(types.GET_TENANTS_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_TENANTS_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    });
};

export default {
  getTenants
};
