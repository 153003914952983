/**
 * ==============
 * Initial state of Tenant module
 */
export class State {
  public error = "";
  public tenants = [];


}
