/* ============
 * Mutation types for the Policy module
 * ============
 *
 * The mutation types that are available
 * on the Policy module.
 */
import keyMirror from "keymirror";

export default keyMirror({
  TENANT_POLICY_CREATE_INIT: null,
  TENANT_POLICY_CREATE_SUCCESS: null,
  TENANT_POLICY_CREATE_FAIL: null,

  TENANT_POLICY_UPDATE_INIT: null,
  TENANT_POLICY_UPDATE_SUCCESS: null,
  TENANT_POLICY_UPDATE_FAIL: null,

  GET_TENANT_POLICY_INIT: null,
  GET_TENANT_POLICY_SUCCESS: null,
  GET_TENANT_POLICY_FAIL: null,

  FETCH_TENANT_POLICY_LIST: null,
  FETCH_TENANT_POLICY_LIST_SUCCESS: null,
  FETCH_TENANT_POLICY_LIST_FAIL: null,

  DELETE_TENANT_POLICY_INIT: null,
  DELETE_TENANT_POLICY_SUCCESS: null,
  DELETE_TENANT_POLICY_FAIL: null,

  SET_TENANT_POLICY: null,
  INIT_TENANT_POLICY: null,

  GET_POLICIES_COUNT_SUCCESS: null,
  GET_POLICIES_COUNT_FAIL: null,

});
