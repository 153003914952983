


















































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import constants from "@/constants/app.properties";

@Component({})
export default class SideBar extends Vue {
  @Action private logout: any;
  @Action private getUnreadTotalCount: any;
  @Getter public role!: any;
  @Getter public unreadTotalCount: any;
  @Getter public isCurrentTenantBlocked: any;
  @Getter public currentUser: any;
  isRegisteredUser = true;

  beforeMount() {
    // this.getUnreadTotalCount();
  }

  onLogout(): void {
    this.logout();
  }

  get isMedicalRep() {
    return localStorage.getItem(constants.IS_MEDICAL_REP);
  }
}
