/* ============
 * Mutation types for the Appointment Type module
 * ============
 *
 * The mutation types that are available
 * on the Appointment Type module.
 */
import keyMirror from "keymirror";

export default keyMirror({
  APPOINTMENT_TYPE_CREATE_INIT: null,
  APPOINTMENT_TYPE_CREATE_SUCCESS: null,
  APPOINTMENT_TYPE_CREATE_FAIL: null,

  APPOINTMENT_TYPE_UPDATE_INIT: null,
  APPOINTMENT_TYPE_UPDATE_SUCCESS: null,
  APPOINTMENT_TYPE_UPDATE_FAIL: null,

  GET_APPOINTMENT_TYPE_INIT: null,
  GET_APPOINTMENT_TYPE_SUCCESS: null,
  GET_APPOINTMENT_TYPE_FAIL: null,

  FETCH_APPOINTMENT_TYPE_LIST: null,
  FETCH_APPOINTMENT_TYPE_LIST_SUCCESS: null,
  FETCH_APPOINTMENT_TYPE_LIST_FAIL: null,

  DELETE_APPOINTMENT_TYPE_INIT: null,
  DELETE_APPOINTMENT_TYPE_SUCCESS: null,
  DELETE_APPOINTMENT_TYPE_FAIL: null,

  SET_APPOINTMENT_TYPE: null,
  INIT_APPOINTMENT_TYPE: null,

  GET_APPOINTMENT_TYPES_COUNT_SUCCESS: null,
  GET_APPOINTMENT_TYPES_COUNT_FAIL: null,

});
