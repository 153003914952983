/* ============
 * Actions for the Appointment Type module
 * ============
 *
 * The actions that are available on the
 * Appointment Type module.
 */
import types from "./mutation-types";
import AppointmentTypeProxy from "@/proxies/AppointmentTypeProxy";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";
import loadingTypes from "@/store/mutation-types";

const getAppointmentTypes = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AppointmentTypeProxy()
    .getAppointmentTypes(data)
    .then((response: any) => {
      commit(types.FETCH_APPOINTMENT_TYPE_LIST_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.FETCH_APPOINTMENT_TYPE_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const createAppointmentType = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AppointmentTypeProxy()
    .create(data)
    .then((response: any) => {
      commit(types.APPOINTMENT_TYPE_CREATE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.APPOINTMENT_TYPE_CREATED_SUCCESS, type: "SUCCESS" });
      callback();
    })
    .catch((error: any) => {
      commit(types.APPOINTMENT_TYPE_CREATE_FAIL, error.error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const updateAppointmentType = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new AppointmentTypeProxy()
    .updateAppointmentType(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.APPOINTMENT_TYPE_UPDATED_SUCCESS, type: "SUCCESS" });
      callBack();
    })
    .catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const initAppointmentType = ({ commit }: any) => {
  commit(types.INIT_APPOINTMENT_TYPE);
};

const getAppointmentTypesCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AppointmentTypeProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_APPOINTMENT_TYPES_COUNT_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_APPOINTMENT_TYPES_COUNT_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getAppointmentType = ({ commit }: any, id: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.GET_APPOINTMENT_TYPE_INIT);
  new AppointmentTypeProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_APPOINTMENT_TYPE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch(({ error }: any) => {
      commit(types.GET_APPOINTMENT_TYPE_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const setAppointmentType = ({ commit }: any, appointmentType: any) => {
  commit(types.SET_APPOINTMENT_TYPE, appointmentType);
};


const getAppointmentTypesByLocation = ({ commit }: any, { id, filter }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.GET_APPOINTMENT_TYPE_INIT);
  new AppointmentTypeProxy()
    .getAppointmentTypesByLocation(id, filter)
    .then((response: any) => {
      commit(types.FETCH_APPOINTMENT_TYPE_LIST_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch(({ error }: any) => {
      commit(types.FETCH_APPOINTMENT_TYPE_LIST_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};


export default {
  getAppointmentTypes,
  updateAppointmentType,
  createAppointmentType,
  initAppointmentType,
  setAppointmentType,
  getAppointmentType,
  getAppointmentTypesCount,
  getAppointmentTypesByLocation
};
