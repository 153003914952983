



































































































import { Component, Vue } from "vue-property-decorator";
import Table from "@/components/table/Table.vue";
import { Action, Getter } from "vuex-class";
import constants from "@/constants/app.properties";
import AgreementModal from "@/components/agreementModal/AgreementModal.vue";
import OrderModal from "@/components/orderModal/OrderModal.vue";
import alert from "@/mixins/alert";
import moment from "moment";

@Component({
  components: { 
    Table,
    AgreementModal,
    OrderModal
   }
})
export default class Subscription extends Vue {
  @Action getBillingHistoryById!: any;
  @Action getSubscriptionPlans!: any;
  @Action addSubscriptionPlan!: any;
  @Action createAgreementAudit!: any;
  @Getter billingHistories: any;
  @Getter subscriptionPlans: any;
  @Getter currentUser!: any;
  @Action addOrder: any;

  locationId = process.env.VUE_APP_SQUARE_LOCATION_ID;
  $refs!: {
    agreementModal: HTMLFormElement;
    orderModal: HTMLFormElement;
  }
  selectedPlan = 0;
  selectedPlanObj: any = {};
  fields = [
    { key: "plan_start_date", label: "Start Date", class: "text-center" },
    { key: "plan_end_date", label: "End Date", class: "text-center" },
    { key: "plan_cost", label: "Amount", class: "text-center" },
    { key: "plan_status", label: "Status", class: "text-center" },
    { key: "receipt_url", label: 'Receipt', class: "text-center"}
  ];

  beforeMount() {
    const userId = localStorage.getItem(constants.USER_ID);
    this.getBillingHistoryById(userId);
    this.getSubscriptionPlans({callback: () => {
      this.selectedPlan = this.subscriptionPlans[0].id;
    }});
  }

  get isEnableProceedbtn() {
    if (this.currentUser.tenant?.enable_subscription) {
      const currentTime = moment.utc(
        moment().format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      let subscriptionTime = moment.utc(
        moment(this.currentUser.subscription_end_date,
        "YYYY-MM-DD[T]HH:mm[Z]").format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      const noOfDaysLeft = subscriptionTime.diff(currentTime, 'days') + 1;
      return isNaN(noOfDaysLeft) ? true : noOfDaysLeft <= 30;
    }
    return false;
  }

  gracePeriodLeft() {
    const currentTime = moment.utc(
        moment().format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      let subscriptionTime = moment.utc(
        moment(this.currentUser.subscription_end_date,
        "YYYY-MM-DD[T]HH:mm[Z]").format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      let subscriptionStartDate = moment.utc(
        moment(this.currentUser.tenant.subscription_start_date,
        "YYYY-MM-DD[T]HH:mm[Z]").format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      if(this.currentUser.subscription_end_date == null && subscriptionStartDate.isSameOrBefore(currentTime)) {
        subscriptionStartDate.add(this.currentUser.tenant.subscription_grace_days, 'days');
        if(subscriptionStartDate.isSameOrAfter(currentTime)) {
          const noOfDaysLeft = subscriptionStartDate.diff(currentTime, 'days');
          if(noOfDaysLeft > 0) {
            return noOfDaysLeft === 1 ? "Your grace period end's today": `Your grace period end's in ${noOfDaysLeft} days`;
          } else {
            return '';
          }
        }
      } else {
        subscriptionTime.add(this.currentUser.tenant.subscription_grace_days, 'days');
        const noOfDaysLeft = subscriptionTime.diff(currentTime, 'days') + 1;
        if(noOfDaysLeft > 0) {
          return noOfDaysLeft === 1 ? "Your grace period end's today": `Your grace period end's in ${noOfDaysLeft} days`;
        } else {
          return '';
        }
      }
  }

  get isOnGracePeriod() {
    if (this.currentUser.tenant?.enable_subscription) {
      const currentTime = moment.utc(
        moment().format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      let subscriptionStartDate = moment.utc(
        moment(this.currentUser.tenant.subscription_start_date,
        "YYYY-MM-DD[T]HH:mm[Z]").format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      let subscriptionTime = moment.utc(
        moment(this.currentUser.subscription_end_date,
        "YYYY-MM-DD[T]HH:mm[Z]").format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );
      if(!this.currentUser.tenant.subscription_grace_days) {
        return false;
      }
      if(this.currentUser.subscription_end_date == null && subscriptionStartDate.isSameOrBefore(currentTime)) {
        subscriptionStartDate.add(this.currentUser.tenant.subscription_grace_days, 'days');
        if(subscriptionStartDate.isSameOrAfter(currentTime)) {
          return true;
        }
      }
      if(subscriptionTime.isBefore(currentTime)) {
        subscriptionTime.add(this.currentUser.tenant.subscription_grace_days, 'days');
        if(subscriptionTime.isSameOrAfter(currentTime)) {
          return true;
        }
      }
    }
    return false;
  }

  showOrderForm() {
    this.selectedPlanObj = this.subscriptionPlans.filter((plan: {id: number}) => plan.id === this.selectedPlan)[0];
    const orderModal = this.$refs.orderModal;
    orderModal.show();
  }

  showAgreementModal() {
    if (!this.currentUser.is_agreed_subscription) {
      const agreementModal = this.$refs.agreementModal;
      agreementModal.show();
    } else if (this.currentUser.is_agreed_subscription) {
      this.showOrderForm();
    }
  }

  onProceedPayment(): void {
    if (this.currentUser.tenant.enable_subscription) {
      this.addOrder({
        data: {
          locationId: this.locationId,
          amount: Number((this.selectedPlanObj.plan_cost * 100).toFixed(0))
        },
        callBack: this.navigateToPayment
      });
    } else {
      alert.methods.appToaster({
        message: 'You are not allowed to proceed the payment. Kindly contact administrator.',
        type: "WARNING",
      });
    }
  }

  navigateToPayment(): void {
    this.$router.push({ path: `payment/${this.selectedPlan}` });
  }

  agreementAgreed(value: any){
    this.createAgreementAudit({ id: this.currentUser.id, is_agreed_subscription: value});
    if (value) {
      this.showOrderForm();
    }
  }
}
