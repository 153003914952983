








































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import Multiselect from "vue-multiselect";
import LetterIcon from "@/components/letterIcon/LetterIcon.vue";
import _ from "lodash";

@Component({
  components: {
    Multiselect,
    LetterIcon
  },
})
export default class TeamForm extends Vue {
  @Action public createTeam: any;
  @Action public updateTeam: any;
  @Action public getUserListForDropdown: any;
  @Getter public users: any;
  @Getter public team: any;
  @Getter public currentUser: any;

  action = "add";
  isSubmitted = false;
  isRefreshList = false;
  $refs!: {
    teamForm: InstanceType<typeof ValidationObserver>;
  };
  teamMembers: any = [];

  beforeMount() {
    this.getUserListForDropdown({
      where: { userTypesId: { neq: 1 }, is_medical_rep: true, status: {nin: [0, 2] }, is_blocked: false},
      order: ["first_name ASC"],
    });
    if (this.team && this.team.id) {
      this.action = "edit";
      this.team.user_id = [];
        this.team.teamMembers.forEach((member: any) => {
          this.team.user_id.push(member.userId);
          member.full_name = member.first_name + member.last_name;
          this.teamMembers.push(member.user);
        });
    }
  }

  setTeamMembers(values: any) {
    this.teamMembers = values;
    const groupedById = _.groupBy(values, value => value.id);
    const result = Object.keys(groupedById);
    this.team.user_id = result;
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.teamForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }

        const errors = Object.entries(this.$refs.teamForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.teamForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        this.isRefreshList = true;
        if (this.action === "add") {
          this.team.user_id.push(this.currentUser.id);
          this.createTeam({
            data: this.team,
            callback: this.onActionPerformed,
          });
        } else {
          this.team.user_id && this.team.user_id.length === 0
            ? this.team.user_id.push(this.currentUser.id)
            : "";
          delete this.team.created_at;
          delete this.team.updated_at;
          delete this.team.created_by;
          delete this.team.updated_by;
          delete this.team.status;
          delete this.team.tenantId;
          delete this.team.teamMembers;
          this.updateTeam({
            data: this.team,
            callback: this.onActionPerformed,
          });
        }
      }
    });
  }

  onCancel() {
    this.isRefreshList = false;
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.onActionPerformed();
      },
      () => {}
    );
  }

  onActionPerformed() {
    this.$emit("actionPerformed", this.isRefreshList);
  }
}
