
















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class VSelect extends Vue {
  @Prop() label!: any;
  @Prop({ default: () => {} }) labelAttrs!: string;
  @Prop({ default: () => [] }) options!: any;
  @Prop() inputValue!: any;
  @Prop({ default: false }) disabled!: any;
  @Prop({ default: "text" }) textField!: string;
  @Prop({ default: "value" }) valueField!: string;
  @Prop() placeholder!: string;
  @Prop() rules!: string;
  @Prop() isSubmitted!: boolean;

  showoptions = false;
  value: any = this.inputValue;

  getValueText(value: any) {
    let valueText = "";
    if (value && this.options) {
      this.options &&
        this.options.map((option: any) => {
          if (value === option[this.valueField]) {
            valueText = option[this.textField];
          }
        });
    }
    return valueText;
  }

  @Watch("inputValue")
  onInputValueChange() {
    if (this.inputValue !== this.value) {
      this.value = this.inputValue;
    }
  }

  onSelectValue(option: any) {
    this.toggleDropdown(false);
    if (this.value !== option[this.valueField]) {
      this.value = option[this.valueField];
      this.$emit("getInputValue", this.value);
    }
  }

  clickAway() {
    this.toggleDropdown(false);
  }

  toggleDropdown(value = !this.showoptions) {
    if (!this.disabled) this.showoptions = value;
  }
}
