









import { Component, Vue, Prop } from "vue-property-decorator";
import { lettersColor } from "@/utils/letterIconCss";

@Component
export default class LetterIcon extends Vue {
  @Prop({default: ""}) public initialLetter: string | undefined;
  lettersColor: any = lettersColor;
}
