import { State } from "./state";
import { GetterTree } from "vuex";
import { clone } from "@/utils/object";

export default {
  tenantPolicy(state: State) {
    return clone(state.tenantPolicy);
  },
  tenantPolicyInitialData(state: State) {
    return clone(state.tenantPolicyInitialData);
  },
  tenantPolicies(state: State) {
    return state.tenantPolicies;
  },
  policiesCount: (state: State) => state.policiesCount,

} as GetterTree<State, any>;
