export class State {
  public error = '';
  public locationInitData = {
    name: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    phone:"",
    head_count: 0,
    primary_contact: 0,
    secondary_contact: 0,
    status: true
  };
  public location = {};
  public tenantLocations = [];
  public locationCount = 0;
  public policyPreferenceList = [];
}