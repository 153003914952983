
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {
  [types.GET_MEETING_RULE_LIST_SUCCESS](state: State, response: any) {
    state.meetingRules = response;
  },
  [types.GET_MEETING_RULE_LIST_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_MEETING_RULE_COUNT](state: State, response: any) {
    state.meetingRuleCount = response.count;
  },
  [types.GET_MEETING_RULE_BY_ID_SUCCESS](state: State, response: any) {
    state.meetingRule = response;
  },
  [types.SET_MEETING_RULE](state: State, response: any) {
    state.meetingRule = response;
  },
  [types.CREATE_NEW_MEETING_RULE_SUCCESS](state: State, response: any) {
    state.meetingRule = response.data;
  },
} as MutationTree<State>;