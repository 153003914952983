
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

  [types.FETCH_MESSAGE_PARTICPANTS_LIST](state: State) {
    state.messageParticipantsLoading = true;
  },
  [types.FETCH_MESSAGE_PARTICPANTS_LIST_SUCCESS](state: State, response: any) {
    state.messageParticipantsLoading = false;
    state.messageParticipants = response.messageParticipantsList;
    state.newUsersContactList = response.newUsersContactList;
  },
  [types.FETCH_MESSAGE_PARTICPANTS_LIST_FAIL](state: State, error: any) {
    state.messageParticipantsLoading = false;
    state.error = error;
    state.messageParticipants = [];
  },

  [types.FETCH_UNREAD_TOTAL_COUNT](state: State) {
    state.messageParticipantsLoading = true;
  },
  [types.FETCH_UNREAD_TOTAL_COUNT_SUCCESS](state: State, response: any) {
    state.messageParticipantsLoading = false;
    state.unreadTotalCount = response.count;
  },
  [types.FETCH_UNREAD_TOTAL_COUNT_FAIL](state: State, error: any) {
    state.messageParticipantsLoading = false;
    state.error = error;
    state.unreadTotalCount = 0;
  },

  [types.FETCH_MESSAGES_LIST](state: State) {
    state.messagesLoading = true;
  },
  [types.FETCH_MESSAGES_LIST_SUCCESS](state: State, response: any) {
    state.messagesLoading = false;
    state.messages = response;
  },
  [types.FETCH_MESSAGES_LIST_FAIL](state: State, error: any) {
    state.messagesLoading = false;
    state.error = error;
    state.messages = [];
  },

  [types.CREATE_MESSAGE_INIT](state: State) {
    state.messagesLoading = true;
  },
  [types.CREATE_MESSAGE_SUCCESS](state: State, response: any) {
    state.messagesLoading = false;
    state.message = response;
  },
  [types.CREATE_MESSAGE_FAIL](state: State, error: any) {
    state.messagesLoading = false;
    state.error = error;
  },

  [types.CREATE_MESSAGE_PARTICIPANTS_INIT](state: State) {
    state.messagesLoading = true;
  },
  [types.CREATE_MESSAGE_PARTICIPANTS_SUCCESS](state: State, response: any) {
    state.messagesLoading = false;
    state.messageParticipant = response;
  },
  [types.CREATE_MESSAGE_PARTICIPANTS_FAIL](state: State, error: any) {
    state.messagesLoading = false;
    state.error = error;
  },
  [types.SET_REP_NAME](state: State, data: any) {
    state.repNameToMessage = data;
  }

} as MutationTree<State>;