/*
 * ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import mixins from "./mixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import moment from "moment";
import clickOutside from "@/directives/clickOutside";

/**
 * =========================
 *
 * Plugins
 *
 * =========================
 */

import router from "./plugins/vue-router";
import "./plugins/axios";
import "./plugins/bootstrap-vue";
import "./plugins/vuex-router-sync";
import "./plugins/validation";
import "./plugins/toaster";
import "./plugins/event-bus";
import { Datetime } from 'vue-datetime';
import "./plugins/vselect";

/**
 * Inject mixins that access globally
 */
mixins.forEach((mixin: any) => {
  Vue.mixin(mixin);
});


// Regestering validation components globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('datetime', Datetime);

// Regestering directive  globally
Vue.directive("clickoutside", clickOutside.process);

/**
 * Register moment globally so need not to import in every file
 * Moment instance includes while vue instance creation
 */
Vue.prototype.moment = moment;

Vue.config.productionTip = false;

/**
 * Import form components globally
 */
const requireComponent = require.context(
  // Look for files in the current directory
  './components/formComponents',
  // Do not look in subdirectories
  true,
  // Only include "_base-" prefixed .vue files
  /[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace("/Index.vue", "").replace("./", "");
    Vue.component(componentName, componentConfig.default || componentConfig)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
