
import keyMirror from "keymirror";

export default keyMirror({
  INIT_NEW_LOCATION: null,
  CREATE_NEW_LOCATION_INIT: null,
  CREATE_NEW_LOCATION_SUCCESS: null,
  CREATE_NEW_LOCATION_FAIL: null,

  FETCH_MESSAGE_PARTICPANTS_LIST: null,
  FETCH_MESSAGE_PARTICPANTS_LIST_SUCCESS: null,
  FETCH_MESSAGE_PARTICPANTS_LIST_FAIL: null,

  FETCH_UNREAD_TOTAL_COUNT: null,
  FETCH_UNREAD_TOTAL_COUNT_SUCCESS: null,
  FETCH_UNREAD_TOTAL_COUNT_FAIL: null,

  FETCH_MESSAGES_LIST: null,
  FETCH_MESSAGES_LIST_SUCCESS: null,
  FETCH_MESSAGES_LIST_FAIL: null,

  CREATE_MESSAGE_INIT: null,
  CREATE_MESSAGE_SUCCESS: null,
  CREATE_MESSAGE_FAIL: null,

  CREATE_MESSAGE_PARTICIPANTS_INIT: null,
  CREATE_MESSAGE_PARTICIPANTS_SUCCESS: null,
  CREATE_MESSAGE_PARTICIPANTS_FAIL: null,

  SET_REP_NAME: null,
});