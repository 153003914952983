











































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";

@Component({})
export default class LocationForm extends Vue {
  @Action public createLocation: any;
  @Action public initNewLocation: any;
  @Action public getUserListForDropdown: any;
  @Action private getLocationById: any;
  @Action public updateLocation: any;
  @Action public setLocation: any;
  @Getter public locationInitData: any;
  @Getter public location: any;
  @Getter public locations: any;
  @Getter public currentUser: any;
  @Getter public users: any;
  @Getter private role!: any;
  action = "add";
  isSameAsEmail = false;
  isSubmitted = false;
  $refs!: {
    locationForm: InstanceType<typeof ValidationObserver>;
  };
  selectedPolicies = [];
  locationId = "";
  searchPrimaryContact= "";
  userFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      userTypesId: { eq: 2 },
      is_medical_rep: false,
    },
    order: ["first_name ASC"],
  };

  beforeMount() {
    this.getUserListForDropdown({where: { userTypesId: { eq: 2 }, status: true, is_medical_rep: false,is_blocked: false }, order: ["first_name ASC"],});
    this.locationId = this.$route.params.id;
    if (this.locationId) {
      this.action = "edit";
      this.getLocationById(this.locationId);
    }
  }

  destroyed() {
    this.initNewLocation();
  }

  setPrimaryContact(value: any): any {
    this.location.primary_contact = value;
  }

  setSecondaryContact(value: any): any {
    this.location.secondary_contact = value;
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.locationForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.locationForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.locationForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        if (this.action === "add") {
          this.location.head_count = Number(this.location.head_count || 0);
          this.location.primary_contact = this.location.primary_contact === null ? 0 : this.location.primary_contact;
          this.location.secondary_contact = this.location.secondary_contact === null ? 0 : this.location.secondary_contact;
          this.createLocation({
            data: this.location,
            callBack:() => {this.$router.push({ name: "locations.index" }).catch()},
          });
            this.setLocation(this.locationInitData);
          setTimeout(() => {
            this.onActionPerformed();
          }, 1000);
        } else {
          if (this.location.head_count === null) {
            this.location.head_count = 0;
          } else {
            this.location.head_count = Number(this.location.head_count || 0);
          }
          this.location.primary_contact = this.location.primary_contact === null ? 0 : this.location.primary_contact;
          this.location.secondary_contact = this.location.secondary_contact === null ? 0 : this.location.secondary_contact;
          const {
            id,
            name,
            phone,
            head_count,
            primary_contact,
            secondary_contact,
            address,
            city,
            state,
            zipcode,
            country,
          } = this.location;
          this.updateLocation({
            data: {
              id,
              name,
              phone,
              head_count,
              primary_contact,
              secondary_contact,
              address,
              city,
              state,
              zipcode,
              country,
            },
            callBack: () => {
              this.getLocationById(this.locationId);
            },
          });
          setTimeout(() => {
            this.getLocationById(this.locationId);
          }, 500);
        }
      }
    });
  }

  onActionPerformed() {
    this.$emit("actionPerformed");
  }

  onCancel(): void {
    this.setLocation(this.locationInitData);
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.$router.push({ name: "locations.index" }).catch();
        this.onActionPerformed();
      },
      () => {}
    );
  }

  backToLocations(): void {
    this.$router.push({ name: "locations.index" }).catch();
  }
}
