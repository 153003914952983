import { State } from "./state";
import { GetterTree } from "vuex";
import { clone } from "@/utils/object";

export default {
  appointmentType(state: State) {
    return clone(state.appointmentType);
  },
  appointmentTypeInitialData(state: State) {
    return clone(state.appointmentTypeInitialData);
  },
  appointmentTypes(state: State) {
    return state.appointmentTypes;
  },
  appointmentTypesCount: (state: State) => state.appointmentTypesCount,

} as GetterTree<State, any>;
