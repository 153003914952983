

























import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

@Component
export default class TextAreaInput extends Vue {
  @Prop() private rules!: string;
  @Prop() private inputValue!: string;
  @Prop() private label!: string;
  @Prop({ default: "2" }) private rows!: string;
  @Prop({ default: "" }) placeholder!: string;
  @Prop({default: 60000}) max!: number; 
  @Prop({ default: false }) disabled!: boolean;

  value: any = this.inputValue;

  @Emit("getInputValue")
  public onChangeInput() {
    return this.value;
  }

  @Watch('inputValue')
  onInputValueChanged(value: any) {
    if(value !== this.value) {
      this.value = value;
    }
  }
}
