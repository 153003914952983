import Proxy from "./Proxy";

class PhysicianProxy extends Proxy {
  /**
   * Constructor for Auth proxy
   */
  constructor() {
    super(`/physicians`);
  }

  public updatePhysician(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }

  public getPhysicians(data: any) {
    return this.submit("get", `${this.endpoint}/list`, {filter: JSON.stringify(data)});
  }

  public createPhysician(data: any) {
    return this.submit("post", this.endpoint, undefined, data);
  }

  public getListCount(data: any) {
    return this.submit("get", `physicians/count`, {where: JSON.stringify(data)});
  }

  public getPhysicianByUserId(data: any) {
    return this.submit("get", `physicians/${data}/user`, {type: 'physician'});
  }

}

export default PhysicianProxy;
