import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import permissionKeys from "@/constants/_permissions";
import { isNotEmpty } from "@/utils/object";
import { isEmpty } from "lodash";


/** Declaring variables to handle route changes
  * without state. so that it will not
  * get initialised more than once for each module
  * */
const rolePermission: any = {};
let modulePermissions: any = [];
let currentModule: any = null;

@Component
export default class Role extends Vue {
  modulePermissions: any;
  currentModulePermissions = {};
  isPermissionsLoaded = isNotEmpty(rolePermission);
  @Getter rolePermissions: any;
  @Getter defaultRoute: any;
  @Action setCurrentModulePermissions: any;

  get rolePermission() {
    return rolePermission;
  }
  get currentModule() {
    return currentModule;
  }

  /**
    * Handling module permissions based on logged in user role.
    * @param {*} modulePermissions => logged in user role permissions.
    */
  @Watch('rolePermissions')
  onRolePermissionsChange(modulePermission: { [x: string]: any; }) {
    const { permission } = this.$route.meta;
    if (isNotEmpty(modulePermission)) {
      const permissions: any = {};
      Object.keys(modulePermission).forEach((moduleName) => {
        permissions[moduleName.toLowerCase()] = modulePermission[moduleName];
      });
      modulePermissions = permissions;
      this.modulePermissions = permissions;
      this.isPermissionsLoaded = true;
      this.authorise(permission);
      this.handlePermissionForCurrentModule();
    }
  }

  /**
   * Authorise for current logged in user to has permission
   * @param {*} module - module to authorise
   * @param {*} permission - permission to find
   */
  isUserHasPermission(permission: string, moduleName = currentModule) {
    permission = permission.toLowerCase();
    return modulePermissions[moduleName] ?
      (modulePermissions[moduleName][permission] || false)
      : false;
  }

  /**
   * Authorise for current logged in user to not have permission
   * @param {*} module - module to authorise
   * @param {*} permission - permission to find
   */
  isUserHasNotPermission(permission: any, moduleName = currentModule) {
    return !this.isUserHasPermission(permission, moduleName);
  }

  /**
   * Setting module permissions based on route path
   * @param {*} path -> expecting route path to contain moduleName
   */
  @Watch('$route.path', { deep: true, immediate: true })
  handleRouteChange() {
    const { module, permission } = this.$route.meta;
    if (module && permission && (module.toLowerCase() !== currentModule)) {
      currentModule = module.toLowerCase();
      if (this.isPermissionsLoaded) this.handlePermissionForCurrentModule();
      this.authorise(permission);
    }
  }

  /**
   * Filtering permissons based on module name.
   * @param {*} moduleName -> returns all permissions for a specific module
   */
  getPermissionsForModule(moduleName: string | number) {
    return modulePermissions[moduleName];
  }

  /**
   * Default self authorising for view on a particular module when route changes
   * Redirect to defaultRoute for a specific page based on login user role.
   */
  @Watch('isPermissionsLoaded')
  authorise(permission = permissionKeys.READ) {
    if (typeof (permission) === 'boolean') permission = permissionKeys.READ;
    if (this.isPermissionsLoaded) {
      let moduleVal = currentModule;
      if (!isEmpty(this.$route.meta) && this.$route.meta.requiresAuth && this.$route.name === "registerApproval.index") {
        moduleVal = this.$route.meta.module.toLowerCase();
        permission = this.$route.meta.permission;
      }
      if (!moduleVal && !isEmpty(this.$route.meta) && this.$route.meta.requiresAuth) {
        moduleVal = this.$route.meta.module.toLowerCase();
      }
      if (!this.isUserHasPermission(permission.toLowerCase(), moduleVal) &&
        !isEmpty(this.$route.meta) && this.$route.meta.requiresAuth) {
        this.$router.push({
          name: this.defaultRoute ? this.defaultRoute : 'meeting.list'
        }).catch();
      } else {
        this.handlePermissions();
      }
    }
  }

  /**
   * Loads all permissions for a specific module.
   */
  handlePermissionForCurrentModule() {
    this.currentModulePermissions = modulePermissions[currentModule];
  }

  handlePermissions() {
    if (this.isPermissionsLoaded) {
      this.setCurrentModulePermissions({ currentModule, modulePermissions: modulePermissions[currentModule] })
    }
  }
}
