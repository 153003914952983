/* ============
 * Mutation  for the Policy module
 * ============
 *
 * The mutation types that are available
 * on the Policy module.
 */

import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";
import array from '@/utils/array';

export default {
  [types.TENANT_POLICY_CREATE_INIT](state: State) {
    state.tenantPoliciesLoading = true;
  },
  [types.TENANT_POLICY_CREATE_SUCCESS](state: State) {
    state.tenantPoliciesLoading = false;
    state.tenantPolicy = state.tenantPolicyInitialData;
  },
  [types.TENANT_POLICY_CREATE_FAIL](state: State, error: any) {
    state.tenantPoliciesLoading = false;
    state.error = error;
  },

  [types.TENANT_POLICY_UPDATE_INIT](state: State) {
    state.tenantPoliciesLoading = true;
  },
  [types.TENANT_POLICY_UPDATE_SUCCESS](state: State) {
    state.tenantPoliciesLoading = false;
    state.tenantPolicy = state.tenantPolicyInitialData;
  },
  [types.TENANT_POLICY_UPDATE_FAIL](state: State, error: any) {
    state.tenantPoliciesLoading = false;
    state.error = error;
  },

  [types.GET_TENANT_POLICY_INIT](state: State) {
    state.tenantPolicy = state.tenantPolicyInitialData;
    state.tenantPoliciesLoading = true;
  },
  [types.GET_TENANT_POLICY_SUCCESS](state: State, response: any) {
    state.tenantPoliciesLoading = false;
    state.tenantPolicy = response;
  },
  [types.GET_TENANT_POLICY_FAIL](state: State, error: any) {
    state.tenantPoliciesLoading = false;
    state.tenantPolicy = state.tenantPolicyInitialData;
    state.error = error;
  },

  [types.FETCH_TENANT_POLICY_LIST](state: State) {
    state.tenantPoliciesLoading = true;
  },
  [types.FETCH_TENANT_POLICY_LIST_SUCCESS](state: State, response: any) {
    state.tenantPoliciesLoading = false;
    state.tenantPolicies = response;
  },
  [types.FETCH_TENANT_POLICY_LIST_FAIL](state: State, error: any) {
    state.tenantPoliciesLoading = false;
    state.error = error;
    state.tenantPolicies = [];
  },
  [types.SET_TENANT_POLICY](state: State, policy: any) {
    state.tenantPolicy = policy;
  },

  [types.INIT_TENANT_POLICY](state: State) {
    state.tenantPolicy = state.tenantPolicyInitialData;
  },


  [types.GET_POLICIES_COUNT_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_POLICIES_COUNT_SUCCESS](state: State, policiesCount: any) {
    state.policiesCount = policiesCount.count;
  },
  [types.DELETE_TENANT_POLICY_INIT](state: State) {
    state.tenantPoliciesLoading = true;
  },
  [types.DELETE_TENANT_POLICY_SUCCESS](state: State, id: any) {
    state.tenantPoliciesLoading = false;
    state.tenantPolicies.splice(array.findIndex(state.tenantPolicies, { id }), 1);
  },
  [types.DELETE_TENANT_POLICY_FAIL](state: State, error: any) {
    state.tenantPoliciesLoading = false;
    state.error = error;
  },

  
} as MutationTree<State>;
