var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar open text-center"},[(_vm.role === 'SUPER_ADMIN')?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{ path: '/dashboard' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/dashboard.png")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Dashboard")])])])]}}],null,false,2352727714)})],1):_vm._e(),(_vm.currentUser.id)?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",class:[_vm.isCurrentTenantBlocked && 'disabled'],attrs:{"to":{ path: '/meeting' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/meeting-menu.png")}}),(_vm.role === 'SUPER_ADMIN' || _vm.role === 'ADMIN')?_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Booked"),_c('br'),_vm._v("Meetings")]):_c('div',{staticClass:"menu-text pt-2"},[_vm._v("My"),_c('br'),_vm._v("Meetings")])])])]}}],null,false,351800601)})],1):_vm._e(),(_vm.currentUser.id)?_c('div',{staticClass:"my-3 mx-2"},[_c('router-link',{staticClass:"router-link menu-text",class:[_vm.isCurrentTenantBlocked && 'disabled'],attrs:{"to":{ path: '/book-meeting' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/book-meetings-menu.png")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Book"),_c('br'),_vm._v("Meeting")])])])]}}],null,false,1314728711)})],1):_vm._e(),(_vm.currentUser.id)?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",class:[_vm.isCurrentTenantBlocked && 'disabled'],attrs:{"to":{ path: '/past-meeting' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/meeting-menu.png")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Past"),_c('br'),_vm._v(" Meetings")])])])]}}],null,false,1254952956)})],1):_vm._e(),(_vm.currentUser.tenant && _vm.currentUser.tenant.tenantpreferences.required_fields.inter_company_transfer && (_vm.role === 'SUPER_ADMIN' || _vm.role === 'ADMIN'))?_c('div',{staticClass:"my-3 mx-2"},[_c('router-link',{staticClass:"router-link menu-text",class:[_vm.isCurrentTenantBlocked && 'disabled'],attrs:{"to":{ path: '/meeting-approval' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/book-meetings-menu.png")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Meeting"),_c('br'),_vm._v("Approvals")])])])]}}],null,false,1139868250)})],1):_vm._e(),(_vm.role !== 'PHYSICIAN')?_c('div',{staticClass:"my-3 py-1 mx-2 position-relative"},[_c('router-link',{staticClass:"router-link",class:[_vm.isCurrentTenantBlocked && 'disabled'],attrs:{"to":{ path: '/messages' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/messages.svg")}}),_c('div',{staticClass:"menu-text"},[_vm._v("Messages")]),(_vm.unreadTotalCount)?_c('span',{staticClass:"text-right rounded-circle font-12 mx-1 py-1 px-2 unread-message-total"},[_vm._v(_vm._s(_vm.unreadTotalCount)+" ")]):_vm._e()])])]}}],null,false,2229618424)})],1):_vm._e(),(_vm.isMedicalRep)?_c('div',{staticClass:"my-3 py-1 mx-2 position-relative"},[_c('router-link',{staticClass:"router-link",class:[_vm.isCurrentTenantBlocked && 'disabled'],attrs:{"to":{ path: '/practice' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/practice.svg")}}),_c('div',{staticClass:"menu-text"},[_vm._v("Practice/Location")])])])]}}],null,false,1566866864)})],1):_vm._e(),(_vm.role === 'SUPER_ADMIN' || _vm.role === 'ADMIN')?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{ path: '/locations' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/location-menu.svg")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Location"),_c('br'),_vm._v("Management")])])])]}}],null,false,3870825169)})],1):_vm._e(),(_vm.role === 'SUPER_ADMIN' || _vm.role === 'ADMIN')?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{ path: '/physician-log' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/physician-room.png")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Physician"),_c('br'),_vm._v("Log")])])])]}}],null,false,807348849)})],1):_vm._e(),(_vm.role === 'SUPER_ADMIN')?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{ path: '/registerApproval' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/pending-approvals.png")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Pending"),_c('br'),_vm._v("Approvals")])])])]}}],null,false,1984144170)})],1):_vm._e(),(_vm.role === 'SUPER_ADMIN' || _vm.role === 'ADMIN')?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{ path: '/user-management' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/user-management.png")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("User/Rep"),_c('br'),_vm._v("Management")])])])]}}],null,false,107094946)})],1):_vm._e(),(_vm.role === 'SUPER_ADMIN' || _vm.role === 'ADMIN')?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{ path: '/restaurants' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/restaurant.svg")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Restaurants")])])])]}}],null,false,1836531530)})],1):_vm._e(),(_vm.role === 'SUPER_ADMIN')?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",attrs:{"to":{ path: '/setting' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/icons/setting.png")}}),_c('div',{staticClass:"menu-text pt-2"},[_vm._v("Settings")])])])]}}],null,false,111099537)})],1):_vm._e(),(_vm.isMedicalRep)?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",class:[_vm.isCurrentTenantBlocked && 'disabled'],attrs:{"to":{ path: '/subscription' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"pr-2 img-fluid",attrs:{"src":require("@/assets/icons/subscription-icon.png")}}),_c('div',{staticClass:"pl-1 menu-text pt-2"},[_vm._v("Subscription")])])])]}}],null,false,3715104665)})],1):_vm._e(),(_vm.isMedicalRep)?_c('div',{staticClass:"my-3 py-1 mx-2"},[_c('router-link',{staticClass:"router-link",class:[_vm.isCurrentTenantBlocked && 'disabled'],attrs:{"to":{ path: '/teams' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{class:[isActive && 'router-link-active py-2']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"pr-2 img-fluid",attrs:{"src":require("@/assets/icons/teams.svg")}}),_c('div',{staticClass:"pl-1 menu-text pt-2"},[_vm._v("Teams")])])])]}}],null,false,3538363652)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }