












































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import moment from "moment";
import constants from "@/constants/_message";
import alert from "@/mixins/alert";
import html2pdf from "html2pdf.js";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import SignaturePad from "../signaturePad/SignaturePad.vue";
import { getSecurityToken } from "@/utils/common";

@Component({
  components: {
    AppModal,
    SignaturePad,
  },
})
export default class HIPAAAgreementModal extends Vue {
  @Getter isShowHIPAAAgreementModal!: boolean;
  @Getter currentUser: any;
  @Action hideHipaaAgreementModal: any;
  @Action submitHIPAAForm: any;
  @Getter tenant: any;
  @Action public startLoading: any;
  @Action public endLoading: any;

  showSignInput = false;
  disablesignInput = false;
  signedDate = "_______________________________";
  printName = "__________________________";
  signature = "";
  $refs!: {
    document: HTMLElement;
    logo: HTMLElement;
    signature: HTMLElement;
    signatureModalRef: HTMLFormElement;
  };
  canvas = null;
  isCanvas = false;
  selectedStyle = 0;
  styleObj: any = {
    fontFamily: "",
  };

  submitSignature(value: any) {
    if (value !== null) {
      this.canvas = value;
      this.isCanvas = true;
      this.showSignInput = true;
      this.addHipaaDetails();
      this.hide();
    } else {
      alert.methods.appToaster({
        message: constants.SIGNATURE_REQURIED,
        type: "ERROR",
      });
    }
  }

  toggleSignInput() {
    setTimeout(() => {
      this.$refs.signature.focus();
    }, 500);
  }

  addHipaaDetails() {
    this.disablesignInput = true;
    this.signedDate = moment().format("MM/DD/YYYY");
    this.printName =
      this.currentUser.first_name.charAt(0).toUpperCase() +
      this.currentUser.first_name.slice(1) +
      " " +
      this.currentUser.last_name.charAt(0).toUpperCase() +
      this.currentUser.last_name.slice(1);
  }

  signSubmitted() {
    if (this.signature.length > 0) {
      this.isCanvas = false;
      this.showSignInput = true;
      this.addHipaaDetails();
      this.hide();
    } else {
      alert.methods.appToaster({
        message: constants.SIGNATURE_REQURIED,
        type: "ERROR",
      });
    }
  }

  openSignatureModal() {
    this.$bvModal.show("signature-modal");
    this.signature = "";
    this.canvas = null;
  }

  onsignatureChange(event: any): any {
    let regularExpression = /[^a-zA-Z]/;
    if (event.key !== " " && regularExpression.test(event.key)) {
      event.preventDefault();
    }
  }

  hide() {
    this.$bvModal.hide("signature-modal");
  }

  closeModal() {
    this.hideHipaaAgreementModal();
  }

  cancelSignatureModal() {
    this.showSignInput = false;
    this.hide();
  }

  submitSignedPDF() {
    this.exportToPDF();
  }

  async exportToPDF() {
    this.startLoading();
    const { VUE_APP_API_PROTOCOL, VUE_APP_API_HOST } = process.env;
    const ImagetoDataURL = () =>
      fetch(
        `${VUE_APP_API_PROTOCOL}${VUE_APP_API_HOST}/tenant/${this.currentUser.tenantId}/logo`,
        {
          headers: {
            "x-api-key": getSecurityToken(),
          },
        }
      ).then(
        (response) =>
          new Promise((resolve) => {
            resolve(response.text());
          })
      );
    ImagetoDataURL()
      .then((dataUrl: any) => {
        const logoArray = this.currentUser.tenant.tenant_mail_logo.split(".");
        this.$refs.logo.setAttribute(
          "src",
          `data:image/${logoArray[logoArray.length - 1]};base64, ${dataUrl}`
        );
        const options = {
          margin: 0.1,
          filename: "document.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { dpi: 192, letterRendering: true },
          jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
        };
        return html2pdf()
          .set(options)
          .from(this.$refs.document)
          .outputPdf("blob", "my-invoice.pdf");
      })
      .then((pdf: any) => {
        const data = {
          id: this.currentUser.id,
          print_name: this.printName,
          signed_date: moment.utc().format(),
          hipaa_form: new File([pdf], "HIPAAform.pdf"),
        };
        this.endLoading();
        this.submitHIPAAForm(data);
        this.closeModal();
      });
  }

  selectCheckBox(value: any) {
    this.selectedStyle = value;
    switch (value) {
      case 1:
        this.styleObj.fontFamily = "Times New Roman";
        break;
      case 2:
        this.styleObj.fontFamily = "Helvetica";
        break;
      case 3:
        this.styleObj.fontFamily = "Times New Roman";
        this.styleObj.fontStyle = "italic";
        break;
      case 4:
        this.styleObj.fontFamily = "Helvetica";
        this.styleObj.fontStyle = "italic";
        break;
    }
  }
}
