






































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LetterIcon from "../../letterIcon/LetterIcon.vue";
import { isNotEmpty } from "@/utils/object";
import constants from "@/constants/app.properties";
import alert from "@/mixins/alert";
import { blockedPracticeAlert, changeTenantAlert } from "@/utils/notificationContent";

@Component({
  components: {
    LetterIcon
  }
})
export default class Header extends Vue {
  @Action private signOut: any;
  @Action private getLoggedInUser: any;
  @Action private changePractice!: any;
  @Action private closePracticeBlockModal: any;
  @Getter private currentUser!: any;
  @Getter public role!: any;
  @Getter public dropdownPracticeList!: any;
  @Getter isCurrentTenantBlocked!: boolean;
  @Action showHipaaAgreementModal: any;

  defaultTenant = Number(localStorage.getItem(constants.TENANT_ID));
  isRegisteredUser = true;

  beforeMount() {
    const userId = localStorage.getItem(constants.USER_ID);
    if (!isNotEmpty(this.currentUser) && userId) {
      this.getLoggedInUser();
    }
  }

  isAllTenantBlocked() {
    const blockedTenants = this.dropdownPracticeList.filter((practice: any) => practice.is_blocked);
    if (blockedTenants.length === this.dropdownPracticeList.length) {
      return true;
    }
    return false;
  }

  onLogout(): void {
    this.signOut();
  }

  editPreference(): void {
    this.$router.push({
      name: this.isRegisteredUser ? "preference.index" : "profile.index"
    });
  }

  resetPassword(): void {
    this.$router.push({ name: "resetpassword.index" });
  }

  manageSubscription(): void {
    this.$router.push({ name: "subscription.index" });
  }

  onHome() {
    if (
      this.$router.currentRoute.name === "register.index" ||
      this.$router.currentRoute.name === "registerSuccess.index"
    ) {
      this.$router.push({ name: "login.index" }).catch();
    } else if (this.$router.currentRoute.name !== "meeting.list") {
      this.$router.push({ name: "meeting.list" }).catch();
    }
  }

  setCurrentTenant(tenantId: number | string) {
    if (tenantId) {
      const selectedPractice = this.dropdownPracticeList
          .filter((practice: {tenantId: number}) => practice.tenantId === tenantId)[0];
      if (selectedPractice.is_blocked) {
         const msg = blockedPracticeAlert.message.replace(
          "practiceName",
          `${selectedPractice.tenant.name}`
        );
        setTimeout(() => {
          alert.methods.conFirmAlert(
            {...blockedPracticeAlert, message: msg},
              () => {
            },
            () => {}
          );
        }, 100);
        // eslint-disable-next-line no-self-assign
        // To trigger input value watch in multi select changed the type of value.
        let tenantStr: any = this.defaultTenant.toString();
        if (typeof this.defaultTenant === 'string') {
          tenantStr = Number(this.defaultTenant);
        }
        this.defaultTenant = tenantStr as any;
      } else {
        this.defaultTenant = tenantId as number;
        this.closePracticeBlockModal();
        setTimeout(() => {
          this.showAlert(tenantId as string);
        }, 100);
      }
    }
  }

  showAlert(tenantId: string) {
    alert.methods.conFirmAlert(
      changeTenantAlert,
      () => {
        this.changePractice({data: {tenantId}, callback: () => {
          localStorage.setItem(constants.TENANT_ID, tenantId as string);
          //this.getLoggedInUser();
          this.$router.go(0);
          // if (this.$route.path === '/meeting/') {
          //   this.$router.go(0);
          // } else {
          //   this.$router.push('/meeting');
          // }
        }});
      },
      () => {}
    );
  }

  get position() {
    if(this.currentUser.is_medical_rep) {
      return "User";  
    }
    return this.$store.state.auth.userRoles[this.currentUser.userTypesId];
  }

  get isMedicalRep() {
    return localStorage.getItem(constants.IS_MEDICAL_REP);
  }

  openHipaaAgreement() {
    this.showHipaaAgreementModal();
  }
}
