/**
 * Copy the element content text to cipboard
 * @param id id of element
 */
const copyToClipboard = (id: any, value?: any) => {
    const targetElement = document.getElementById(id) as HTMLElement;
    const el = document.createElement('textarea');
    el.value = value ? value : targetElement.innerHTML;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export { copyToClipboard };
