import types from "./mutation-types";
import HolidayProxy from "@/proxies/HolidayProxy";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const initNewHoliday = ({ commit }: any) => {
  commit(types.INIT_NEW_HOLIDAY);
};

const createHoliday = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new HolidayProxy().create(data).then((response: any) => {
    commit(types.CREATE_NEW_HOLIDAY_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.HOLIDAY_CREATE_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.CREATE_NEW_HOLIDAY_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getHolidayById = ({ commit }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  new HolidayProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_HOLIDAY_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_HOLIDAY_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getHolidays = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new HolidayProxy().getHolidays(data).then((response: any) => {
    commit(types.GET_HOLIDAY_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.CREATE_NEW_HOLIDAY_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const updateHoliday = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new HolidayProxy().updateHoliday(data).then(() => {
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.HOLIDAY_UPDATE_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.GET_HOLIDAY_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const getHolidayCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new HolidayProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_HOLIDAY_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_HOLIDAY_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const deleteHolidayById = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.DELETE_HOLIDAY_INIT);
  new HolidayProxy().delete(data.id).then(() => {
    commit(types.DELETE_HOLIDAY_SUCCESS, data.id);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.HOLIDAY_DELETED_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.DELETE_HOLIDAY_FAIL, error);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.HOLIDAY_DELETE_FAIL, type: "ERROR" });
  });
};

const setHoliday = ({ commit }: any, holiday: any) => {
  commit(types.SET_HOLIDAY, holiday);
};


export default {
  initNewHoliday,
  createHoliday,
  getHolidays,
  getHolidayById,
  updateHoliday,
  getHolidayCount,
  setHoliday,
  deleteHolidayById
};