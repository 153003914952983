import Proxy from "./Proxy";

class MessagesProxy extends Proxy {
  /**
   * Constructor for Auth proxy
   */
  constructor() {
    super(`/messages`);
  }

  public updateMessage(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }

  public getMessages(filter: any) {
    const filterObj: any = JSON.parse(JSON.stringify(filter));
    return this.submit("get", `${this.endpoint}/${filterObj.where.messageParticipantsId}`,  {filter: filterObj});
  }

  public createMessage(data: any) {
    return this.submit("post", `${this.endpoint}`, undefined, data);
  }


  public getUnreadTotalCount() {
    return this.submit("get", `${this.endpoint}/unreadTotalCount`);
  }

}

export default MessagesProxy;
