import keyMirror from "keymirror";

export default keyMirror({
  GET_MEETING_RULE_BY_ID_SUCCESS: null,
  GET_MEETING_RULE_BY_ID_FAIL: null,
  GET_MEETING_RULE_LIST_SUCCESS: null,
  GET_MEETING_RULE_LIST_FAIL: null,
  GET_MEETING_RULE_COUNT: null,
  SET_MEETING_RULE: null,
  CREATE_NEW_MEETING_RULE_SUCCESS: null,
  CREATE_NEW_MEETING_RULE_FAIL: null,
});