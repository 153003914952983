









































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";

@Component({})
export default class PhysicianProfile extends Vue {
  @Action public createPhysician: any;
  @Action public initNewPhysicianUser: any;
  @Action public setUser: any;
  @Action private getPhysicianByUserId: any;
  @Action private updatePhysicianProfile: any;
  @Action public getLocationDropdown: any;
  @Getter public physician: any;
  @Getter public locations: any;
  @Getter public currentUser: any;
  @Getter public role!: any;
  notification = false;
  isSameAsEmail = false;
  isSubmitted = false;
  isDisabled = false;
  $refs!: {
    physicianForm: InstanceType<typeof ValidationObserver>;
  };

  beforeMount() {
    this.setUser({});
    this.initNewPhysicianUser();
    const userId = this.currentUser.id;
    if (userId) {
      this.getPhysicianByUserId(userId);
      this.isDisabled = true;
    }
  }

  destroyed() {
    this.initNewPhysicianUser();
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.physicianForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.physicianForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.physicianForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
          if (this.physician.address === null) {
            this.physician.address = "";
          }
          if (this.physician.zipcode === null) {
            this.physician.zipcode = "";
          }
          if (this.physician.contact_no === null) {
            this.physician.contact_no = "";
          }
          if (this.physician.optout_topic_ids === null) {
            this.physician.optout_topic_ids = [];
          }
          if (this.physician.optout_brand_ids === null) {
            this.physician.optout_brand_ids = [];
          }
          if (this.physician.optout_category_ids === null) {
            this.physician.optout_category_ids = [];
          }
          if (this.physician.required_email_notification === null) {
            this.physician.required_email_notification = false;
          }
          const {
            id,
            prefix,
            first_name,
            last_name,
            email,
            contact_no,
            npi_no,
            department_name,
            location,
            address,
            zipcode,
            optout_topic_ids,
            optout_brand_ids,
            optout_category_ids,
            required_email_notification
          } = this.physician;
          this.updatePhysicianProfile({
            data: {
              id,
              prefix,
              first_name,
              last_name,
              email,
              contact_no,
              npi_no,
              department_name,
              location,
              address,
              zipcode,
              optout_topic_ids,
              optout_brand_ids,
              optout_category_ids,
              required_email_notification
            },
            callBack: () => {
              this.$router.push({ name: "meeting.list" }).catch();
            },
          });
        }
    });
  }

  changeStatus(value:any) {
    this.physician.required_email_notification = value;
    this.notification = value;
  }

  onCancel(): void {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.$router.push({ name: "meeting.list" }).catch();
      },
      () => {}
    );
  }
}
