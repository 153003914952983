







import Vue from "vue";
import { Component } from "vue-property-decorator";
import AppLayout from "@/components/layout/AppLayout.vue";
import NotificationAlert from '@/components/confirmationPopup/Index.vue';
import role from '@/mixins/role';

@Component({
  components: {
    AppLayout,
    NotificationAlert
  },
  mixins: [role]
})
export default class App extends Vue {}
