export class State {
  public team: any = {};
  public error = '';
  public teamInitData = {
    name: "",
    user_id: [],
    status: true
  };
  public teams = [];
  public teamCount = 0;
}