import Proxy from "./Proxy";

class MeetingRuleProxy extends Proxy {
  /**
   * Constructor for Appointment Type proxy
   */
  constructor() {
    super(`/rules`);
  }


  public updateMeetingRule(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }

  public createMeetingRule(data: any) {
    return this.submit("post", `${this.endpoint}`, undefined, data);
  }

  public getMeetingRules(filter: any) {
    return this.submit("get", this.endpoint,  {filter: JSON.stringify(filter)});
  }

  public getListCount(data: any) {
    return this.submit("get", `${this.endpoint}/count`,  {where: JSON.stringify(data)});
  }

}

export default MeetingRuleProxy;
