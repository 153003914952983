import Proxy from "./Proxy";

class PracticeProxy extends Proxy {
  /**
   * Constructor for practice Type proxy
   */
  constructor() {
    super(`/user-tenant`);
  }


  public remove(id: string) {
    return this.submit("delete", `${this.endpoint}/${id}`);
  }

  public create(data: any) {
    return this.submit("patch", `${this.endpoint}/${data.userId}`, undefined, data);
  }

  public getPractices(filter: any) {
    return this.submit("get", this.endpoint, {filter: JSON.stringify(filter)});
  }

  public getListCount(data: any) {
    return this.submit("get", `${this.endpoint}/count` , {where: JSON.stringify(data)});
  }

  public changePractice(tenantId: number) {
    return this.submit('get', `${this.endpoint}/${tenantId}/token`);
  }

}

export default PracticeProxy;
