import { IUser } from "@/model/index";
import constants from "@/constants/app.properties";

const defaultPermissions = {
  create: false,
  update: false,
  delete: false,
  read: false
};

/**
 * ==============
 * Initial state of Auth module
 */
export class State {
  public isAuthenticated = Boolean(localStorage.getItem(constants.AUTH_TOKEN));
  public authToken = "";
  public loginErrMsg = "";
  public error: any;
  public passwordReset: any = {
    newPassword: "",
    oldPassword: ""
  };
  public currentUser: any = {};
  public registerUserInitialData: any = {
    first_name: "",
    last_name: "",
    email: "",
    pharma_company: "",
    phone: "",
    password: "",
    products: "",
    confirmPassword: "",
    status: 0,
    speciality: "",
    is_password_changed: true,
    is_medical_rep: true,
    tenantId: "",
    address: "",
    zipcode: "",
    territory: "",
    favorite: false,
    userPreferences: {
      is_email_verified: false,
      is_phone_verified: false
    }
  };

  public user: any = this.registerUserInitialData;
  public pendingUsers = [];
  public preference: IUser = {
    first_name: "",
    last_name: "",
    email: "",
  };
  public access = {};
  public currentModule = '';
  public modulePermissions = {
    meeting: defaultPermissions
  };
  public rolePermissions: any = {};
  public userRoles: any = {
    1: "SUPER_ADMIN",
    2: "ADMIN",
    3: "USER",
    4: "GUEST_USER",
    5: "PHYSICIAN",
  };
  public defaultAcces: any = {
    Profile: {
      create: false,
      update: true,
      delete: false,
      read: true
    }
  }

  // subscription
  public billingHistories = [];
  public subscriptionPlans = [];
  public users = [];
  public userCount = 0;
  public allUsers = [];
  public healthcareDomains = [];
  public selectedPlan = {};
  public showUnSubscribeModal = false;
  public isCurrentTenantBlocked = false;
  public isSubscribed = false;
  public order = {};
  public practiceLocationsByUser = [];
  public organizationTypes = [];
  public settingValue = '';
  public powerbiRes = '';
  public powerbiReports: any = [];
  public isShowHIPAAAgreementModal = false;
  public showVerificationModal = false;
}
