/* ============
 * Actions for the Auth module
 * ============
 *
 * The actions that are available on the
 * Auth module.
 */
import types from "./mutation-types";
import AuthProxy from "@/proxies/AuthProxy";
import router from "@/plugins/vue-router";
import constants from "@/constants/app.properties";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";
import loadingTypes from "@/store/mutation-types";
import meetingTypes from "@/store/modules/meeting/mutation-types";
import { jsonToMultiPartConversion } from "@/utils/common";
import moment from "moment";
import momenttz from 'moment-timezone'

import axios from "axios";

//let refreshTask = 0
const multiPartHeader = {
  "Content-Type": "multipart/form-data"
};

const login = ({ commit, dispatch }: any, data: any): void => {
  commit(types.LOGIN_INIT);
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .login(data)
    .then((response: any) => {
      commit(meetingTypes.SET_ACTIVE_TAB, true);
      commit(types.LOGIN_SUCCESS, response);
      commit(types.VERIFY_TOKEN_SUCCESS, response);
      if (response.user.is_medical_rep) {
        dispatch('getDropdownPractice');
      }
      if (localStorage.getItem("isEmailUser")) {
        router.push({
          path: `/meeting/${localStorage.getItem("emmailUserMeetingId")}/view`
        });
        localStorage.removeItem("isEmailUser");
        localStorage.removeItem("emmailUserMeetingId");
      } else if (localStorage.getItem("isPendingApprovalUser")) {
        const email = localStorage.getItem("pendingApprovalUserMail")
        router.push({
          path: email ? `/registerApproval?e=${email}` : `/registerApproval`
        });
        localStorage.removeItem("isPendingApprovalUser");
        localStorage.removeItem("pendingApprovalUserMail");
      }
      else {
        dispatch('hideHipaaAgreementModal');
        dispatch('hideVerificationModal');
        let isSubscriptionModalOpen = false;
        if (response.user.tenant.time_zone) {
          momenttz.tz.setDefault(response.user.tenant.time_zone);
        }
        const isDefaultTenantBlocked = response.user.is_medical_rep && response.user.usertenants.length > 0 && !response.user.usertenants.find((userTenant: any) => { return userTenant.tenantId === response.user.tenantId }).is_blocked;
        if (response.user.is_medical_rep && isDefaultTenantBlocked && response.user.tenant.enable_subscription) {

          let message = '';
          let isSubscriptionAlertNeeded = false;
          if (response.user.subscription_end_date && response.user.subscription_end_date !== '') {
            const currentTime = moment.utc(
              moment().format("YYYY-MM-DD"),
              "YYYY-MM-DD"
            );
            const subscriptionTime = moment.utc(
              response.user.subscription_end_date,
              "YYYY-MM-DD[T]HH:mm[Z]"
            );
            if (subscriptionTime.isSameOrAfter(currentTime)) {
              const noOfDaysLeft = subscriptionTime.diff(currentTime, 'days') + 1;
              if (noOfDaysLeft <= 30) {
                isSubscriptionAlertNeeded = true;
                if (noOfDaysLeft === 1) {
                  message = 'Your subscription is expiring today. Kindly renew your subscription to avoid disruption in services.'
                } else {
                  message = `Your subscription is about to expire in ${noOfDaysLeft} days.Kindly renew your subscription to continue services.`
                }
              } else {
                isSubscriptionAlertNeeded = false;
              }
            } else {
              isSubscriptionAlertNeeded = true;
              commit(types.SHOW_LICENSE_INFO_MODAL);
              isSubscriptionModalOpen = true;
              message = 'Your subscription has expired. Kindly renew your subscription to continue services.'
            }
          } else {
            isSubscriptionAlertNeeded = false;
            commit(types.SHOW_LICENSE_INFO_MODAL);
            isSubscriptionModalOpen = true;
          }
          isSubscriptionAlertNeeded ? alert.methods.appToaster({ message: message, type: "WARNING", noAutoClose: true }) : '';
        }
        if (isDefaultTenantBlocked && !isSubscriptionModalOpen && response.user.tenant.tenantpreferences.required_fields.enable_hipaa_form && (!response.user.userhipaaform.hipaa_form_url || (response.user.userhipaaform.hipaa_form_url && response.user.userhipaaform.hipaa_form_url.length == 0))) {
          dispatch('showHipaaAgreementModal');
        }
      }
      dispatch('getRolePermissions', {
        id: response.user.userTypesId, filter: {
          "where": { "roleAccess": true }, "fields": { "id": true, "resourcesId": true, "operationsId": true }, "include": [{ "relation": "resources", "scope": { "fields": ["id", "name", "slug", "display_order"] } }, { "relation": "operations", "scope": { "fields": ["id", "name", "is_default_action", "slug", "display_order"] } }]
        },
        callBack: () => {
          router.push({
            name: "meeting.list"
          }).catch();
        }
      });
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.LOGIN_FAIL, error.error);
      let message: string = msgConstants.LOGIN_ERROR;
      if (error.error.message === msgConstants.USER_NOT_ACTIVATED) {
        message = msgConstants.USER_NOT_ACTIVATED;
      } else if (error.error.message === msgConstants.USER_BLOCKED) {
        message = msgConstants.USER_BLOCKED;
      } else if (error.error.message === msgConstants.USER_REJECTED) {
        message = msgConstants.USER_REJECTED;
      } else {
        message = msgConstants.LOGIN_ERROR;
      }
      alert.methods.appToaster({ message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const signOut = ({ commit, dispatch }: any, data: any):void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy().signOut(data).then(() => {
    commit(loadingTypes.END_LOADING);
    dispatch('logout');
    localStorage.clear();
  }).catch(()=>{
     commit(loadingTypes.END_LOADING);
     localStorage.clear();
     dispatch('logout');
  });  
};

const logout = ({ commit }: any) => {
  commit(types.LOGOUT_SUCCESS);
  momenttz.tz.setDefault();
  router.push({
    name: constants.LOGIN_PAGE
  });
  commit(types.CLOSE_DEFAULT_TENANT_BLOCKED_MODAL);
};

const closeInfoModal = ({ commit }: any) => {
  commit(types.CLOSE_LICENSE_INFO_MODAL);
}

const onRegisterUser = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .registerUser(data)
    .then((response: any) => {
      commit(types.REGISTER_USER_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      callback(response.user.id);
    })
    .catch((error: any) => {
      commit(types.REGISTER_USER_FAIL, error.error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const setUser = ({ commit }: any, user: any) => {
  commit(types.SET_USER, user);
};

const updatePreference = ({ commit, dispatch }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .updatePreference(data)
    .then(() => {
      commit(types.PREFERENCE_UPDATE_SUCCESS, data);
      commit(loadingTypes.END_LOADING);
      router.push({
        name: "meeting.list"
      }).catch();
      if (!data.userPreferences) {
        alert.methods.appToaster({ message: msgConstants.PROFILE_UPDATED_SUCCESS, type: "SUCCESS" });
      } else {
        dispatch('getLoggedInUser');
      }
    })
    .catch((error: any) => {
      commit(types.PREFERENCE_UPDATE_FAIL, error);
      alert.methods.appToaster({ message: msgConstants.PROFILE_UPDATED_FAIL, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const getPendingUsers = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getPendingUsers(data)
    .then((response: any) => {
      commit(types.GET_PENDING_USERS_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      if (error.error.message === "Access denied") {
        alert.methods.appToaster({ message: msgConstants.ACCESS_DENIED, type: "ERROR" });
        router.push({
          name: "meeting.list"
        }).catch();
      }
      commit(types.GET_PENDING_USERS_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    });
};

const bulkUpdate = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .bulkUpdate(data)
    .then(() => {
      commit(types.BULK_UPDATE_SUCCESS, data.ids);
      callback();
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.BULK_UPDATE_SUCCESS, type: "SUCCESS" });
    })
    .catch((error: any) => {
      commit(types.BULK_UPDATE_FAIL, error);
      alert.methods.appToaster({ message: msgConstants.BULK_UPDATE_FAIL, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const passwordReset = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .resetPassword(data)
    .then((response: any) => {
      commit(types.PASSWORD_RESET_SUCCESS, response);
      if (callback) {
        callback();
      }
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.PASSWORD_RESET_FAIL, error);
      if (error.error.message == "Old password not match with current password") {
        alert.methods.appToaster({ message: msgConstants.NOT_VALID_CURRENT_PASSWORD, type: "ERROR" });
      } else {
        alert.methods.appToaster({ message: msgConstants.PASSWORD_RESET_FAIL, type: "ERROR" });
      }
      commit(loadingTypes.END_LOADING);
    });
};

const onForgotPasswordMail = ({ commit }: any, {data, callback}: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .onForgotPasswordMail(data)
    .then(() => {
      if (data.security_code) {
        alert.methods.appToaster({ message: msgConstants.PASSWORD_CHANGED_SUCCESS, type: "SUCCESS" });
      } else {
        const msg = data.phone ? 'RESET_LINK_SENT_TO_MOBILE' : 'RESET_LINK_SENT_TO_EMAIL';
        alert.methods.appToaster({ message: msgConstants[msg], type: "SUCCESS" });
      }
      callback();
      if(!data.phone){
        router.push({
          name: "login.index"
        }).catch();
      }      
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const getLoggedInUser = ({ commit, dispatch }: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getLoggedInUser()
    .then((response: any) => {
      commit(types.SET_CURRENT_USER, response);
      if (response.tenant.time_zone) {
        momenttz.tz.setDefault(response.tenant.time_zone);
      }
      if (response.is_medical_rep) {
        dispatch('getDropdownPractice');
      }
      const isDefaultTenantBlocked = response.is_medical_rep && response.usertenants.length > 0 && !response.usertenants.find((userTenant: any) => { return userTenant.tenantId === response.tenantId }).is_blocked;
      if (isDefaultTenantBlocked) {
        if (!response.subscription_end_date) {
          commit(types.SHOW_LICENSE_INFO_MODAL);
        } else if (response.subscription_end_date !== null && response.subscription_end_date !== '') {
          const currentTime = moment.utc(
            moment().format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          );
          const subscriptionTime = moment.utc(
            response.subscription_end_date,
            "YYYY-MM-DD[T]HH:mm[Z]"
          );
          if (subscriptionTime.isBefore(currentTime)) {
            commit(types.SHOW_LICENSE_INFO_MODAL);
          }
        } else {
          commit(types.SET_SUBSCRIPTION_TRUE);
        }
      }
      dispatch('getRolePermissions', {
        id: response.userTypesId, filter: {
          "where": { "roleAccess": true }, "fields": { "id": true, "resourcesId": true, "operationsId": true }, "include": [{ "relation": "resources", "scope": { "fields": ["id", "name", "slug", "display_order"] } }, { "relation": "operations", "scope": { "fields": ["id", "name", "is_default_action", "slug", "display_order"] } }]
        },
        callBack: () => { }
      });
      commit(loadingTypes.END_LOADING);
    }).catch(() => {
      commit(loadingTypes.END_LOADING);
    });
};

const getPreference = ({ commit }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_PREFERENCE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_PREFERENCE_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getUserById = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getUserById(data.id, data.filter)
    .then((response: any) => {
      commit(types.GET_PREFERENCE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      data.callBack();
    })
    .catch((error: any) => {
      commit(types.GET_PREFERENCE_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const setToken = ({ commit }: any, response: any) => {
  commit(types.VERIFY_TOKEN_SUCCESS, response);
};

const setAuthenticated = ({ commit }: any, authenticated: any) => {
  commit(types.SET_AUTHENTICATED, authenticated);
};

const setCurrentModulePermissions = ({ commit }: any, data: any) => {
  commit(types.SET_CURRENT_MODULE_PERMISSIONS, data);
};

const getAccessTokenByRefreshToken = ({ commit }: any) => {
  const refreshToken = localStorage.getItem(constants.REFRESH_TOKEN);
  new AuthProxy().getAccessTokenByRefreshToken({ refreshToken }).then((response: any) => {
    commit(types.VERIFY_TOKEN_SUCCESS, response.data);
  }).catch((error: any) => {
    console.log("Error while auto refresh", error);
  });
};

const initRegisterUser = ({ commit }: any) => {
  commit(types.INIT_REGISTER_USER);
};

// Subscription plan
const getBillingHistoryById = ({ commit }: any, id: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getBillingHistoryById(id)
    .then((response: any) => {
      commit(types.GET_BILLING_HISTORY_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_BILLING_HISTORY_BY_ID_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    });
};

const getSubscriptionPlans = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getSubscriptionPlans()
    .then((response: any) => {
      commit(types.GET_SUBSCRIPTIONS_PLANS_SUCCESS, response);
      if (data.callback) {
        data.callback();
      }
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_SUBSCRIPTIONS_PLANS_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    });
};

const addSubscriptionPlan = ({ commit }: any, {data,callback}: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .addSubscriptionPlan(data)
    .then((response: any) => {
      commit(types.ADD_SUBSCRPTION_PLAN_SUCCESS, response);
      commit(types.SET_SUBSCRIPTION_TRUE);
      alert.methods.appToaster({ message: msgConstants.SUBSCRIPTION_ADDED_SUCCESS, type: "SUCCESS" });
      callback();
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      alert.methods.appToaster({ message: msgConstants.SUBSCRIPTION_ADDED_FAIL, type: "ERROR" });
      commit(types.ADD_SUBSCRPTION_PLAN_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    });
};

const getSubscriptionPlanById = ({ commit }: any, data: any) => {
  new AuthProxy()
    .getSubscriptionPlanById(data)
    .then((response: any) => {
      commit(types.GET_SUBSCRIPTION_PLAN_SUCCESS, response);
    })
    .catch((error: any) => {
      commit(types.GET_SUBSCRIPTION_PLAN_FAIL, error.error);
    });
}

const createPayment = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .paymentForPlan(data.data)
    .then((response: any) => {
      data.callback(response);
    })
    .catch((error: any) => {
      commit(loadingTypes.END_LOADING);
      router.push({
        name: "subscription.index"
      }).catch();
      let errorMsg = 'Payment failed. Kindly contact administrator.'
      if (error.error && error.error.message) {
        errorMsg = `Payment Failed: ${error.error.message}. \nPlease try again after sometime.`
      }
      alert.methods.appToaster({
        message: `${errorMsg}`,
        type: "ERROR",
      });
    });
}

const getUsers = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getUsers(data)
    .then((response: any) => {
      commit(types.GET_USERS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_USERS, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getHealthCareDomains = ({ commit }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getHealthCareDomains()
    .then((response: any) => {
      commit(types.GET_HEALTH_CARE_DOMAINS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_USERS, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getUserCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_USERS_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_USERS, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const deleteUserById = ({ commit }: any, id: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.DELETE_USER_INIT);
  new AuthProxy().delete(id).then(() => {
    commit(types.DELETE_USER_SUCCESS, id);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.USER_DELETED_SUCCESS, type: "SUCCESS" });
  }).catch((error: any) => {
    commit(types.DELETE_USER_FAIL, error);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.USER_DELETED_FAIL, type: "ERROR" });
  });
};

const updateUser = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  data.phone == null ? delete data.phone : '';
  new AuthProxy()
    .updatePreference(data)
    .then((res: any) => {
      commit(loadingTypes.END_LOADING);
      if (data.status) {
        alert.methods.appToaster({ message: `${res.first_name} ${res.last_name} is successfully ${data.status == 1 ? 'unblocked' : 'blocked'}`, type: "SUCCESS" });
      } else {
        alert.methods.appToaster({ message: msgConstants.USER_UPDATED_SUCCESS, type: "SUCCESS" });
      }
      callback();
    })
    .catch(() => {
      alert.methods.appToaster({ message: msgConstants.USER_UPDATED_FAIL, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const deleteAdminandSubscribers = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .updatePreference(data)
    .then((res: any) => {
      commit(loadingTypes.END_LOADING);
      if (res.userTypesId === 6 && data.status === 2) {
        alert.methods.appToaster({ message: msgConstants.SUBSCRIBER_DELETE_SUCCESS, type: "SUCCESS" });
      } else if (res.userTypesId === 2 && data.status === 2) {
        alert.methods.appToaster({ message: msgConstants.USER_DELETED_SUCCESS, type: "SUCCESS" });
      }
      callback();
    })
    .catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const createUser = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .registerUser(data)
    .then((response: any) => {
      commit(types.REGISTER_USER_SUCCESS, response);
      alert.methods.appToaster({ message: msgConstants.USER_CREATED_SUCCESS, type: "SUCCESS" });
      callback();
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.REGISTER_USER_FAIL, error.error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const performRepActivities = ({ commit }: any, data: any): void => {
  const params = { ...data.requestData };
  delete params.msg;
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .transferMeeting(params)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      data.cb();
      alert.methods.appToaster({ message: data.requestData.msg, type: "SUCCESS" });
    })
    .catch((error: any) => {
      commit(types.REP_ACTIVITES_FAIL, error.error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const getAllUsers = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getUsers(data)
    .then((response: any) => {
      commit(types.GET_USERS_FOR_TRANSFER_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_USERS_FOR_TRANSFER, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const createAgreementAudit = ({ commit }: any, data: any): void => {
  new AuthProxy()
    .addAgreementAudit(data)
    .then((response: any) => {
      commit(types.CREATE_SUBSCRIPTION_AGREEMENT_AUDIT_SUCCESS, response);
    }).catch((error: any) => {
      commit(types.CREATE_SUBSCRIPTION_AGREEMENT_AUDIT_FAIL, error.error);
    })
};

const getDropdownPractice = ({ dispatch }: any) => {
  dispatch('fetchPracticesForDropdown', {
    where: {
      userId: localStorage.getItem(constants.USER_ID),
      is_enabled: true
    },
    include: [
      {
        relation: "tenant",
        scope: {
          fields: {
            id: true,
            name: true,
            display_name: true,
          }
        }
      }
    ]
  });
}

const addOrder = ({ commit }: any, { data, callBack }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .addOrder(data)
    .then((response: any) => {
      commit(types.CREATE_ORDER_SUCCESS, response);
      callBack();
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.CREATE_ORDER_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    })
}

const addOrUpdatePracticeLocation = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .addOrUpdatePracticeLocation(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.PRACTICE_LOCATION_ADDED_SUCCESS, type: "SUCCESS" });
    }).catch((error: any) => {
      commit(types.CREATE_ORDER_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    });
}

const getPracticeLocationByUser = ({ commit }: any, { data, callBack }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getPracticeLocationByUser(data)
    .then((response: any) => {
      commit(types.GET_PRACTICE_LOCATIONS_BY_USER, response);
      callBack();
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.CREATE_ORDER_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    });
}

const getOrganizationTypes = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getOrganizationTypes(data)
    .then((response: any) => {
      commit(types.GET_ORGANIZATION_TYPES_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_ORGANIZATION_TYPES_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    });
}

const updateFavoriteuser = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .updateFavoriteuser(data)
    .then(() => {
      callback();
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.USER_UPDATED_SUCCESS, type: "SUCCESS" });
    })
    .catch((error: any) => {
      commit(types.PREFERENCE_UPDATE_FAIL, error);
      alert.methods.appToaster({ message: msgConstants.USER_UPDATED_FAIL, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const updateVaccinationStatus = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  const id = data.id;
  delete data.id;
  const isFromRegister = data.isFromRegister ? data.isFromRegister : false;
  if (data.vaccination_certificate_url == '') {
    delete data.vaccination_certificate_url;
  }
  new AuthProxy()
    .updateVaccinationStatus(id, jsonToMultiPartConversion(data), multiPartHeader)
    .then((response: any) => {
      commit(types.SET_USER_VACCINATION_STATUS, response);
      commit(loadingTypes.END_LOADING);
      if (!isFromRegister) {
        alert.methods.appToaster({ message: msgConstants.USER_VACCINATION_STATUS, type: "SUCCESS" });
      }
      callback();
    })
    .catch((error: any) => {
      commit(types.PREFERENCE_UPDATE_FAIL, error);
      alert.methods.appToaster({ message: msgConstants.USER_UPDATED_FAIL, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const downloadVaccinationCertification = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  const {
    VUE_APP_API_PROTOCOL,
    VUE_APP_API_HOST,
  } = process.env;
  axios({
    url: `${VUE_APP_API_PROTOCOL}${VUE_APP_API_HOST}/users/vaccination/${data}`,
    method: 'GET',
    responseType: 'blob',
  }).then((res: any) => {
    const blob = new Blob([res.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = data;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.PREFERENCE_UPDATE_FAIL, error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const deleteVaccinationCertificate = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .deleteVaccinationCertificate(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      callback();
    }).catch((error: any) => {
      commit(types.GET_ORGANIZATION_TYPES_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    });
}

const switchUser = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .switchUser(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.SWITCH_USER_SUCCESS, type: "SUCCESS" });
      callback();
    })
    .catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};


const resetUserPassword = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .resetUserPassword(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.PASSWORD_RESET_SUCCESSFULL, type: "SUCCESS" });
      callback();
    })
    .catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const getSettingValue = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getSettingValue(data)
    .then((response: any) => {
      commit(types.GET_SETTING_VALUE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_SETTING_VALUE_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    });
}

const getUserListForDropdown = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getUserListForDropdown(data)
    .then((response: any) => {
      commit(types.GET_USERS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_USERS, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getPowerBIReport = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getPowerBIReport(data)
    .then((response: any) => {
      commit(types.GET_POWERBI_SUCCESS, response);
      callback(response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_POWERBI_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getReportList = ({ commit }: any, { callBack }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .getReportList()
    .then((response: any) => {
      commit(types.GET_POWERBI_REPORT_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      callBack();
    }).catch((error: any) => {
      commit(types.GET_POWERBI_REPORT_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const showHipaaAgreementModal = ({ commit }: any) => {
  commit(types.HIDE_HIPAA_AGREEMENT_MODAL);
  setTimeout(() => {
    commit(types.SHOW_HIPAA_AGREEMENT_MODAL);
  }, 200);
};

const hideHipaaAgreementModal = ({ commit }: any) => {
  commit(types.HIDE_HIPAA_AGREEMENT_MODAL);
};

const submitHIPAAForm = ({ commit, dispatch }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  const id = data.id;
  delete data.id;
  new AuthProxy()
    .submitHIPAAForm(id, jsonToMultiPartConversion(data), multiPartHeader)
    .then(() => {
      dispatch('getLoggedInUser')
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    })
};


const downloadSignedHipaaForm = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  const {
    VUE_APP_API_PROTOCOL,
    VUE_APP_API_HOST,
  } = process.env;
  axios({
    url: `${VUE_APP_API_PROTOCOL}${VUE_APP_API_HOST}/users/hipaa/${data}`,
    method: 'GET',
    responseType: 'blob',
  }).then((res: any) => {
    const blob = new Blob([res.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = data;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.PREFERENCE_UPDATE_FAIL, error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const setUsers = ({ commit }: any, data: any) => {
  commit(types.GET_USERS, data);
}

const sendVerificationCode = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .sendVerificationCode(data)
    .then(() => {
      alert.methods.appToaster({ message: 'Verification code sent successfully.', type: "SUCCESS" });
      callback();
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    })
};

const verifyVerificationCode = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .verifyVerificationCode(data)
    .then((res: any) => {
      if (res.data && res.data !== null) {
        commit(types.SET_USER, res.data);
      }if(!res.token){
        alert.methods.appToaster({ message: res.message, type: "SUCCESS" });
      }      
      callback(res.token);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    })
};

const sendProfileVerificationCode = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .sendProfileVerificationCode(data)
    .then(() => {
      alert.methods.appToaster({ message: 'Verification code sent successfully.', type: "SUCCESS" });
      callback();
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    })
};

const verifyProfileVerificationCode = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .verifyProfileVerificationCode(data)
    .then((res: any) => {
      if (res.data && res.data !== null) {
        commit(types.SET_USER, res.data);
      }
      alert.methods.appToaster({ message: res.message, type: "SUCCESS" });
      callback();
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    })
};

const updateUnapprovedUserDetails = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new AuthProxy()
    .updateUnapprovedUserDetails(data)
    .then(() => {
      callback();
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    })
};

const showVerificationModal = ({ commit }: any) => {
  commit(types.SHOW_VERIFICATION_MODAL);
};

const hideVerificationModal = ({ commit }: any) => {
  commit(types.HIDE_VERIFICATION_MODAL);
};

export default {
  login,
  logout,
  signOut,
  onRegisterUser,
  initRegisterUser,
  updatePreference,
  passwordReset,
  onForgotPasswordMail,
  getLoggedInUser,
  getPreference,
  setAuthenticated,
  setCurrentModulePermissions,
  getAccessTokenByRefreshToken,
  setToken,
  setUser,
  getPendingUsers,
  bulkUpdate,
  getBillingHistoryById,
  getSubscriptionPlans,
  addSubscriptionPlan,
  getUsers,
  deleteUserById,
  updateUser,
  createUser,
  getUserCount,
  getUserById,
  performRepActivities,
  getAllUsers,
  getHealthCareDomains,
  getSubscriptionPlanById,
  createPayment,
  createAgreementAudit,
  closeInfoModal,
  getDropdownPractice,
  addOrder,
  addOrUpdatePracticeLocation,
  getPracticeLocationByUser,
  updateFavoriteuser,
  getOrganizationTypes,
  updateVaccinationStatus,
  downloadVaccinationCertification,
  deleteVaccinationCertificate,
  deleteAdminandSubscribers,
  switchUser,
  resetUserPassword,
  getSettingValue,
  getUserListForDropdown,
  getPowerBIReport,
  getReportList,
  showHipaaAgreementModal,
  hideHipaaAgreementModal,
  submitHIPAAForm,
  downloadSignedHipaaForm,
  setUsers,
  sendVerificationCode,
  verifyVerificationCode,
  updateUnapprovedUserDetails,
  showVerificationModal,
  hideVerificationModal,
  sendProfileVerificationCode,
  verifyProfileVerificationCode
};
