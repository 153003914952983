





































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { deletePhysicianAlert } from "@/utils/notificationContent";
import LetterIcon from "../../components/letterIcon/LetterIcon.vue";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    LetterIcon,
  },
})
export default class PhysicianList extends Vue {
  @Action public getPhysicianList: any;
  @Action public deleteUserById: any;
  @Action public getPhysicianCount: any;
  @Getter public physicianUsers: any;
  @Getter public physicianCount: any;
  @Getter public role!: any;
  @Getter public currentUser: any;
  @Action public deletePhysicianById: any;
  @Getter public rolePerm: any;
  perm = _permissions;
  searchText = "";
  physiciansToDisplay = [];
  isMedicalRep = false;
  userFilter: any = {
    limit: 20,
    skip: 0,
    fields: {
      created_at: false,
      updated_at: false,
      created_by: false,
      updated_by: false,
    },
    where: {
     status: { eq: true}, 
    },
    include: [
      {
        relation: "physiciansAvailabilities",
        scope: { fields: ["id", "weekday", "locationId", "physicianId"] },
      },
    ],
    order: ["first_name ASC"]
  };
  pageOptions = [10, 20];
  currentPage = 1;
  userWhere: any = {
     status: { eq: true},
  };

  beforeMount() {
    this.getUserList();
  }

  getUserList() {
    this.userFilter.where.tenantId = this.currentUser.tenantId;
    this.userWhere.tenantId = this.currentUser.tenantId;
    this.getPhysicianList(this.userFilter);
    this.getPhysicianCount(this.userWhere);
  }

  pageSizeChanged() {
    this.userFilter.skip = 0;
    this.getUserList();
  }

  onPageChange(currentPage: any) {
    this.userFilter.skip = (currentPage - 1) * this.userFilter.limit;
    this.getUserList();
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.userFilter.where.or = [
        { first_name: { ilike: `%${this.searchText}%` } },
        { last_name: { ilike: `%${this.searchText}%` } },
        { location: { ilike: `%${this.searchText}%` } },
      ];
      this.userWhere.or = [
        { first_name: { ilike: `%${this.searchText}%` } },
        { last_name: { ilike: `%${this.searchText}%` } },
        { location: { ilike: `%${this.searchText}%` } },
      ];
      this.userFilter.skip = 0;
      this.getUserList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  addUser(): void {
    this.$router.push({ name: "physicians.create" });
  }

  editUser(id: number): void {
    this.$router.push({ path: `physicians/update/${id}` });
  }

  backToUserManagement(): void {
    this.$router.push({ name: "user-management.list" }).catch();
  }

  deletePhysician(id: any): void {
    alert.methods.conFirmAlert(
      deletePhysicianAlert,
      () => {
        this.deletePhysicianById({ data: id, callback: this.getUserList});
      },
      () => {}
    );
  }

}
