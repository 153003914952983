

































import { Vue, Component, Emit, Watch, Prop } from "vue-property-decorator";

@Component
export default class DatePicker extends Vue {
  showLabel = true;
  @Prop({ default: "" }) minDate!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: true }) showSelectToday!: boolean;
  @Prop({ default: true }) showReset!: boolean;
  @Prop({ default: false }) isWeekendDisabled!: boolean;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: "" }) maxDate!: string;
  @Prop({default: ""}) inputValue!: string;

  selectedDate = this.inputValue;
  
  @Watch("selectedDate")
  setSelectedDate() {
    this.emitDate();
  }

  @Emit("emitDate")
  emitDate() {
    return this.selectedDate;
  }

  dateDisabled(ymd: any, date: any) {
    if (this.isWeekendDisabled) {
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6;
    } else {
      return false;
    }
  }
}
