import Proxy from "./Proxy";

class PolicyProxy extends Proxy {
  /**
   * Constructor for Policy proxy
   */
  constructor() {
    super(`/policies`);
  }

  
  public updatePolicy(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }


  public getPolicies(filter: any) {
    return this.submit("get", this.endpoint, {filter: JSON.stringify(filter)});
  }

  public getListCount(data: any) {
    return this.submit("get", `${this.endpoint}/count`, {where: JSON.stringify(data)});
  }

  public addPolicyToLocations(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit('patch', `${this.endpoint}/${id}/locations`, undefined, body);
  }

}

export default PolicyProxy;
