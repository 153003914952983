



















































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LetterIcon from "../../components/letterIcon/LetterIcon.vue";
import alert from "@/mixins/alert";
import { deleteSubscriberAlert, changeUserAlert } from "@/utils/notificationContent";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    LetterIcon,
  },
})
export default class SubscriberList extends Vue {
  @Action public getUsers: any;
  @Action public deleteUserById: any;
  @Action public getUserCount: any;
  @Getter public users: any;
  @Getter public userCount: any;
  @Action public deleteAdminandSubscribers: any;
  @Action public switchUser: any;
  @Getter public rolePerm: any;
  perm = _permissions;
  searchText = "";
  usersToDisplay = [];
  isMedicalRep = false;
  userFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      status: 1,
      and: [{ userTypesId: { eq: 6 } }, { is_medical_rep: false }],
    },
    order: ["first_name ASC"],
  };
  pageOptions = [10, 20];
  currentPage = 1;
  userWhere: any = {
    status: 1,
    and: [{ userTypesId: { eq: 6 } }, { is_medical_rep: false }],
  };

  beforeMount() {
    this.getUserList();
  }

  getUserList() {
    this.getUsers(this.userFilter);
    this.getUserCount(this.userWhere);
  }

  pageSizeChanged() {
    this.userFilter.skip = 0;
    this.getUserList();
  }

  onPageChange(currentPage: any) {
    this.userFilter.skip = (currentPage - 1) * this.userFilter.limit;
    this.getUserList();
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.userFilter.where.or = [
        { first_name: { ilike: `%${this.searchText}%` } },
        { last_name: { ilike: `%${this.searchText}%` } },
        { location: { ilike: `%${this.searchText}%` } },
      ];
      this.userWhere.or = [
        { first_name: { ilike: `%${this.searchText}%` } },
        { last_name: { ilike: `%${this.searchText}%` } },
        { location: { ilike: `%${this.searchText}%` } },
      ];
      this.userFilter.skip = 0;
      this.getUserList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  addUser(): void {
    this.$router.push({ name: "subscriber.create" });
  }

  editUser(id: number): void {
    this.$router.push({ path: `/subscribers/update/${id}` });
  }

  changeUser(id:number): void{
    alert.methods.conFirmAlert(
      changeUserAlert,
      () => {
        this.switchUser({ data: { id: id, userTypesId:2}, callback: this.getUserList});
      },
      () => {}
    );
  }

  backToUserManagement(): void {
    this.$router.push({ name: "user-management.list" }).catch();
  }

  deleteSubscriber(id: any): void {
    alert.methods.conFirmAlert(
      deleteSubscriberAlert,
      () => {
        this.deleteAdminandSubscribers({ data: { id: id, status: 2 }, callback: this.getUserList});
      },
      () => {}
    );
  }
}
