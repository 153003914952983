import keyMirror from "keymirror";

export default keyMirror({
    INIT_NEWS_ANNOUNCEMENTS: null,
    GET_NEWS_ANNOUNCEMENTS_LIST_SUCCESS: null,
    GET_NEWS_ANNOUNCEMENTS_LIST_FAIL: null,
    GET_NEWS_ANNOUNCEMENTS_COUNT: null,

    REMOVE_NEWS_ANNOUNCEMENT_SUCCESS: null,
    REMOVE_NEWS_ANNOUNCEMENT_FAIL: null,

    CREATE_NEWS_ANNOUNCEMENT_SUCCESS: null,
    CREATE_NEWS_ANNOUNCEMENT_FAIL: null,
    CREATE_NEWS_ANNOUNCEMENT_INIT: null,

    GET_NEWS_ANNOUNCEMENT_BY_ID_SUCCESS: null,
    GET_NEWS_ANNOUNCEMENT_BY_ID_FAIL: null
})