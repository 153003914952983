export class State {
  public error = '';
  public holidayInitData = {
    holiday_name: '',
    holiday_date: '',
    // status: true,
  };
  public holiday = {};
  public holidays = [];
  public holidayCount = 0;
}