























































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import _ from "lodash";

@Component({})
export default class MultiCheckbox extends Vue {
  @Prop() public options!: any;
  @Prop({ default: "text" }) textField!: string;
  @Prop({ default: "value" }) valueField!: string;
  @Prop() inputValue!: any;
  @Prop({ default: true }) isExpandVisible!: any;
  @Prop({ default: false }) disabled!: boolean;

  selected: any = this.getSelectedInput();
  allSelected = (this.inputValue && this.options && this.inputValue.length ===
    this.options.length && this.options.length > 0) || false;
  searchText = "";
  isSubmitted = false;
  filterOptions = this.options;

  getSelectedInput() {
    let values = [];
    if (this.inputValue && this.inputValue[0] && typeof this.inputValue[0] === "object") {
      values = this.inputValue.map((participant: any) => {
        return participant.id;
      });
    } else if (this.inputValue && this.inputValue[0]) {
      values = this.inputValue;
    }
    return values;
  }

  @Watch("options")
  onOptionsChange(newValue:any) {
    this.filterOptions = newValue;
  }

  @Watch("inputValue")
  onInputValueChange() {
   const value = this.getSelectedInput();
    if (!_.isEqual(_.sortBy(value), _.sortBy(this.selected))) {
      this.selected = value;
    }
  }

  toggleAll(checked: any) {
    this.selected = checked
      ? this.options.map((option: any) => {
          return option[this.valueField];
        })
      : [];
  }

  changeFilter(value: any) {
    this.searchText = value;
    this.filterOptions = this.options.filter((option: any) => {
      return option.fullname
        .toLowerCase()
        .includes(this.searchText.toLowerCase());
    });
  }

  @Watch("selected")
  onSelected(newValue: any): void {
    if (newValue.length === this.options.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    this.onChangeInput();
  }

  @Emit("getInputValue")
  public onChangeInput() {
    return this.selected;
  }

  openAttendeeListModal(): void {
    if(!this.disabled) {
      this.$emit("openAttendeeListModal", {});
    }
  }
}
