
































import { Vue, Component, Emit, Watch, Prop } from "vue-property-decorator";

@Component
export default class DateFilter extends Vue {
  selectedDate = "";
  showLabel = true;
  @Prop({ default: "" }) minDate!: string;
  @Prop({ default: false}) disabled!: boolean;
  @Prop({ default: "" }) maxDate!: string;

  @Watch("selectedDate")
  setSelectedDate() {
    this.emitDate();
  }

  @Emit("emitDate")
  emitDate() {
    return this.selectedDate;
  }
}
