
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {
  [types.GET_USER_TYPES_SUCCESS](state: State, response: any) {
    state.userTypes = response;
  },
  [types.GET_USER_TYPES_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_USER_PERMISSIONS_SUCCESS](state: State, response: any) {
    state.userPermissions = response;
  },
  [types.GET_USER_ROLE_PERMISSION_SUCCESS](state: State, response: any) {
    state.userRolePermission = response;
  },
  [types.GET_USER_ROLE_PERMISSIONS_SUCCESS](state: State, response: any) {
    state.rolePerm = response;
  }
} as MutationTree<State>;