interface roleOptions {
  [key: string]: string;
}

const DEFAULT_ROUTE_PATH: roleOptions = {
  SUPER_ADMIN: "meeting.list",
  ADMIN: "meeting.list",
  USER: "meeting.list",
  PHYSICIAN: "meeting.list",
  GUEST: "meeting.list",
};

export default {
  DEFAULT_ROUTE_PATH,
  AUTH_TOKEN: "authToken",
  REFRESH_TOKEN: "refreshToken",
  USER_ID: "userId",
  IS_FIRST_TIME_USER: "isFirstTimeUser",
  LOGIN_PAGE: "login.index",
  RESET_PASSWORD_CHANGE: "resetpassword.index",
  DATE_FORMAT: "DD-MMM-YYYY",
  CONFIRM_ALERT: "confirmAlert",
  IS_MEDICAL_REP: "isMedicalRep",
  LOCATION_ID: "locationId",
  TENANT_ID: "ti"
};
