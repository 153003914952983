import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

    [types.INIT_NEW_MEDICAL_REP](state: State) {
        state.medicalRep = state.medicalRepInitialData;
    },
    [types.GET_MEDICAL_REP_BY_ID_SUCCESS](state: State, response: any) {
        state.medicalRep = response;
    },
    [types.GET_MEDICAL_REP_BY_ID_FAIL](state: State, error: any) {
        state.error = error;
    },
    [types.MEDICAL_REP_UPDATE_SUCCESS](state: State, response: any) {
        state.medicalRep = response;
    },
    [types.MEDICAL_REP_UPDATE_FAIL](state: State, error: any) {
        state.error = error;
    }

} as MutationTree<State>;