
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

  [types.INIT_NEW_RESTAURANT](state: State) {
    state.restaurant = {};
  },
  [types.CREATE_NEW_RESTAURANT_SUCCESS](state: State, response: any) {
    state.restaurant = response.data;
  },
  [types.GET_RESTAURANT_LIST_SUCCESS](state: State, response: any) {
    state.restaurants = response;
  },
  [types.CREATE_NEW_RESTAURANT_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_RESTAURANT_BY_ID_SUCCESS](state: State, response: any) {
    state.restaurant = response;
  },
  [types.GET_RESTAURANT_BY_ID_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.RESTAURANT_UPDATE_SUCCESS](state: State, response: any) {
    state.restaurant = response;
  },
  [types.RESTAURANT_UPDATE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_RESTAURANT_COUNT](state: State, response: any) {
    state.restaurantCount = response.count;
  },
  [types.SET_RESTAURANT](state: State, restaurant: any) {
    state.restaurant = restaurant;
  },
} as MutationTree<State>;