import { State } from "./state";
import { GetterTree } from "vuex";
import { clone } from "@/utils/object";

export default {
  tenantPreference(state: State) {
    return clone(state.tenantPreference);
  },
  tenantPreferenceInitialData(state: State) {
    return clone(state.tenantPreferenceInitialData);
  },
  tenantPreferences(state: State) {
    return state.tenantPreferences;
  },
  preferencesCount: (state: State) => state.preferencesCount,

} as GetterTree<State, any>;
