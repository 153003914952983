















































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { deletePracticeAlert } from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import appConstants from "@/constants/app.properties";
import { ValidationObserver } from "vee-validate";
import constants from "@/constants/_message";
import { isNotEmpty } from "@/utils/object";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import { makeAsDefaultAlert } from "@/utils/notificationContent";
import PracticeLocationForm from "@/components/practiceLocationForm/practiceLocationForm.vue";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    PracticeLocationForm,
  },
})
export default class PracticeList extends Vue {
  @Action public getTenants: any;
  @Action public getPractices: any;
  @Action public removePractice: any;
  @Action public getPracticeCount: any;
  @Action public addPractice: any;
  @Action public enableDisablePractice: any;
  @Action public getDropdownPractice: any;
  @Getter public practices: any;
  @Getter public practiceCount: any;
  @Getter public currentUser: any;
  @Getter public tenants: any;
  @Getter public dropdownPracticeList!: any;
  @Action public getTenantLocation: any;
  @Action public getPracticeLocationByUser: any;
  @Action public getLocationDropdown: any;
  @Getter public rolePerm: any;
  perm = _permissions;

  selectedPracticeId: any = null;
  selectedPracticeForLocation: any = null;
  $refs!: {
    appModalRef: HTMLFormElement;
    practiceForm: InstanceType<typeof ValidationObserver>;
    practiceLocationModal: HTMLFormElement;
  };
  practiceFilter: any = {
    limit: 20,
    where: {
      userId: localStorage.getItem(appConstants.USER_ID),
    },
    fields: {
      created_by: false,
      updated_by: false,
      created_at: false,
      updated_at: false,
    },
    include: [
      {
        relation: "tenant",
        scope: {
          fields: {
            id: true,
            name: true,
          },
          include: [],
        },
      },
    ],
    skip: 0,
  };
  pageOptions = [10, 20];
  currentPage = 1;
  searchText = "";
  isSubmitted = false;

  beforeMount() {
    this.getPracticeList();
  }

  get currentPractice() {
    return Number(localStorage.getItem(appConstants.TENANT_ID));
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.practiceFilter.include[0].scope.where = {
        name: { ilike: `%${this.searchText}%` },
        is_active: true,
      };
      this.practiceFilter.skip = 0;
      this.getPractices(this.practiceFilter);
      this.getPracticeCount({ isCustom: true, ...this.practiceFilter });
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  getPracticeList() {
    this.getPractices(this.practiceFilter);
    this.getPracticeCount(this.practiceFilter.where);
  }

  openAddPracticeModal() {
    this.selectedPracticeId = null;
    this.getTenants({
      where: {
        is_active: true,
        id: {
          nin: this.practices.map(
            (practice: { tenantId: number }) => practice.tenantId
          ),
        },
      },
    });
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  openPracticeLocations(practice: any) {
    this.getTenantLocation({
      fields: { id: true, name: true },
      where: { status: { eq: true }, tenantId: { eq: practice.tenant.id }, isAdminLocation: true },
      order: ["name ASC"],
    });
    this.getPracticeLocationByUser({
      data: {
        offset: 0,
        limit: 1000,
        skip: 0,
        where: {
          userId: this.currentUser.id,
          tenant_id: practice.tenant.id,
          status: true,
        },
        fields: {
          id: true,
          tenantId: true,
          userId: true,
          tenantLocationId: true,
          status: true,
        },
        include: [
          {
            relation: "tenantLocation",
            scope: {
              offset: 0,
              limit: 100,
              fields: { id: true, name: true },
              include: [],
            },
          },
        ],
      },
      callBack: this.openPracticeLocationModal,
    });
    this.selectedPracticeForLocation = practice;
  }

  openPracticeLocationModal() {
    setTimeout(() => {
      const practiceLocationModal = this.$refs
        .practiceLocationModal as HTMLFormElement;
      practiceLocationModal.show();
    }, 500);
  }

  changeStatus(status: boolean, tenantId: number) {
    this.enableDisablePractice({
      data: { userId: this.currentUser.id, is_enabled: status, tenantId },
      callback: () => {
        this.getPractices(this.practiceFilter);
        this.getDropdownPractice();
      },
    });
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.practiceForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.practiceForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.practiceForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        this.addPractice({
          data: {
            userId: this.currentUser.id,
            tenantId: this.selectedPracticeId,
            is_enabled: true,
          },
          callback: () => {
            this.getPracticeList();
            this.getDropdownPractice();
            this.closeModal();
          },
        });
      }
    });
  }

  closeModal() {
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    this.selectedPracticeId = null;
  }

  onCancel(): void {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.closeModal();
      },
      () => {}
    );
  }

  makeAsDefault(practice: any) {
    alert.methods.conFirmAlert(
      makeAsDefaultAlert,
      () => {
        this.enableDisablePractice({
          data: { userId: this.currentUser.id, is_default: true, tenantId: practice.tenantId },
          callback: () => {
          this.getPractices(this.practiceFilter);
          this.getDropdownPractice();
      },
    });
      },
      () => {}
    );
  }

  deletePractice(id: number, tenantId: number): void {
    alert.methods.conFirmAlert(
      deletePracticeAlert,
      () => {
        this.removePractice({
          data: { id: id, status: false },
        });
        setTimeout(() => {
          this.getPracticeList();
          this.getDropdownPractice();
          if (
            Number(localStorage.getItem(appConstants.TENANT_ID)) === tenantId
          ) {
            const defaultPractice = this.dropdownPracticeList.filter(
              (practice: any) => practice.default
            );
            localStorage.setItem(
              appConstants.TENANT_ID,
              defaultPractice[0].tenantId
            );
          }
        }, 1000);
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.practiceFilter.skip = 0;
    this.getPracticeList();
  }

  onPageChange(currentPage: any) {
    this.practiceFilter.skip = (currentPage - 1) * this.practiceFilter.limit;
    this.getPracticeList();
  }
}
