import Vue from "vue";
import { getSecurityToken } from "@/utils/common";

class BaseProxy {
  public endpoint: string;
  public parameters: any;

  /**
   * The constructor of the BaseProxy.
   *
   * @param {string} endpoint   The endpoint being used.
   */
  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  public create(data: any, headers?: any) {
    return this.submit("post", `${this.endpoint}`, undefined, data, headers);
  }

  public update(data: any) {
    return this.submit("patch", `${this.endpoint}/${data.id}`, undefined, data);
  }

  public updateMultiPartForm(data: any, headers?: any) {
    return this.submit("patch", `${this.endpoint}/${data.get("id")}`, undefined, data, headers);
  }

  public getById(id: string) {
    return this.submit("get", `${this.endpoint}/${id}`);
  }

  public getUserById(id: string, filter: any) {
    return this.submit("get", `${this.endpoint}/${id}`,  {filter: JSON.stringify(filter)});
  }

  public getAll() {
    return this.submit("get", this.endpoint);
  }

  public getList(data: any) {
    return this.submit("get", this.endpoint,  {filter: JSON.stringify(data)});
  }

  public delete(id: string) {
    return this.submit("delete", `${this.endpoint}/${id}`);
  }

  /**
   * The method used to check token expires or not before making AJAX request.
   * If valid refresh token, It will get latest access token then further processed AJAX request
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param params Query params
   * @param {Object|null} data        The data to be send with the request.
   * @param {Object|null} headers     The request headers
   * @returns {Promise} The result in a promise.
   */
  public submit(
    requestType: string,
    url: string,
    params?: URLSearchParams | Record<string, unknown>,
    data?: any,
    headers?: any
  ) {
    return new Promise((resolve, reject) => {
        this.submitReq(requestType, url, params, data, headers).then((response) => {
          resolve(response);
        }).catch((error: any) => {
          reject(error);
        });
    });
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param params Query params
   * @param {Object|null} data        The data to be send with the request.
   * @param {Object|null} headers     The request headers.
   * @returns {Promise} The result in a promise.
   */
  public submitReq(
    requestType: string,
    url: string,
    params?: URLSearchParams | Record<string, unknown>,
    data?: any,
    headers?: any,
  ) {
    return new Promise((resolve, reject) => {
      const defaultHeader = {
        "Content-Type": "application/json",
      };
      const config = {
        headers: {...defaultHeader, ...headers, "x-api-key": getSecurityToken()},
        params: params
      };
      const args = data ? [url, data, config] : [url, config]
      Vue.prototype.$http[requestType](...args)
        .then((response: any) => {
          resolve(response.data);
        })
        .catch((response: any) => {
          if (response && response.response) {
            reject(response.response.data);
          } else {
            reject();
          }
        });
    });
  }
}

export default BaseProxy;
