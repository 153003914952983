/* ============
 * Actions for the Policy module
 * ============
 *
 * The actions that are available on the
 * Policy module.
 */
import types from "./mutation-types";
import PolicyProxy from "@/proxies/PolicyProxy";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";
import loadingTypes from "@/store/mutation-types";

const getPolicies = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new PolicyProxy()
    .getPolicies(data)
    .then((response: any) => {
      commit(types.FETCH_TENANT_POLICY_LIST_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.FETCH_TENANT_POLICY_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const createPolicy = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new PolicyProxy()
  .create(data)
    .then((response: any) => {
      commit(types.TENANT_POLICY_CREATE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: msgConstants.POLICY_CREATED_SUCCESS, type: "SUCCESS" });
      callback();
    })
    .catch((error: any) => {
      commit(types.TENANT_POLICY_CREATE_FAIL, error.error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const updatePolicy = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new PolicyProxy()
    .updatePolicy(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      if (data && data.status === false) {
        alert.methods.appToaster({ message: msgConstants.POLICY_DELETED_SUCCESS, type: "SUCCESS" });        
      } else {
        alert.methods.appToaster({ message: msgConstants.POLICY_UPDATED_SUCCESS, type: "SUCCESS" });
      }
      
      callback();
    })
    .catch(() => {
      alert.methods.appToaster({ message: msgConstants.POLICY_UPDATED_FAIL, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const initPolicy = ({ commit }: any) => {
  commit(types.INIT_TENANT_POLICY);
};

const getPoliciesCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new PolicyProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_POLICIES_COUNT_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_POLICIES_COUNT_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getPolicy = ({ commit }: any, {data,callback}:any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.GET_TENANT_POLICY_INIT);
  new PolicyProxy()
    .getUserById(data.id,data.filter)
    .then((response: any) => {
      callback();
      commit(types.GET_TENANT_POLICY_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch(({ error }: any) => {
      commit(types.GET_TENANT_POLICY_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const setPolicy = ({ commit }: any, policy: any) => {
  commit(types.SET_TENANT_POLICY, policy);
};

const addPolicyToLocations = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new PolicyProxy()
    .addPolicyToLocations(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
        alert.methods.appToaster({ message: msgConstants.ADD_POLICY_TO_ALL_LOCATION, type: "SUCCESS" });      
      callback();
    })
    .catch(() => {
      alert.methods.appToaster({ message: msgConstants.POLICY_UPDATED_FAIL, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};




export default {
  getPolicies,
  updatePolicy,
  createPolicy,
  initPolicy,
  setPolicy,
  getPolicy,
  getPoliciesCount,
  addPolicyToLocations
};
