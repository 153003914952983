import types from "./mutation-types";
import PhysicianRoomProxy from "@/proxies/PhysicianRoomProxy";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const initNewPhysicianRoom = ({ commit }: any) => {
  commit(types.INIT_NEW_PHYSICIAN_ROOM);
};

const createPhysicianRoom = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianRoomProxy().create(data).then((response: any) => {
    commit(types.CREATE_NEW_PHYSICIAN_ROOM_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.PHYSICIAN_ROOM_CREATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.CREATE_NEW_PHYSICIAN_ROOM_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getPhysicianRoomById = ({ commit }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianRoomProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_PHYSICIAN_ROOM_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_PHYSICIAN_ROOM_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getPhysicianRooms = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianRoomProxy().getPhysicianRooms(data).then((response: any) => {
    commit(types.GET_PHYSICIAN_ROOM_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.CREATE_NEW_PHYSICIAN_ROOM_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const updatePhysicianRoom = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianRoomProxy().updatePhysicianRoom(data).then(() => {
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.PHYSICIAN_ROOM_UPDATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.GET_PHYSICIAN_ROOM_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const getPhysicianRoomCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new PhysicianRoomProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_PHYSICIAN_ROOM_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_PHYSICIAN_ROOM_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getPhysiciansForDropDown = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new PhysicianRoomProxy()
    .getPhysiciansForDropdown(data)
    .then((response: any) => {
      commit(types.GET_PHYSICIAN_LIST_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_PHYSICIAN_ROOM_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    });
}

export default {
  initNewPhysicianRoom,
  createPhysicianRoom,
  getPhysicianRooms,
  getPhysicianRoomById,
  updatePhysicianRoom,
  getPhysicianRoomCount,
  getPhysiciansForDropDown
};