
import keyMirror from "keymirror";

export default keyMirror({
  INIT_NEW_HOLIDAY: null,
  CREATE_NEW_HOLIDAY_INIT: null,
  CREATE_NEW_HOLIDAY_SUCCESS: null,
  CREATE_NEW_HOLIDAY_FAIL: null,
  GET_HOLIDAY_LIST_INIT: null,
  GET_HOLIDAY_LIST_SUCCESS: null,
  GET_HOLIDAY_LIST_FAIL: null,
  GET_HOLIDAY_BY_ID_INIT: null,
  GET_HOLIDAY_BY_ID_SUCCESS: null,
  GET_HOLIDAY_BY_ID_FAIL: null,

  HOLIDAY_UPDATE_INIT: null,
  HOLIDAY_UPDATE_SUCCESS: null,
  HOLIDAY_UPDATE_FAIL: null,
  GET_HOLIDAY_COUNT: null,
  SET_HOLIDAY: null,
  DELETE_HOLIDAY_INIT: null,
  DELETE_HOLIDAY_SUCCESS: null,
  DELETE_HOLIDAY_FAIL: null,
});