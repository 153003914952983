/* ============
 * Mutation types for the Tenant Preference module
 * ============
 *
 * The mutation types that are available
 * on the Tenant Preference module.
 */
import keyMirror from "keymirror";

export default keyMirror({
  TENANT_PREFERENCE_CREATE_INIT: null,
  TENANT_PREFERENCE_CREATE_SUCCESS: null,
  TENANT_PREFERENCE_CREATE_FAIL: null,

  TENANT_PREFERENCE_UPDATE_INIT: null,
  TENANT_PREFERENCE_UPDATE_SUCCESS: null,
  TENANT_PREFERENCE_UPDATE_FAIL: null,

  GET_TENANT_PREFERENCE_INIT: null,
  GET_TENANT_PREFERENCE_SUCCESS: null,
  GET_TENANT_PREFERENCE_FAIL: null,

  FETCH_TENANT_PREFERENCE_LIST: null,
  FETCH_TENANT_PREFERENCE_LIST_SUCCESS: null,
  FETCH_TENANT_PREFERENCE_LIST_FAIL: null,

  DELETE_TENANT_PREFERENCE_INIT: null,
  DELETE_TENANT_PREFERENCE_SUCCESS: null,
  DELETE_TENANT_PREFERENCE_FAIL: null,

  SET_TENANT_PREFERENCE: null,
  INIT_TENANT_PREFERENCE: null,

  GET_PREFERENCES_COUNT_SUCCESS: null,
  GET_PREFERENCES_COUNT_FAIL: null,

});
