
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

  [types.INIT_NEW_PHYSICIAN_ROOM](state: State) {
    state.physicianRoom = {};
  },
  [types.CREATE_NEW_PHYSICIAN_ROOM_SUCCESS](state: State, response: any) {
    state.physicianRoom = response.data;
  },
  [types.GET_PHYSICIAN_ROOM_LIST_SUCCESS](state: State, response: any) {
    state.physicianRooms = response;
  },
  [types.CREATE_NEW_PHYSICIAN_ROOM_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_PHYSICIAN_ROOM_BY_ID_SUCCESS](state: State, response: any) {
    state.physicianRoom = response;
  },
  [types.GET_PHYSICIAN_ROOM_BY_ID_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.PHYSICIAN_ROOM_UPDATE_SUCCESS](state: State, response: any) {
    state.physicianRoom = response;
  },
  [types.PHYSICIAN_ROOM_UPDATE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_PHYSICIAN_ROOM_COUNT](state: State, response: any) {
    state.physicianRoomCount = response.count;
  },
  [types.SET_PHYSICIAN_ROOM](state: State, restaurant: any) {
    state.physicianRoom = restaurant;
  },
  [types.GET_PHYSICIAN_LIST_SUCCESS](state: State, response: any) {
    state.physiciansForDropdown = response;
  }
} as MutationTree<State>;