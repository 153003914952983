import types from "./mutation-types";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";
import TeamProxy from "@/proxies/TeamProxy";


const createTeam = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new TeamProxy().create(data).then((response: any) => {
    commit(types.CREATE_NEW_TEAM_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.TEAM_CREATE_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.GET_TEAM_LIST_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getTeamById = ({ commit }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  new TeamProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_TEAM_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_TEAM_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getTeams = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new TeamProxy().getTeams(data.query).then((response: any) => {
    if (data.callback) {
      data.callback(response);
    }
    commit(types.GET_TEAM_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.GET_TEAM_LIST_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};


const getTeamCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new TeamProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_TEAM_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_TEAM_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const setTeam = ({ commit }: any, Team: any) => {
  commit(types.SET_TEAM, Team);
};

const updateTeam = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new TeamProxy().updateTeam(data).then(() => {
    commit(loadingTypes.END_LOADING);
    if (!('status' in data)) {
      alert.methods.appToaster({ message: msgConstants.TEAM_UPDATE_SUCCESS, type: "SUCCESS" });
    } else {
      alert.methods.appToaster({ message: msgConstants.TEAM_DELETE_SUCCESS, type: "SUCCESS" });
    }
    callback();
  }).catch((error: any) => {
    commit(types.GET_TEAM_LIST_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};


const deleteTeamById = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new TeamProxy().delete(data.id).then(() => {
    commit(types.DELETE_TEAM_SUCCESS, data.id);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.TEAM_DELETE_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.DELETE_TEAM_FAIL, error);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
  });
};


export default {
  getTeams,
  getTeamById,
  getTeamCount,
  setTeam,
  createTeam,
  updateTeam,
  deleteTeamById,
};