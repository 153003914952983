import Proxy from "./Proxy";

class PhysicianRoomProxy extends Proxy {
  /**
   * Constructor for Auth proxy
   */
  constructor() {
    super(`/physician-rooms`);
  }

  public updatePhysicianRoom(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }

  public getPhysicianRooms(data: any) {
    return this.getList(data);
  }

  public createPhysicianRoom(data: any) {
    return this.submit("post", this.endpoint, undefined, data);
  }

  public getListCount(data: any) {
    return this.submit("get", `physician-rooms/count`, {where: JSON.stringify(data)});
  }

  public getPhysiciansForDropdown(data: any): any {
    return this.submit("get", `/physicians/dropdown`, {filter: JSON.stringify(data)});
  }
}

export default PhysicianRoomProxy;
