import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  alpha_num,
  min_value,
  max_value,
  min,
  mimes,
  size
} from "vee-validate/dist/rules";

const mobile = {
  validate(value: any) {
    // Custom regex for a phone number
    const MOBILEREG = /^([[0-9]{1,5})?([0-9][0-9]{9})$/;
    return MOBILEREG.test(value);
  }
};

const alpha_one = {
  validate(value: any) {
    const MOBILEREG = /^.*[a-zA-Z][^a-zA-Z]*$/;
    return MOBILEREG.test(value);
  }
};

extend("required", {
  ...required,
  message: "This field is required"
});

extend("is_not", {
  ...confirmed,
  validate: (value: string, { target }: any) => {
    return value !== target;
  },
  message: "This field should not match with current password"
});

extend("regex", {
  ...regex,
  message: "Invalid format"
});

extend("mimes", {
  ...mimes,
  message: "Unsupported file type. Allowed types: jpeg, png, txt, pdf, doc, docx, msword, ppt, xls, xlsx",

});

extend("size", {
  ...size,
  params: [{ name: 'size', cast: size => size / 1024 }],
  validate: (value: any, { size }: any) => {
    const sizeAtMB = Math.round(value.size / 1000000);
    return sizeAtMB <= size;
  },
  message: "File exceeds size limit: {size} MB",
});

extend("confirmed", {
  ...confirmed,
  message: "This field should match with new password"
});

extend("password_check", {
  validate: (value: any) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_$=%?\^&\*\+\-])(?=.{8,})");/* eslint-disable-line */
    return strongRegex.test(value);/* eslint-disable-line */
  },
  message: "The password must contain minimum 8 characters with at least: 1 uppercase, 1 lowercase, 1 number, and one special character"
});

extend("us_mobile", {
  validate: (value: any) => {
    return value.length === 17;/* eslint-disable-line */
  },
  message: "Mobile number should be valid format"
});

extend("email", {
  ...email,
  message: "Email format is invalid"
});

extend("mobile", {
  ...mobile,
  message: "This field must be valid mobile number"
});

extend("alpha_one", {
  ...alpha_one,
  message: "{_field_} must contain atleast 1 alphabet"
});

extend("alpha_num", {
  ...alpha_num,
  message: "This field does not allow special characters"
});

extend("min_value", {
  ...min_value,
  message: "The field must be greater than {min}"
});

extend('min', {
  ...min,
  validate(value, args) {
    return value.trim().length >= args.length;
  },
  params: ['length'],
  message: "Minimum {length} letters required"
});

extend("max_value", {
  ...max_value,
  message: "The field must be less than {max}"
});