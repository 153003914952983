











import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ToasterComponent extends Vue {
  @Prop() public message: any;
  @Prop() public type: any;
}
