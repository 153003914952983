


















































import { Component, Prop, Vue } from "vue-property-decorator";
import LetterIcon from "../../components/letterIcon/LetterIcon.vue";
import moment from "moment";
import { Getter } from "vuex-class";

@Component({
  components: {
    LetterIcon,
  },
})
export default class MessageList extends Vue {
  @Prop() message: any;
  @Prop() messageDate: any;
  @Prop() index: any;
  @Prop() messageVal: any;
  @Prop() latestMsgTo: any;
  @Prop() latestMsgFrom: any;
  @Prop() msgSender: any;
  @Getter public currentUser: any;
  checkDate = moment();

  getMessageTime(value: string) {
    // const isSameDate = moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").startOf("day").isSame(this.checkDate.startOf("day"));
    // if(isSameDate) {
    //   this.checkDate = moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").startOf("day");
    //   return moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").format('MM-DD-YYYY');
    // }
    // if(this.checkDate.isSame(moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").startOf("day"))) {
    //   return "";
    // } else {
    //   return moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").format('hh:mm A');
    // }
    // return isSameDate ? `Today ${moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").format("hh:mm A")}` : this.checkDate.format("MM-DD-YYYY hh:mm A");
    return moment(value).format("hh:mm A");
  }

  get chatDateValid() {
    return (
      this.index == 0 ||
      moment(this.message.created_at).format("YYYY-MM-DD") !=
        moment(this.messageVal[this.index - 1].created_at).format("YYYY-MM-DD")
    );
  }

  get lastMsgFrom() {
    return (
      this.latestMsgFrom.length > 0 &&
      this.latestMsgFrom.includes(this.message.id)
    );
  }

  get lastMsgTo() {
    return (
      this.latestMsgTo.length > 0 && this.latestMsgTo.includes(this.message.id)
    );
  }
}
