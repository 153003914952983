import types from "./mutation-types";
import AuthProxy from "@/proxies/AuthProxy";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const initNewMedicalRep = ({ commit }: any) => {
    commit(types.INIT_NEW_MEDICAL_REP);
};

const getMedicalRepById = ({ commit }: any, data: any) => {
    commit(loadingTypes.START_LOADING);
    new AuthProxy()
        .getUserById(data.id, data.filter)
        .then((response: any) => {
            commit(types.GET_MEDICAL_REP_BY_ID_SUCCESS, response);
            commit(loadingTypes.END_LOADING);
            data.callback();
        })
        .catch((error: any) => {
            commit(types.GET_MEDICAL_REP_BY_ID_FAIL, error);
            commit(loadingTypes.END_LOADING);
        });
};

const updateMedicalRep = ({ commit }: any, { data, callback }: any) => {
    commit(loadingTypes.START_LOADING);
    new AuthProxy().updateUserDetails(data).then(() => {
        commit(loadingTypes.END_LOADING);
        commit(types.MEDICAL_REP_UPDATE_SUCCESS, data);
        alert.methods.appToaster({ message: msgConstants.MEDICAL_REP_UPDATE_SUCCESS, type: "SUCCESS" });
        callback();
    }).catch((error: any) => {
        commit(types.MEDICAL_REP_UPDATE_FAIL, error.error);
        alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
        commit(loadingTypes.END_LOADING);
    });
};

export default {
    initNewMedicalRep,
    getMedicalRepById,
    updateMedicalRep
};