import { GetterTree } from "vuex";
import { State } from "./state";

export default {
  physicianRoom: (state: State) => state.physicianRoom,
  physicianInitData: (state: State) => state.physicianInitData,
  physicianRooms: (state: State) => state.physicianRooms,
  physicianRoomCount: (state: State) => state.physicianRoomCount,
  physiciansForDropdown: (state: State) => state.physiciansForDropdown,
} as GetterTree<State, any>;
