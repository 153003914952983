export default {
  NO_CONNECTION: "No connection.",
  INTERNAL_SERVER_ERROR: "Internal server error.",
  SOMETHING_WENT_WRONG: 'Something went wrong! Kindly contact administrator.',
  CHANGE_PASSWORD: "Contact your administrator to change the password.",
  ACCESS_DENIED: "You have no permission to access this page. Contact your administrator.",
  LOGIN_ERROR: "Email or password is incorrect!.",
  USER_NOT_ACTIVATED: "Your account is pending approval.",
  USER_BLOCKED: "Your account is currently blocked by the practice. Please contact administrator.",
  LOGIN_SUCCESS: "Login success.",
  LOGOUT_SUCCESS: "Logout success.",
  LOGIN_EMPTY_ALERT: "Email or password cannot be empty.",
  MEETING_CREATED_SUCCESS: "Meeting  successfully created and saved.",
  MEETING_CREATED_WITH_PUBLISH_SUCCESS: "Meeting  successfully created and published.",
  MEETING_CREATED_FAIL: "Meeting not created.",
  MEETING_CREATED_FAIL_FOR_NON_ACTIVE_USER: "Approval request has been sent to your zoom email. Please approve the request to publish a meeting.",
  MEETING_UPDATED_SUCCESS: "Meeting  successfully updated and saved.",
  MEETING_UPDATED_WITH_PUBLISH_SUCCESS: "Meeting successfully booked.",
  MEETING_UPDATED_WITH_CONFIRM_SUCCESS: "Meeting successfully confirmed.",
  MEETING_UPDATED_FAIL: "Meeting not updated.",
  MEETING_DELETED_SUCCESS: "Meeting  successfully cancelled.",
  MEETING_DELETED_FAIL: "Meeting not cancelled.",
  MEETING_UPDATED: "Meeting  successfully updated.",
  MEETING_ALREADY_COMPLETED: "Meeting already completed.",
  MEETING_NOT_FOUND: "Meeting not found.",
  FORM_ERROR: "Please correct the errors then resubmit.",
  PROFILE_UPDATED_SUCCESS: "Profile successfully updated.",
  PROFILE_UPDATED_FAIL: "Profile not updated.",
  BULK_UPDATE_SUCCESS: "All the users status under this domain has successfully updated.",
  BULK_UPDATE_FAIL: "All the users status under this domain not updated.",
  PASSWORD_RESET_SUCCESS: "Password successfully changed. Kindly re-login again.",
  PASSWORD_RESET_FAIL: "Password not changed.",
  START_TIME_ERROR: "Meeting start time should be greater than current time.Please change start time and publish.",
  START_TIME_ERROR_AT_SAVE: "Meeting start time should be greater than current time.Please change start time then resubmit.",
  END_TIME_ERROR_AT_SAVE: "Meeting end time should be greater than start time.Please change end time then resubmit.",
  ATLEAST_ONE_PARTICIPANT_SELECTED: "Atleast one participant needs to be selected.",
  NOT_VALID_CURRENT_PASSWORD: "Current password is not valid.",
  PLEASE_SELECT_USER_TO_PROCEED: "Please select any user to proceed.",
  KINDLY_LOGIN_AND_PROCEED: "Kindly login and proceed.",
  SUBSCRIPTION_NOT_VALID: "You don't have access to create meeting. Please subscribe one of the plan.",
  SUBSCRIPTION_ADDED_SUCCESS: "Subscription plan successfully added.",
  SUBSCRIPTION_ADDED_FAIL: "Subscription plan not added.",
  NOT_ACCESS_TO_THIS_PAGE: "You don't have access to this page.",
  NOT_ACCESS_TO_THIS_MEETING: "You don't have access to this meeting.",
  PASSWORD_CHANGED_SUCCESS: "Password successfully changed. Please login using new credentials.",
  RESET_LINK_SENT_TO_EMAIL: "Password reset link has been sent to your email. Please check.",
  NON_MEETING_LINK_ERROR_AT_PUBLISH: "You should enter the meeting link for your participants.",
  USER_DELETED_SUCCESS: "User successfully deleted.",
  USER_DELETED_FAIL: "User not deleted.",
  USER_UPDATED_SUCCESS: "User successfully updated.",
  USER_CREATED_SUCCESS: "User created successfully.",
  USER_UPDATED_FAIL: "User not updated.",
  LOCATION_CREATE_SUCCESS: "Location created successfully.",
  LOCATION_UPDATE_SUCCESS: "Location updated successfully.",
  LOCATION_TIMESLOT_CREATE_SUCCESS: "Appointment type is added for this location successfully.",
  LOCATION_TIMESLOT_UPDATE_SUCCESS: "Appointment type is updated for this location successfully.",
  POLICY_CREATED_SUCCESS: "Policy successfully created.",
  POLICY_CREATED_FAIL: "Policy not created.",
  POLICY_UPDATED_SUCCESS: "Policy successfully updated.",
  POLICY_UPDATED_FAIL: "Policy not updated.",
  POLICY_DELETED_SUCCESS: "Policy successfully deleted.",
  POLICY_DELETED_FAIL: "Policy not deleted.",
  PREFERENCE_CREATED_SUCCESS: "Preference successfully created.",
  PREFERENCE_CREATED_FAIL: "Preference not created.",
  PREFERENCE_UPDATED_SUCCESS: "Preference successfully updated.",
  PREFERENCE_UPDATED_FAIL: "Preference not updated.",
  PREFERENCE_DELETED_SUCCESS: "Preference successfully deleted.",
  PREFERENCE_DELETED_FAIL: "Preference not deleted.",
  APPOINTMENT_TYPE_CREATED_SUCCESS: "Appointment type successfully created.",
  APPOINTMENT_TYPE_CREATED_FAIL: "Appointment type not created.",
  APPOINTMENT_TYPE_UPDATED_SUCCESS: "Appointment type successfully updated.",
  APPOINTMENT_TYPE_UPDATED_FAIL: "Appointment type not updated.",
  RESTAURANT_CREATE_SUCCESS: "Restaurant created successfully.",
  RESTAURANT_UPDATE_SUCCESS: "Restaurant updated successfully.",
  LOCATION_POLICIES_UPDATE_SUCCESS: "Location policies updated successfully.",
  LOCATION_PREFERENCES_UPDATE_SUCCESS: "Location preferences updated successfully.",
  MEETING_RULE_CREATE_SUCCESS: "Meeting rule created successfully.",
  MEETING_RULE_UPDATE_SUCCESS: "Meeting rule updated successfully.",
  MEETING_RULE_DELETE_SUCCESS: "Meeting rule deleted successfully.",
  TEAM_CREATE_SUCCESS: "Team created successfully.",
  TEAM_UPDATE_SUCCESS: "Team updated successfully.",
  TEAM_DELETE_SUCCESS: "Team deleted successfully.",
  MEETING_TRANSFER_SUCCESS: "Meeting transferred successfully.",
  BLOCK_CANCEL_USER_SUCCESS: "User blocked and all appointments cancelled successfully.",
  BLOCK_TRANSFER_SUCCESS: "User blocked and all appointments transferred successfully.",
  CANCEL_ALL_MEETINGS_SUCCESS: "All appointments cancelled successfully.",
  PRACTICE_ADD_SUCCESS: "Practice added successfully.",
  REMOVE_PRACTICE_SUCCESS_MSG: 'Practice removed successfully.',
  PHYSICIAN_ROOM_CREATE_SUCCESS: "Physician log created successfully",
  PHYSICIAN_ROOM_UPDATE_SUCCESS: "Physician log updated successfully",
  PRACTICE_LOCATION_ADDED_SUCCESS: 'Location preferences updated successfully.',
  MEETING_GENERATE_SUCCESS: "Meetings generated successfully.",
  GENERATE_MEETING_CARD_FORM_ERROR: "Please select both start date and end date.",
  TIME_SLOT_DELETED_SUCCESS: "Appointment type deleted successfully.",
  USER_VACCINATION_STATUS: 'Your vaccination status updated successfully.',
  PHYSICIAN_USER_CREATE_SUCCESS: 'Physician created successfully.',
  PHYSICIAN_USER_UPDATE_SUCCESS: 'Physician updated successfully.',
  PHYSICIAN_DELETE_SUCCESS: 'Physician deleted successfully.',
  SUBSCRIBER_CREATED_SUCCESS: "Subscriber created successfully.",
  SUBSCRIBER_UPDATED_SUCCESS: "Subscriber updated successfully.",
  SOMETHING_WENT_WRONG_KINDLY_TRY_AFTER_SOMETIME: "Something went wrong! Kindly try again after some time.",
  SUBSCRIBER_DELETE_SUCCESS: "Subscriber deleted successfully.",
  SWITCH_USER_SUCCESS: "User successfully changed.",
  SWITCH_USER_FAIL: "User not changed.",
  PASSWORD_RESET_SUCCESSFULL: "Password changed successfully.",
  PASSWORD_PATTERN_ERROR: 'The password must contain minimum 8 characters with at least: 1 uppercase, 1 lowercase, 1 number, and one special character.',
  HOLIDAY_CREATE_SUCCESS: 'Holiday created successfully.',
  HOLIDAY_UPDATE_SUCCESS: 'Holiday updated successfully.',
  HOLIDAY_DELETED_SUCCESS: 'Holiday deleted successfully.',
  HOLIDAY_DELETE_FAIL: 'Holiday not deleted.',
  HOLIDAY_NAME_MANDATORY: 'Holiday name is mandatory.',
  LOCATION_SUSPEND_SUCCESS: "Location appointments suspended successfully.",
  LOCATION_REVOKE_SUCCESS: "Location appointments unsuspended successfully.",
  SIGNATURE_REQURIED: "Signature is required.",
  LOCATION_APPOINTMENTS_CANCEL_AND_DELETE_SUCCESS: "Location appointments cancelled and deleted successfully.",
  LOCATION_APPOINTMENT_CANCEL_SUCCESS: "Location appointments cancelled successfully.",
  CANCEL_DELETE_MEETING_CARD_FORM_ERROR: "Please select days to continue.",
  USER_ROLE_PERMISSIONS_UPDATE_SUCCESS: "User permissions updated successfully.",
  NEWS_ANNOUNCEMENT_CREATE_SUCCESS: "News & announcement created successfully.",
  NEWS_ANNOUNCEMENT_UPDATE_SUCCESS: "News & announcement updated successfully.",
  NEWS_ANNOUNCEMENT_DELETE_SUCCESS: "News & announcement deleted successfully.",
  USER_REJECTED: "Your account is not approved, please contact the practice.",
  SESSION_EXP: "Your session has expired. Please login again to continue.",
  MEDICAL_REP_UPDATE_SUCCESS: "Pharma rep updated successfully.",
  ADD_POLICY_TO_ALL_LOCATION: "Policy has been added successfully to the chosen locations.",
  RESET_LINK_SENT_TO_MOBILE: "Password reset code has been sent to your phone. Please check.",
  BLOCK_ALL_REPS: 'All pharma reps has been successfully blocked',
  UNBLOCK_ALL_REPS: 'All pharma reps has been successfully unblocked',
};