
















































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import constants from "@/constants/_message";
import Variables from "@/constants/_variables";

@Component
export default class login extends Vue {
  @Action private login: any;
  @Getter public loginErrMsg: any;
  @Action private getSettingValue: any;
  @Getter public settingValue: any;

  public userObj: any = {
    email: "",
    password: ""
  };

  isRememberMe = true;
  isSubmitted = false;

  private passwordVisibility: any = {
    password: {
      type: Variables.PASSWORD_TYPE.password,
      icon: Variables.PASSWORD_ICON.show
    }
  };

  beforeMount() {
    this.getSettingValue(
      {
        where: {
          setting_type: "login"
        },
        fields: {
          setting_value: true
        }
      }
    )
  }

  onLogin(): void {
    this.isSubmitted = true;
    if (
      !this.userObj ||
      this.userObj.email.trim() === "" ||
      this.userObj.password.trim() === ""
    ) {
      alert.methods.appToaster({
        message: constants.LOGIN_EMPTY_ALERT,
        type: "ERROR"
      });
    } else if (this.userObj.password.length < 8) {
      alert.methods.appToaster({
        message: constants.LOGIN_ERROR,
        type: "ERROR"
      });
    } else {
      this.userObj.email = this.userObj.email.toLowerCase();
      this.userObj.email = this.userObj.email.trim();
      this.login(this.userObj);
    }
  }

  onForgotPassword(): void {
    this.$router.push({ name: "forgotPassword.index" });
  }

  onRegister(): void {
    this.$router.push({ name: "register.index" });
  }

  private togglePasswordVisibility(field: any) {
    if (this.passwordVisibility[field].type === Variables.PASSWORD_TYPE.text) {
      this.passwordVisibility[field].type = Variables.PASSWORD_TYPE.password;
      this.passwordVisibility[field].icon = Variables.PASSWORD_ICON.show;
    } else {
      this.passwordVisibility[field].type = Variables.PASSWORD_TYPE.text;
      this.passwordVisibility[field].icon = Variables.PASSWORD_ICON.hide;
    }
  }
}
