




























































































































import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import moment from "moment";
import DatePicker from "../datepicker/index.vue";
import MultiSelect from "@/components/formComponents/MultiSelect/Index.vue"

@Component({
  components: {
    DatePicker,
    MultiSelect
  },
})
export default class CancelDeleteAppointmentModal extends Vue {
  @Prop({ default: "lg" }) public size!: any;
  @Prop() public selectedLocation: any;
  @Prop() public title!: string;
  @Prop({default: false}) public isDeleteAppointments!: boolean;

  @Getter public currentUser: any;
  @Action public cancelAndDeleteAppointments: any;
  @Getter public appointmentTypes: any;

  isSubmitted = false;
  showDate = true;
  $refs!: {
    generateMeetingForm: InstanceType<typeof ValidationObserver>;
  };
  cancelDeleteMeetingDate = {
    startDate: null,
    endDate: null,
  };
  endDateDisabled = true;
  minStartDate = "";
  minEndDate = "";
  maxEndDate = "";
  timezone = "";
  selected: any = [];
  withoutWeekEndsOptions = [1,2,3,4,5];
  withWeekEndsOptions = [0,1,2,3,4,5,6];
  showCheckBox = true;
  disableSelectAllCheckBox = false;
  options = [
    {
      text: 'Select by date range',
      value: 0
    },
    {
      text: 'Select by days',
      value:1
    },
  ];
  selectedOption = 0;
  reason = '';
  selectedAppointmentTypes = [];

  beforeMount() {
    this.minStartDate = moment().toISOString();
    this.toggleAll(true);
  }

  @Watch('currentUser')
  onCurrentUserChanged() {
    this.toggleAll(true);
  }

  setStartDate(value: any) {
    this.cancelDeleteMeetingDate.startDate = value;
    this.minEndDate = moment(moment(value).toISOString())
      .add(1, "week")
      .toISOString();
    this.maxEndDate = moment(moment(value).toISOString())
      .add(1, "years")
      .toISOString(); 
    this.endDateDisabled = false;
  }

  setEndDate(value: any) {
    this.cancelDeleteMeetingDate.endDate = value;
  }

  setAppointmentTypes(value: any) {
    this.selectedAppointmentTypes = value;
  }

  toggleAll(checked: any) {
    if(checked) {
      this.selected = this.currentUser.tenant && this.currentUser.tenant.allow_weekend_meetings ? this.withWeekEndsOptions : this.withoutWeekEndsOptions;
    } else {
      this.selected = [];
    }
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    if(this.cancelDeleteMeetingDate.startDate == null ||
      this.cancelDeleteMeetingDate.endDate == null) {
        alert.methods.appToaster({
        message: constants.GENERATE_MEETING_CARD_FORM_ERROR,
        type: "ERROR",
      });
      return;
    } else if(this.selected.length == 0) {
      alert.methods.appToaster({
        message: constants.CANCEL_DELETE_MEETING_CARD_FORM_ERROR,
        type: "ERROR",
      });
      return;
    } else {
      this.$refs.generateMeetingForm.validate().then((success: any) => {
        if (!success) {
          alert.methods.appToaster({
            message: constants.FORM_ERROR,
            type: "ERROR",
          });
          const firstErrorFocused: HTMLFormElement = Object(
            document.querySelector(".error:first-of-type")
          );
          if (
            isNotEmpty(firstErrorFocused) &&
            !firstErrorFocused.classList.contains("IZ-select")
          ) {
            firstErrorFocused.querySelector("input");
            firstErrorFocused.focus();
          }
          const errors = Object.entries(this.$refs.generateMeetingForm.errors)
            .map(([key, value]) => ({ key, value }))
            .filter((error) => error["value"].length);
          if (errors.length) {
            this.$refs.generateMeetingForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
          return;
        } else {
          const weekdays = this.selected.map((str: any) => {
            return Number(str);
          });
          const selectedAppointmentTypes = this.selectedAppointmentTypes.map((value: any) => {
            return value.appointment_type;
          });
          const appointmentTypeIds = this.selectedAppointmentTypes.map((value: any) => {
            return Number(value.id);
          });
          const data = {
            locationId: this.selectedLocation.id,
            is_delete_appointments: this.isDeleteAppointments,
            startDate: moment(this.cancelDeleteMeetingDate.startDate),
            endDate: moment(this.cancelDeleteMeetingDate.endDate),
            selected_days: weekdays,
            reason: this.reason,
            refreshment: selectedAppointmentTypes,
            appointmentTypeIds: appointmentTypeIds,
          }
          this.cancelAndDeleteAppointments({ data: data, callBack: this.onSubmit });
        }
      });
    }
  }

  onCancel() {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.hide();
      },
      () => {
      }
    );
  }

  resetData() {
    this.endDateDisabled = true;
    this.toggleAll(true);
    this.reason = '';
    this.cancelDeleteMeetingDate = {
      startDate: null,
      endDate: null,
    }
    this.selectedAppointmentTypes = [];
    this.showDate = false;
    setTimeout(() => {
      this.showDate = true;
    }, 100);
  }

  show(): void {
    this.$bvModal.show("cancel-delete-meeting-modal");
  }

  hide() {
    this.resetData();
    this.$bvModal.hide("cancel-delete-meeting-modal");
  }

  @Emit("onSubmit")
  onSubmit() {
    this.hide();
    return true;
  }
}
