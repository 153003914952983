/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import Vue from "vue";
import VueRouter, { RawLocation, Route } from "vue-router";
import routes from "@/router";
import store from "@/store";
import constants from "@/constants/app.properties";
import msgConstants from "@/constants/_message";
import alert from "@/mixins/alert";
import moment from "moment";


Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      // on complete
      resolve(this.currentRoute);
    }, (error) => {
      // on abort
      // only ignore NavigationDuplicated error
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

export const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  //Check auth token in store else get it from localstorage
  const isAuthenticated = store.getters.isAuthenticated;
  const isPasswordChanged = localStorage.getItem(constants.IS_FIRST_TIME_USER);
  const currentUser = store.getters.currentUser;
  if (to.matched.some(m => m.meta.requiresAuth) && !isAuthenticated) {
    /*
     * If the user is not authenticated and visits
     * a page that requires authentication, redirect to the login page
     */
    alert.methods.appToaster({ message: msgConstants.KINDLY_LOGIN_AND_PROCEED, type: "WARNING" });
    if (to.name === 'registerApproval.index') {
      localStorage.setItem("isPendingApprovalUser", "true");
      if (to.query && to.query.e) {
        const email: any = to.query.e;
        localStorage.setItem("pendingApprovalUserMail", email);
      }
    }
    next({
      name: constants.LOGIN_PAGE
    });
  } else if (to.matched.some(m => !m.meta.requiresAuth) && isAuthenticated) {
    /*
     * If the user is authenticated and visits
     * an guest page, redirect to the home page
     */
    if (to.name !== 'meeting.list' && to.name !== 'landing.index') {
      next({
        name: constants.DEFAULT_ROUTE_PATH.GUEST
      });
    } else {
      next();
    }
  } else if (isPasswordChanged === 'false' && to.name !== 'resetpassword.index') {
    next({
      name: constants.RESET_PASSWORD_CHANGE
    });
  } else if (from.name === 'messages.index') {
    store.commit('SET_REP_NAME', '');
    next();
  } else if ('id' in currentUser && currentUser.is_medical_rep && currentUser.tenant.enable_subscription) {
    const currentTime = moment.utc(
      moment().format("YYYY-MM-DD"),
      "YYYY-MM-DD"
    );
    let subscriptionTime;
    let isCheckSubscription = false;
    if (currentUser.tenant.subscription_start_date && currentUser.tenant.subscription_start_date !== '') {
      let subscriptionStartTime = moment.utc(currentUser.tenant.subscription_start_date, "YYYY-MM-DD[T]HH:mm[Z]");
      if (currentUser.tenant.subscription_grace_days > 0) {
        subscriptionStartTime = subscriptionStartTime.add(currentUser.tenant.subscription_grace_days, 'days');
      }
      if (subscriptionStartTime.isSameOrBefore(currentTime)) {
        if (currentUser.subscription_end_date) {
          subscriptionTime = moment.utc(
            currentUser.subscription_end_date,
            "YYYY-MM-DD[T]HH:mm[Z]"
          );
          if (currentUser.tenant.subscription_grace_days > 0) {
            subscriptionTime = subscriptionTime.add(currentUser.tenant.subscription_grace_days, 'days');
          }
        } else if (currentUser.subscription_end_date == null) {
          subscriptionTime = subscriptionStartTime;
        } else {
          subscriptionTime = moment.utc(
            currentTime.format('YYYY-MM-DD[T]HH:mm[Z]'),
            "YYYY-MM-DD[T]HH:mm[Z]"
          );
        }
        isCheckSubscription = !store.getters.isCurrentTenantBlocked && !store.getters.isSubscribed && (from.fullPath === '/subscription' || from.name === 'payment.index') && subscriptionTime.isSameOrBefore(currentTime);
      } else {
        isCheckSubscription = false;
      }
    }
    if (isCheckSubscription) {
      if (to.fullPath !== '/subscription' && to.name !== 'payment.index' && to.matched.some(m => m.meta.requiresAuth)) {
        router.push({ name: 'subscription.index' })
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
