import Vue from "vue";
import Vuex from "vuex";
import mutationTypes from "./mutation-types";

// Modules
import { auth } from "./modules/auth";
import { meeting } from "./modules/meeting";
import { tenant } from "./modules/tenant";
import { location } from "./modules/location";
import { locationtimeslot } from "./modules/location-timeslot";
import { message } from "./modules/message";
import { policy } from "./modules/tenant-policy";
import { preference } from "./modules/tenant-preference";
import { appointmenttype } from "./modules/appointment-type";
import { restaurant } from "./modules/restaurant";
import { meetingRule } from "./modules/meeting-rule";
import { teams } from "./modules/teams";
import { practice } from "./modules/practice";
import { physicianRoom } from "./modules/physician-room";
import { physicians } from "./modules/physicians";
import { holiday } from "./modules/holiday";
import { userTypes } from "./modules/user-types";
import { newsAnnouncement } from "./modules/news-announcement";
import { medicalrep } from "./modules/medical-rep";

/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  /**
   * Assign the modules to the store.
   */
  modules: {
    auth,
    meeting,
    tenant,
    location,
    locationtimeslot,
    message,
    policy,
    preference,
    appointmenttype,
    restaurant,
    meetingRule,
    teams,
    practice,
    physicianRoom,
    physicians,
    holiday,
    userTypes,
    newsAnnouncement,
    medicalrep
  },
  state: {
    isLoading: false,
  },
  mutations: {
    [mutationTypes.START_LOADING]: (state: any) => {
      state.isLoading = true;
    },
    [mutationTypes.END_LOADING]: (state: any) => {
      state.isLoading = false;
    }
  },
  getters: {
    isLoading: (state: any) => state.isLoading,
  },
  actions: {
    startLoading: ({ commit }: any) => {
      commit(mutationTypes.START_LOADING);
    },
    endLoading: ({ commit }: any) => {
      commit(mutationTypes.END_LOADING);
    },
  },
  /**
   * If strict mode should be enabled.
   */
  strict: debug,

  /**
   * Plugins used in the store.
   */
  plugins: []
});
