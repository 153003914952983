
import keyMirror from "keymirror";

export default keyMirror({
  INIT_NEW_LOCATION: null,
  CREATE_NEW_LOCATION_INIT: null,
  CREATE_NEW_LOCATION_SUCCESS: null,
  CREATE_NEW_LOCATION_FAIL: null,
  GET_LOCATION_LIST_INIT: null,
  GET_LOCATION_LIST_SUCCESS: null,
  GET_LOCATION_LIST_FAIL: null,
  GET_LOCATION_BY_ID_INIT: null,
  GET_LOCATION_BY_ID_SUCCESS: null,
  GET_LOCATION_BY_ID_FAIL: null,

  LOCATION_UPDATE_INIT: null,
  LOCATION_UPDATE_SUCCESS: null,
  LOCATION_UPDATE_FAIL: null,
  GET_LOCATION_COUNT: null,
  SET_LOCATION: null,
  GET_POLICY_PREFERENCE_LIST_BY_LOCATION_NAME_SUCCESS: null,
  GET_POLICY_PREFERENCE_LIST_BY_LOCATION_NAME_FAIL: null
});