
import keyMirror from "keymirror";

export default keyMirror({
  INIT_NEW_PHYSICIAN_ROOM: null,
  CREATE_NEW_PHYSICIAN_ROOM_INIT: null,
  CREATE_NEW_PHYSICIAN_ROOM_SUCCESS: null,
  CREATE_NEW_PHYSICIAN_ROOM_FAIL: null,
  GET_PHYSICIAN_ROOM_LIST_INIT: null,
  GET_PHYSICIAN_ROOM_LIST_SUCCESS: null,
  GET_PHYSICIAN_ROOM_LIST_FAIL: null,
  GET_PHYSICIAN_ROOM_BY_ID_INIT: null,
  GET_PHYSICIAN_ROOM_BY_ID_SUCCESS: null,
  GET_PHYSICIAN_ROOM_BY_ID_FAIL: null,
  GET_PHYSICIAN_LIST_SUCCESS: null,

  PHYSICIAN_ROOM_UPDATE_INIT: null,
  PHYSICIAN_ROOM_UPDATE_SUCCESS: null,
  PHYSICIAN_ROOM_UPDATE_FAIL: null,
  GET_PHYSICIAN_ROOM_COUNT: null,
  SET_PHYSICIAN_ROOM: null,
});