import Proxy from "./Proxy";

class RestaurantProxy extends Proxy {
  /**
   * Constructor for Auth proxy
   */
  constructor() {
    super(`/restaurants`);
  }

  public updateRestaurant(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }

  public getRestaurants(data: any) {
    return this.getList(data);
  }

  public createRestaurant(data: any) {
    return this.submit("post", this.endpoint, undefined, data);
  }

  public getListCount(data: any) {
    return this.submit("get", `restaurants/count`, {where: JSON.stringify(data)});
  }

}

export default RestaurantProxy;
