var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.label,"rules":_vm.rules,"vid":_vm.vidRef},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-group',{staticClass:"my-2",attrs:{"label":_vm.label,"description":_vm.description}},[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.label && _vm.rules && _vm.rules.includes('required'))?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.toolTipContent)?_c('span',[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"src":require("@/assets/icons/info-icon.svg"),"title":_vm.toolTipContent}})]):_vm._e(),(_vm.checkboxContent)?_c('span',{staticClass:"float-right checkbox-content"},[_c('b-form-checkbox',{staticClass:"d-inline-block",attrs:{"value":"checkBoxValue"},on:{"change":_vm.onChangeCheckbox}},[_c('span',{staticClass:"pt-1 d-inline-block"},[_vm._v(_vm._s(_vm.checkboxContent))])])],1):_vm._e()]),(!_vm.mask)?_c('b-form-input',{class:{ error: errors[0], error: !valid && _vm.isSubmitted },attrs:{"type":_vm.inputType,"formatter":_vm.formatter,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"change":_vm.onChangeInput,"keyup":_vm.onChangeInputAtKeyUp,"keydown":function($event){return _vm.nameKeydown($event)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(("" + _vm.mask)),expression:"`${mask}`"}],class:{ error: errors[0], error: !valid && _vm.isSubmitted },attrs:{"type":_vm.inputType,"placeholder":_vm.placeholder,"formatter":_vm.formatter,"disabled":_vm.disabled},on:{"change":_vm.onChangeInput,"keyup":_vm.onChangeInputAtKeyUp,"keydown":function($event){return _vm.nameKeydown($event)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_vm._t("append"),(
        _vm.inputType === 'password' &&
          _vm.value &&
          _vm.value.trim() &&
          errors[0] &&
          !_vm.isLogin &&
          !_vm.isSubmitted
      )?_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))]):(
        errors[0] && errors[0].trim() != '' && _vm.isSubmitted && !_vm.isLogin
      )?_c('span',{staticClass:"error-text"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }