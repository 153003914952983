import jwtDecode, { JwtPayload } from "jwt-decode";
import { SHA256, enc } from "crypto-js";
import moment from "moment";

const getTokenExpiryTime = (token: any) => {
  const { exp }: any = jwtDecode<JwtPayload>(token);
  const now = Date.now() / 1000; // exp is represented in seconds
  return exp - now;
};

const jsonToMultiPartConversion = (jsonData: any) => {
  const formData = new FormData();
  for (const key in jsonData) {
    if (Array.isArray(jsonData[key])) {
      formData.append(key, JSON.stringify(jsonData[key]));
    } else {
      formData.append(key, jsonData[key]);
    }
  }
  return formData;
};

const getSecurityToken = () => {
  const { VUE_APP_API_CLIENT_SECRET, VUE_APP_API_EXPIRES_TIME } = process.env;
  const windowsTime = moment()
    .utc()
    .add(VUE_APP_API_EXPIRES_TIME, "milliseconds")
    .unix();
  const tokenBuild =
    windowsTime + "-" + SHA256(window.navigator.userAgent).toString(enc.Hex);
  const tokenGen = SHA256(
    tokenBuild + "_" + VUE_APP_API_CLIENT_SECRET
  ).toString(enc.Hex);
  return tokenBuild + "-" + tokenGen;
};

export {
  getTokenExpiryTime,
  jsonToMultiPartConversion,
  getSecurityToken,
};
