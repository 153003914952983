






































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { deleteRestaurantAlert, favoriteRestaurantAlert, unFavoriteRestaurantAlert } from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import LocationForm from "@/components/locations/LocationForm.vue";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    LocationForm,
  },
})
export default class RestaurantList extends Vue {
  @Action public initNewRestaurant: any;
  @Action public getRestaurants: any;
  @Action public updateRestaurant: any;
  @Action public getRestaurantCount: any;
  @Action public setRestaurant: any;
  @Getter public restaurantInitData: any;
  @Getter public restaurants: any;
  @Getter public restaurantCount: any;
  @Getter public currentUser: any;
  @Getter public rolePerm: any;
  perm = _permissions;
  restaurantFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      and: [
        {
          name: { nilike: "other" },
        },
      ],
      status: { eq: true },
    },
    order:["name ASC"]
  };
  pageOptions = [10, 20];
  currentPage = 1;
  searchText = "";

  beforeMount() {
    this.getRestaurantList();
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.restaurantFilter.where.and = [
        {
          name: { nilike: "other" },
        },
        {
          or: [
            { name: { ilike: `%${this.searchText}%` } },
            { phone: { ilike: `%${this.searchText}%` } },
          ],
        },
      ];
      this.restaurantFilter.skip = 0;
      this.getRestaurantList();
    }
  }

  clearSearch() {
    this.searchText = '';
    this.onChangeFilterInput();
  }

  getRestaurantList() {
    this.getRestaurants(this.restaurantFilter);
    this.getRestaurantCount(this.restaurantFilter.where);
  }

  addRestaurant() {
    this.$router.push({ path: `/restaurants/add` });
  }

  editRestaurant(id: number): void {
    this.$router.push({ path: `/restaurants/update/${id}` });
  }

  deleteRestaurant(id: number): void {
    alert.methods.conFirmAlert(
      deleteRestaurantAlert,
      () => {
        this.updateRestaurant({
          data: { id: id, status: false },
          callback: this.getRestaurantList,
        });
        setTimeout(() => {
          this.getRestaurantList();
        }, 1000);
      },
      () => {}
    );
  }

  getImgUrl(restaurant: any) {
    var images = require.context("@/assets/icons/", false, /\.png$/);
    return images(
      "./" + (restaurant.display_order ? "favorite-enabled" : "favorite-disabled") + ".png"
    );
  }

  setFavorite(restaurant: any): void {
    const alertMessage = restaurant.display_order ? unFavoriteRestaurantAlert : favoriteRestaurantAlert;
    alert.methods.conFirmAlert(
      alertMessage,
      () => {
        this.updateRestaurant({
          data: { id: restaurant.id, display_order: restaurant.display_order ? 0 : 1 },
          callBack: this.getRestaurantList,
        });
      },
      () => {}
    );
  }

  pageSizeChanged() {
    this.restaurantFilter.skip = 0;
    this.getRestaurantList();
  }

  onPageChange(currentPage: any) {
    this.restaurantFilter.skip =
      (currentPage - 1) * this.restaurantFilter.limit;
    this.getRestaurantList();
  }
}
