
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {
  [types.GET_TEAM_LIST_SUCCESS](state: State, response: any) {
    state.teams = response;
  },
  [types.GET_TEAM_LIST_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_TEAM_COUNT](state: State, response: any) {
    state.teamCount = response.count;
  },
  [types.GET_TEAM_BY_ID_SUCCESS](state: State, response: any) {
    state.team = response;
  },
  [types.SET_TEAM](state: State, response: any) {
    state.team = response;
  },
  [types.CREATE_NEW_TEAM_SUCCESS](state: State, response: any) {
    state.team = response.data;
  },
  [types.DELETE_TEAM_SUCCESS](state: State) {
    state.team = null;
  },
  [types.DELETE_TEAM_FAIL](state: State, error: any) {
    state.error = error;
  },
} as MutationTree<State>;