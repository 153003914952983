

















































































import { Vue, Prop, Component, Emit, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";

@Component({
  name: "FormInput",
  directives: { mask }
})
export default class FormInput extends Vue {
  @Prop({ default: "" }) private rules!: string;
  @Prop() private inputValue!: string;
  @Prop() private label!: string;
  @Prop() private inputType!: string;
  @Prop({ default: "" }) private placeholder!: string;
  @Prop() isSubmitted!: boolean;
  value: any = this.inputValue ? this.inputValue : '';
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isLogin!: boolean;
  @Prop({ default: "" }) private vidRef!: string;
  @Prop({ default: "" }) private toolTipContent!: string;
  @Prop({ default: "" }) checkboxContent!: any;
  @Prop({ default: "" }) mask!: any;
  @Prop({ default: "" }) private description!: string;
  @Prop({default: 0}) public maxCharacterRestriction!: number;
  @Prop({ default: false }) public blockSpecialCharacters!: boolean;
  checkBoxValue = false;

  @Emit("getInputValue")
  public onChangeInput() {
    return this.value;
  }

  @Emit("getKeyupInput")
  public onChangeInputAtKeyUp() {
    return this.value;
  }

  @Emit("getCheckboxValue")
  public onChangeCheckbox() {
    this.checkBoxValue = !this.checkBoxValue;
    return this.checkBoxValue;
  }

  formatter(value: any) {
    if(this.maxCharacterRestriction > 0) {
      if(value.length <= this.maxCharacterRestriction) {
        return value;
      } else {
        return value.toString().slice(0,this.maxCharacterRestriction);
      }
    } else {
      return value;
    }
  }

  nameKeydown(e: any) {
    let regularExpression = /[^a-zA-Z0-9@-]/;
    if(this.blockSpecialCharacters && e.key !== ' ' && regularExpression.test(e.key)) {
      e.preventDefault();
    }
  }

  @Watch("inputValue")
  onInputValueChange(value: any) {
    if (value !== this.value && this.checkboxContent) {
      this.value = value;
    }
  }
}
