export class State {
    public newsAnnouncement = {};
    public error = '';
    public newsAnnouncementInit = {
        title: "",
        description: "",
        start_date: null,
        end_date: null,
        status: 0,
        type: "",
        url: ""
    };
    public newsAnnouncements = [];
    public newsAnnouncementCount = 0;
}