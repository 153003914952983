export class State {
  public error = '';
  public physicianUserInitData = {
    prefix: "",
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    userTypesId: 5,
    location: "",
    zipcode: "",
    contact_no: "",
    npi_no: 0,
    department_name: "",
    status: true,
    visibility: true,
    required_email_notification: false,
    availability: [],
    usertypes: false,
  };
  public physician = {};
  public physicians = [];
  public physicianCount = 0;
}