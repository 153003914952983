




























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import constants from "@/constants/_message";
import CancelMeetingModal from "@/components/cancelMeetingModal/CancelMeetingModal.vue";
import {
  approveEmailAlert,
  rejectEmaillAlert,
} from "@/utils/notificationContent";
import MeetingCard from "@/components/meetingCard/MeetingCard.vue";
import moment from "moment";
import DateFilter from "@/components/dateFilter/index.vue";
import appConstants from "@/constants/app.properties";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import LetterIcon from "@/components/letterIcon/LetterIcon.vue";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    CancelMeetingModal,
    MeetingCard,
    AppModal,
    LetterIcon,
    DateFilter,
  },
})
export default class MeetingApprovalList extends Vue {
  @Action public getPendingUsers: any;
  @Getter public pendingUsers: any;
  @Action public bulkUpdate: any;
  @Getter public currentUser: any;
  @Action public downloadVaccinationCertification: any;
  @Action public updateTransferMeetingRequest: any;
  @Action public getMeetingList: any;
  @Getter public meetings: any;
  @Action public getLocationDropdown: any;
  @Getter public locations: any;
  @Getter public isCurrentTenantBlocked: any;
  @Getter public permissions!: any;
  @Getter public role!: any;
  @Getter public rolePerm: any;
  perm = _permissions;

  meetingList: any = [];
  selected: any = [];
  allSelected = false;
  skip = 0;
  searchText = "";
  activeTabName = "UNAPPROVED_USERS";
  $refs!: {
    appModalRef: HTMLFormElement;
    cancelMeetingModalRef: HTMLFormElement;
  };
  searchValue = "";
  searchLocation: any = "";
  fromDate = "";
  toDate = "";
  attendeeList: any = [];
  meetingToBeCancelled: any = {};

  beforeMount() {
    if(this.currentUser.tenant && !this.currentUser.tenant.tenantpreferences.required_fields.inter_company_transfer) {
      this.$router.push({ path: '/'});
    }
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    this.searchLocation =
      Number(localStorage.getItem(appConstants.LOCATION_ID)) || null;
    let startTime = `${moment()
      .startOf("day")
      .format("YYYY-MM-DD")}T00:00:00.000Z`;
    let endTime = `${moment()
      .add(10, "days")
      .format("YYYY-MM-DD")}T23:59:59.999Z`;
    this.fromDate = startTime;
    this.toDate = endTime;
    this.getMeetings();
  }

  @Watch('currentUser')
  onCurrentUser() {
    if(this.currentUser.tenant && !this.currentUser.tenant.tenantpreferences.required_fields.inter_company_transfer) {
      this.$router.push({ path: '/'});
    }
  }

  scroll() {
    const listElm = document.querySelector("#meeting-card");
    if (
      listElm &&
      listElm.childElementCount >= 20 &&
      listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight
    ) {
      this.skip = this.skip + 20;
      this.getMeetings();
    }
  }

  @Watch("meetings")
  onMeetingsUpdated() {
    if (this.skip > 0) {
      this.meetingList = this.meetingList.concat(this.meetings);
    } else {
      this.meetingList = [...this.meetings];
    }
  }

  getDuration(duration: any) {
    const d = Number(duration);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    let hD = (h > 0 ? h : 0) + "h ";
    let mD = (m > 0 ? m : 0) + "m";

    return hD + mD;
  }

  dateFilterChanged(value: any) {
    this.meetingList = [];
    this.skip = 0;
    this.dateChanged(value);
  }

  refreshMeetingList() {
    this.meetingList = [];
    this.skip = 0;
    this.getMeetings();
  }

  dateChanged(value: any) {
    let date = value ? moment(value, "YYYY-MM-DD") : moment();
    this.fromDate = `${date.startOf("day").format("YYYY-MM-DD")}T00:00:00.000Z`;
    this.getMeetingList(this.getMeetingFilterObj());
  }

  getMeetings() {
    this.getMeetingList(this.getMeetingFilterObj());
  }

  getMeetingFilterObj(): URLSearchParams {
    const meetingParams = new URLSearchParams();
    meetingParams.set("limit", "20")
    meetingParams.set("skip", this.skip.toString())
    meetingParams.set("after", this.fromDate)
    meetingParams.set("status", "BOOKED")
    meetingParams.set("transferStatus", "PENDING")
    if (this.searchText) {
      meetingParams.set("searchText", this.searchText)
    }
    if (this.searchLocation && this.searchLocation != "All") {
      meetingParams.set("locationId", this.searchLocation);
    }
    if (( this.currentUser && this.currentUser.is_medical_rep) ||
        localStorage.getItem(appConstants.IS_MEDICAL_REP)) {
      const userId = localStorage.getItem(appConstants.USER_ID);
      if (userId) {
        meetingParams.set("organizerIds", userId);
      }
    }
    return meetingParams;
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.searchValue = this.searchText;
      this.skip = 0;
      this.meetingList = [];
      this.getMeetings();
    }
  }

  setLocation(value: any): any {
    this.searchLocation = value;
    this.skip = 0;
    this.meetingList = [];
    this.getMeetings();
  }

  openAttendeeListModal(list: any): void {
    this.attendeeList = list ? list : [];
    const appModal = this.$refs.appModalRef;
    appModal.show();
  }

  toggleOption(checked: any) {
    this.selected = checked
      ? this.meetingList &&
        this.meetingList.map((option: any) => {
          return option;
        })
      : [];
  }

  refresh() {
    this.getMeetings();
  }

  onApprove() {
    if (this.selected && this.selected.length > 0) {
      const msg = approveEmailAlert.message.replace(
        "approve of these emails",
        `approve ${this.selected.length} of the meetings`
      );
      const selectedIds = this.selected.map((selectedUser: any) => {
        return selectedUser.id;
      });
      alert.methods.conFirmAlert(
        {...approveEmailAlert, message: msg},
        () => {
          const data = {
            meetingIds: selectedIds,
            transfer_status: "approved",
          };
          this.updateTransferMeetingRequest({data: data, callback: () => {
            this.getMeetings();
            this.selected = [];
          }});
        },
        () => {}
      );
    } else {
      alert.methods.appToaster({
        message: constants.PLEASE_SELECT_USER_TO_PROCEED,
        type: "WARNING",
      });
    }
  }

  onReject() {
    if (this.selected && this.selected.length > 0) {
      this.meetingToBeCancelled.id = 0;
      const cancelMeetingModal = this.$refs.cancelMeetingModalRef;
      cancelMeetingModal.show();
    } else {
      alert.methods.appToaster({
        message: constants.PLEASE_SELECT_USER_TO_PROCEED,
        type: "WARNING",
      });
    }
  }

  onCancelMeeting(value: any) {
    const msg = rejectEmaillAlert.message.replace(
        "reject of these emails",
        `reject ${this.selected.length} of the meetings`
      );
      const selectedIds = this.selected.map((selectedUser: any) => {
        return selectedUser.id;
      });
      alert.methods.conFirmAlert(
        { ...rejectEmaillAlert, message: msg },
        () => {
          const data = {
            meetingIds: selectedIds,
            transfer_status: "rejected",
            reason: value.reason
          };
          this.updateTransferMeetingRequest({data: data, callback: () => {
            this.getMeetings();
            this.selected = [];
          }});
        },
        () => {}
      );
  }

  onClear() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  @Watch("selected")
  onSelected(newValue: any): void {
    if (newValue.length === this.meetingList.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }
}
