
































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ValidationObserver } from "vee-validate";
import constants from "@/constants/app.properties";
import ProfileForm from "@/views/registerForm/RegisterForm.vue";
import PhysicianProfile from "@/views/physicians/PhysicianProfile.vue"

@Component({ components: { ProfileForm, PhysicianProfile } })
export default class preference extends Vue {
  @Action private getUserById: any;
  @Action public initRegisterUser: any;
  @Action private updatePreference: any;
  @Getter public currentUser!: any;
  @Getter public user!: any;

  isSubmitted = false;
  isDisabled = true;
  $refs!: {
    preferenceForm: InstanceType<typeof ValidationObserver>;
  };

  beforeMount() {
    this.initRegisterUser();
    const userId = localStorage.getItem(constants.USER_ID);
    if(!this.isMedicalRep && this.currentUser.userTypesId !== 5){
      this.getUserById({
        id: userId, filter: {fields: ['id','first_name', 'last_name', 'email']}
    });
    }    
  }

  editPreference(): void {
    this.isSubmitted = true;
    this.$refs.preferenceForm.validate().then((success: any) => {
      if (!success) {
        return;
      } else {
        const { id, first_name, last_name, email } = this.user;
        this.updatePreference({ id, first_name, last_name, email });
      }
    });
  }

  onCancel(): void {
    this.$router.push({ name: "meeting.list" }).catch();
  }

  get isMedicalRep() {
    return localStorage.getItem(constants.IS_MEDICAL_REP);
  }
}
