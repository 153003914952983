
import keyMirror from "keymirror";

export default keyMirror({
  INIT_NEW_PHYSICIAN_USER: null,
  CREATE_NEW_PHYSICIAN_USER_INIT: null,
  CREATE_NEW_PHYSICIAN_USER_SUCCESS: null,
  CREATE_NEW_PHYSICIAN_USER_FAIL: null,
  GET_PHYSICIAN_USER_LIST_INIT: null,
  GET_PHYSICIAN_USER_LIST_SUCCESS: null,
  GET_PHYSICIAN_USER_LIST_FAIL: null,
  GET_PHYSICIAN_USER_BY_ID_INIT: null,
  GET_PHYSICIAN_USER_BY_ID_SUCCESS: null,
  GET_PHYSICIAN_USER_BY_ID_FAIL: null,
  GET_PHYSICIAN_LIST_SUCCESS: null,

  PHYSICIAN_USER_UPDATE_INIT: null,
  PHYSICIAN_USER_UPDATE_SUCCESS: null,
  PHYSICIAN_USER_UPDATE_FAIL: null,
  GET_PHYSICIAN_USER_COUNT: null,
  SET_PHYSICIAN_USER: null,
});