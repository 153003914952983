import MeetingRuleProxy from "@/proxies/MeetingRuleProxy";
import types from "./mutation-types";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";


const createMeetingRule = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new MeetingRuleProxy().create(data).then((response: any) => {
    commit(types.CREATE_NEW_MEETING_RULE_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.MEETING_RULE_CREATE_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.GET_MEETING_RULE_LIST_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getMeetingRuleById = ({ commit }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  new MeetingRuleProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_MEETING_RULE_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_MEETING_RULE_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getMeetingRules = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new MeetingRuleProxy().getMeetingRules(data).then((response: any) => {
    commit(types.GET_MEETING_RULE_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.GET_MEETING_RULE_LIST_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};


const getMeetingRuleCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new MeetingRuleProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_MEETING_RULE_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_MEETING_RULE_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const setMeetingRule = ({ commit }: any, meetingRule: any) => {
  commit(types.SET_MEETING_RULE, meetingRule);
};

const updateMeetingRule = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new MeetingRuleProxy().updateMeetingRule(data).then(() => {
    commit(loadingTypes.END_LOADING);
    if (!('status' in data)) {
      alert.methods.appToaster({ message: msgConstants.MEETING_RULE_UPDATE_SUCCESS, type: "SUCCESS" });
    } else {
      alert.methods.appToaster({ message: msgConstants.MEETING_RULE_DELETE_SUCCESS, type: "SUCCESS" });
    }
    callback();
  }).catch((error: any) => {
    commit(types.GET_MEETING_RULE_LIST_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const deleteMeetingRule = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new MeetingRuleProxy().delete(data).then(() => {
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.MEETING_RULE_DELETE_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.GET_MEETING_RULE_LIST_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};



export default {
  getMeetingRules,
  getMeetingRuleById,
  getMeetingRuleCount,
  setMeetingRule,
  createMeetingRule,
  updateMeetingRule,
  deleteMeetingRule,
};