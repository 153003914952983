




























































































































import { Vue, Prop, Component, Emit, Watch } from "vue-property-decorator";
import LetterIcon from "@/components/letterIcon/LetterIcon.vue";
import _ from "lodash";

@Component({
  components: {
    LetterIcon,
  },
})
export default class MultipleSelect extends Vue {
  @Prop() options: any;
  @Prop() inputValue: any;
  @Prop({ default: "name" }) textField!: string | string[];
  @Prop({ default: "id" }) valueField!: string;
  @Prop() private label!: string;
  @Prop() private rules!: string;
  @Prop() public optionsLabel!: string;
  @Prop() public valueLabel!: string;
  @Prop({ default: "Add" }) public addButtonLabel!: string;
  @Prop({ default: "Remove" }) public removeButtonLabel!: string;

  values: any;
  unselect = "<<";
  optionsToBeShown: any[] = [];
  valuesToBeShown: any[] = [];
  optionsSelected: any[] = [];
  valuesSelected: any[] = [];
  allValuesSelected = false;
  allOptionsSelected = false;

  beforeMount() {
    this.values = this.inputValue;
    this.optionsToBeShown = this.options;
    this.valuesToBeShown = this.inputValue;
  }

  // isPresentInValues(value: any) {
  //   if(this.options.length) {
  //     return this.options.find((val: any) => {
  //       return value[this.valueField] === val[this.valueField];
  //     });
  //   }
  // }

  optionsSearched(value: any) {
    if (value.length > 0) {
      this.optionsToBeShown = this.options.filter((item: any) => {
        return value
          .toLowerCase()
          .split(" ")
          .every((v) => item.name.toLowerCase().includes(v));
      });
    } else {
      this.optionsToBeShown = this.options;
    }
  }

  valuesSearched(value: any) {
    if (value.length > 0) {
      this.valuesToBeShown = this.values.filter((item: any) => {
        return value
          .toLowerCase()
          .split(" ")
          .every((v) => item.name.toLowerCase().includes(v));
      });
    } else {
      this.valuesToBeShown = this.inputValue;
    }
  }

  moveToOptions() {
    this.optionsToBeShown = [...this.valuesSelected,...this.optionsToBeShown];
    const ids = this.optionsToBeShown.map(o => o.id);
    const filtered = this.optionsToBeShown.filter(({id}, index) => !ids.includes(id, index + 1));
    this.optionsToBeShown = _.orderBy(filtered);
    this.valuesSelected.forEach((val: any) => {
      this.valuesToBeShown.forEach((value: any) => {
        if (value[this.valueField] === val[this.valueField]) {
          this.valuesToBeShown.splice(this.valuesToBeShown.indexOf(value), 1);
        }
      });
    });
    this.values = this.valuesToBeShown;
    this.valuesSelected = [];
    this.allValuesSelected = false;
    this.allOptionsSelected = false;
    this.getInputValue();
  }

  moveToValues() {
    this.valuesToBeShown = [...this.optionsSelected,...this.valuesToBeShown];
    const ids = this.valuesToBeShown.map(o => o.id);
    const filtered = this.valuesToBeShown.filter(({id}, index) => !ids.includes(id, index + 1));
    this.valuesToBeShown = _.orderBy(filtered);
    this.optionsSelected.forEach((val: any) => {
      this.optionsToBeShown.forEach((value: any) => {
        if (value[this.valueField] === val[this.valueField]) {
          this.optionsToBeShown.splice(this.optionsToBeShown.indexOf(value), 1);
        }
      });
    });
    this.optionsSelected = [];
    this.values = this.valuesToBeShown;
    this.allOptionsSelected = false;
    this.allValuesSelected = false;
    this.getInputValue();
  }

  @Emit("getInputValue")
  getInputValue() {
    return this.optionsToBeShown;
  }

  toggleOption(checked: any) {
    this.optionsSelected = checked
      ? this.optionsToBeShown &&
        this.optionsToBeShown.map((option: any) => {
          return option;
        })
      : [];
  }

  toggleValues(checked: any) {
    this.valuesSelected = checked
      ? this.valuesToBeShown &&
        this.valuesToBeShown.map((value: any) => {
          return value;
        })
      : [];
  }

  @Watch('valuesSelected')
  valuesSelectedChanged(newValue: any) {
    if (newValue.length > 0 && newValue.length === this.valuesToBeShown.length) {
      this.allValuesSelected = true;
    } else {
      this.allValuesSelected = false;
    }
  }

  @Watch('optionsSelected')
  optionsSelectedChanged(newValue: any) {
    if (newValue.length > 0 && newValue.length === this.optionsToBeShown.length) {
      this.allOptionsSelected = true;
    } else {
      this.allOptionsSelected = false;
    }
  }
}
