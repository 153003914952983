/* ============
 * Actions for the Messages module
 * ============
 *
 * The actions that are available on the
 * Messages module.
 */
import types from "./mutation-types";
import MessageParticipantsProxy from "@/proxies/MessageParticipantsProxy";
import MessagesProxy from "@/proxies/MessagesProxy";
import loadingTypes from "@/store/mutation-types";

const getMessageParticipants = ({ commit }: any, filter: any): void => {
  // commit(loadingTypes.START_LOADING);
  new MessageParticipantsProxy()
    .getMessageParticipants(filter)
    .then((response: any) => {
      commit(types.FETCH_MESSAGE_PARTICPANTS_LIST_SUCCESS, response);
      // commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.FETCH_MESSAGE_PARTICPANTS_LIST_FAIL, error.error);
      // commit(loadingTypes.END_LOADING);
    })
};


const getUnreadTotalCount = ({ commit }: any): void => {
  // commit(loadingTypes.START_LOADING);
  new MessagesProxy()
    .getUnreadTotalCount()
    .then((response: any) => {
      commit(types.FETCH_UNREAD_TOTAL_COUNT_SUCCESS, response);
      // commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.FETCH_UNREAD_TOTAL_COUNT_FAIL, error.error);
      // commit(loadingTypes.END_LOADING);
    })
};

const getMessages = ({ commit }: any, filter: any): void => {
  // commit(loadingTypes.START_LOADING);
  new MessagesProxy()
    .getMessages(filter)
    .then((response: any) => {
      commit(types.FETCH_MESSAGES_LIST_SUCCESS, response);
      // commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.FETCH_MESSAGES_LIST_FAIL, error.error);
      // commit(loadingTypes.END_LOADING);
    })
};

const createMessage = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new MessagesProxy()
    .createMessage(data)
    .then((response: any) => {
      commit(types.CREATE_MESSAGE_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      callback(response);
    })
    .catch((error: any) => {
      commit(types.CREATE_MESSAGE_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    });
};

const createMessageParticpants = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new MessageParticipantsProxy()
    .createMessageParticpants(data)
    .then((response: any) => {
      commit(types.CREATE_MESSAGE_PARTICIPANTS_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      callback(response);
    })
    .catch((error: any) => {
      commit(types.CREATE_MESSAGE_PARTICIPANTS_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    });
};

const setRepName = ({ commit }: any, data: any): void => {
  commit(types.SET_REP_NAME, data);
}


export default {
  getMessageParticipants,
  getMessages,
  createMessage,
  createMessageParticpants,
  getUnreadTotalCount,
  setRepName
};