
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

  [types.INIT_NEW_HOLIDAY](state: State) {
    state.holiday = state.holidayInitData;
  },
  [types.CREATE_NEW_HOLIDAY_SUCCESS](state: State, response: any) {
    state.holiday = response.data;
  },
  [types.GET_HOLIDAY_LIST_SUCCESS](state: State, response: any) {
    state.holidays = response;
  },
  [types.CREATE_NEW_HOLIDAY_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_HOLIDAY_BY_ID_SUCCESS](state: State, response: any) {
    state.holiday = response;
  },
  [types.GET_HOLIDAY_BY_ID_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.HOLIDAY_UPDATE_SUCCESS](state: State, response: any) {
    state.holiday = response;
  },
  [types.HOLIDAY_UPDATE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_HOLIDAY_COUNT](state: State, response: any) {
    state.holidayCount = response.count;
  },
  [types.SET_HOLIDAY](state: State, holiday: any) {
    state.holiday = holiday;
  },
  [types.DELETE_HOLIDAY_INIT](state: State) {
    state.error = '';
  },
  [types.DELETE_HOLIDAY_SUCCESS](state: State) {
    state.holiday = {};
  },
  [types.DELETE_HOLIDAY_FAIL](state: State, error: any) {
    state.error = error;
  }
} as MutationTree<State>;