export class State {
  public error = '';
  public locationTimeslotInitData = {
    locationId: 0,
    appointmentTypeId: 0,
    start_time: "",
    end_time: "",
    appointment_label: ""
  };
  public locationTimeslot = {};
  public tenantLocationTimeslots = [];
  public locationTimeslotCount = 0;
}