import moment from "moment";

/**
 * getDayString formats given date into day string
 * @param date 
 */
const getDayString = (date: any) => {
  const diff = moment(date)
    .startOf("date")
    .diff(moment().startOf("date"), "days");
  let day = "";
  switch (diff) {
    case -1:
      day = "Yesterday";
      break;
    case 0:
      day = "Today";
      break;
    case 1:
      day = "Tomorrow";
      break;
    default:
      day = moment(date).format("dddd");
  }
  return day;
}

const isSameDate = (startDate: any, endDate: any) => {
  return moment(startDate)
    .startOf("date")
    .isSame(moment(endDate).startOf("date"));
}

const isBefore = (startDate: any, endDate?: any) => {
  return moment(startDate).isBefore(endDate ? moment(endDate) : moment());
}

const formatDate = (date: any, format?: any) => {
  return moment(date).format(format)
}

/**
 * getDateObject used to add time and date for start date and end date
 * @param obj contains startDate, startTime, endDate & endTime
 */
const getDateObject = (obj: any) => {
  const startHour = moment(obj.startTime).hours();
  const startMinutes = moment(obj.startTime).minutes();
  const endHour = moment(obj.endTime).hours();
  const endMinutes = moment(obj.endTime).minutes();
  const dateObject = {
    startDate: moment(obj.startDate).startOf("date")
      .add(startHour, "hours")
      .add(startMinutes, "minute")
      .toISOString(),
    endDate: moment(obj.endDate).startOf("date")
      .add(endHour, "hours")
      .add(endMinutes, "minute")
      .toISOString()
  }
  return dateObject;
}


export { getDayString, isSameDate, isBefore, formatDate, getDateObject };
