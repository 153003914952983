







































































import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Variables from "@/constants/_variables";
import { ValidationObserver } from "vee-validate";
import constants from "@/constants/app.properties";
import alert from "@/mixins/alert";
import { resetPasswordAlertContent } from "@/utils/notificationContent";

@Component
export default class resetpassword extends Vue {
  @Action private passwordReset: any;
  @Action private logout: any;

  $refs!: {
    passwordResetForm: InstanceType<typeof ValidationObserver>;
  };

  isFirstTimeUser: any;

  public userObj: any = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  beforeMount() {
    this.isFirstTimeUser = localStorage.getItem(constants.IS_FIRST_TIME_USER);
  }

  private passwordVisibility: any = {
    oldPassword: {
        type: Variables.PASSWORD_TYPE.password,
        icon: Variables.PASSWORD_ICON.show,
        title: "Show"
      },
      newPassword: {
        type: Variables.PASSWORD_TYPE.password,
        icon: Variables.PASSWORD_ICON.show,
        title: "Show"
      },
      confirmPassword: {
        type: Variables.PASSWORD_TYPE.password,
        icon: Variables.PASSWORD_ICON.show,
        title: "Show"
      },
  };

  isSubmitted = false;

  onPasswordReset(): void {
    this.isSubmitted = true;
    this.$refs.passwordResetForm.validate()
      .then((success: any) => {
        if (!success) {
          return;
        } else {
          delete this.userObj.confirmPassword;
          this.passwordReset({data: this.userObj, callback: this.passwordResetAlert});
        }
      });
  }

  private togglePasswordVisibility(field: any) {
    if (this.passwordVisibility[field].type === Variables.PASSWORD_TYPE.text) {
      this.passwordVisibility[field].type = Variables.PASSWORD_TYPE.password;
      this.passwordVisibility[field].icon = Variables.PASSWORD_ICON.show;
      this.passwordVisibility[field].title = "Show";
    } else {
      this.passwordVisibility[field].type = Variables.PASSWORD_TYPE.text;
      this.passwordVisibility[field].icon = Variables.PASSWORD_ICON.hide;
      this.passwordVisibility[field].title = "Hide";
    }
  }

  passwordResetAlert(): any {
    alert.methods.conFirmAlert(
      resetPasswordAlertContent,
      () => {
        this.logout();
      },
      () => {
         this.logout();
      }
    );
  }

  onCancel(): void {
    this.$router.push({ name: "meeting.list" }).catch();
  }

}
