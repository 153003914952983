/* ============
 * Actions for the Meeting module
 * ============
 *
 * The actions that are available on the
 * Meeting module.
 */
import types from "./mutation-types";
import MeetingProxy from "@/proxies/MeetingProxy";
import router from "@/plugins/vue-router";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";
import loadingTypes from "@/store/mutation-types";
import { jsonToMultiPartConversion } from "@/utils/common";
import moment from "moment";

const multiPartHeader = {
  "Content-Type": "multipart/form-data"
};

const createMeeting = ({ commit }: any, data: any) => {
  commit(types.MEETING_CREATE_INIT);
  commit(loadingTypes.START_LOADING);
  if (!data.file) {
    delete data.file;
  }
  new MeetingProxy()
    .create(jsonToMultiPartConversion(data), multiPartHeader)
    .then((response: any) => {
      commit(types.MEETING_CREATE_SUCCESS, response);
      commit(types.SET_ACTIVE_TAB, data.status);
      if (data && data.status) {
        alert.methods.appToaster({ message: msgConstants.MEETING_CREATED_WITH_PUBLISH_SUCCESS, type: "SUCCESS" });
      } else {
        alert.methods.appToaster({ message: msgConstants.MEETING_CREATED_SUCCESS, type: "SUCCESS" });
      }
      router.push({
        name: "meeting.list"
      }).catch();
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.MEETING_CREATE_FAIL, error);
      commit(loadingTypes.END_LOADING);
      if (error.error.message.includes("User does not exist:")) {
        alert.methods.appToaster({ message: msgConstants.MEETING_CREATED_FAIL_FOR_NON_ACTIVE_USER, type: "ERROR" });
      } else {
        alert.methods.appToaster({ message: msgConstants.MEETING_CREATED_FAIL, type: "ERROR" });
      }
    });
};

const updateMeeting = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.MEETING_UPDATE_INIT);
  if (!data.file) {
    delete data.file;
  }
  new MeetingProxy()
    .updateMultiPartForm(jsonToMultiPartConversion(data), multiPartHeader)
    .then((response: any) => {
      commit(types.MEETING_UPDATE_SUCCESS, response);
      commit(types.SET_ACTIVE_TAB, data.status)
      if (data && !data.isEdit) {
        alert.methods.appToaster({ message: msgConstants.MEETING_UPDATED_WITH_PUBLISH_SUCCESS, type: "SUCCESS" });
      } else {
        alert.methods.appToaster({ message: msgConstants.MEETING_UPDATED_SUCCESS, type: "SUCCESS" });
      }
      callback();
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.MEETING_UPDATE_FAIL, error);
      if (error.error.message.includes("User does not exist:")) {
        alert.methods.appToaster({ message: msgConstants.MEETING_CREATED_FAIL_FOR_NON_ACTIVE_USER, type: "ERROR" });
      } else if (error.error.message.includes("As per meeting rules, your booking limit is exceeded.")) {
        alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      } else if (error.error.message.includes("You don't have a subscribed plan. Kindly subscribe.")) {
        alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      } else if(error.error.message.includes("This appointment has already been booked by someone else.")){        
        alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
        router.push({
          name: "book-meeting.list"
        }).catch();
      } else {
        alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      }
      commit(loadingTypes.END_LOADING);
    });
};

const publishMeetingById = ({ commit }: any, { id, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new MeetingProxy()
    .publishMeetingById(id)
    .then((response: any) => {
      commit(types.PUBLISH_MEETING_SUCCESS, response);
      callback();
      alert.methods.appToaster({ message: msgConstants.MEETING_UPDATED_WITH_PUBLISH_SUCCESS, type: "SUCCESS" });
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.PUBLISH_MEETING_FAIL, error);
      if (error.error.message.includes("User does not exist:")) {
        alert.methods.appToaster({ message: msgConstants.MEETING_CREATED_FAIL_FOR_NON_ACTIVE_USER, type: "ERROR" });
      } else {
        alert.methods.appToaster({ message: msgConstants.MEETING_UPDATED_FAIL, type: "ERROR" });
      }
      commit(loadingTypes.END_LOADING);
    });
};

const confirmMeetingById = ({ commit }: any, { id, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new MeetingProxy()
    .confirmMeetingById(id)
    .then((response: any) => {
      commit(types.PUBLISH_MEETING_SUCCESS, response);
      callback();
      alert.methods.appToaster({ message: msgConstants.MEETING_UPDATED_WITH_CONFIRM_SUCCESS, type: "SUCCESS" });
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.PUBLISH_MEETING_FAIL, error);
      if (error.error.message.includes("User does not exist:")) {
        alert.methods.appToaster({ message: msgConstants.MEETING_CREATED_FAIL_FOR_NON_ACTIVE_USER, type: "ERROR" });
      } else {
        alert.methods.appToaster({ message: msgConstants.MEETING_UPDATED_FAIL, type: "ERROR" });
      }
      commit(loadingTypes.END_LOADING);
    });
};

const getMeeting = ({ commit, dispatch, rootState }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  commit(types.GET_MEETING_INIT);
  new MeetingProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.HIDE_RECOMMENDATION_MODAL);
      const state = rootState.auth;
      const permissions = state.rolePermissions;
      // This should be physician the one who is part of the meetig, but no access to update meeting
      if (response.created_by !== state.currentUser.id && permissions && permissions.Meeting && !permissions.Meeting.update) {
        alert.methods.appToaster({ message: msgConstants.NOT_ACCESS_TO_THIS_PAGE, type: "ERROR" });
        // This should be organizer the one who is part of the meetig, but no access to update meeting
        //} else if (response.created_by !== state.currentUser.id && state.userTypes[state.currentUser.userTypesId] === 'ORGANIZER') {
        // alert.methods.appToaster({ message: msgConstants.NOT_ACCESS_TO_THIS_MEETING, type: "ERROR" });
        // router.push({
        //   name: "meeting.list"
        // }).catch();
      } else {
        if (response.status == 0) {
          response.participants = rootState.meeting.recommendedPhysicians;
        }
        commit(types.GET_MEETING_SUCCESS, response);
        const weekday = moment(response.start_time).day();
        const physicianFilter = {
          fields: {
            id: true,
            first_name: true,
            last_name: true,
            department_name: true,
            location: true,
            prefix: true
          },
          where: {
            // optout_topic_ids: { regexp: `^[^${response.topicsId}]+$` },
            // optout_brand_ids: { regexp: `^[^${response.brandsId}]+$` },
            // optout_category_ids: { regexp: `^[^${response.categoriesId}]+$` },
          },
          include: [
            {
              relation: "physiciansAvailabilities",
              scope: {
                fields: {
                  id: true,
                  physicianId: true,
                  weekday: true,
                  locationId: true,
                  available: true
                },
                where: {
                  and: [{ weekday: weekday === 0 ? 7 : weekday }, { locationId: response.locationId }, { available: { inq: AMPMvalue(response) } }],
                },
                include: [
                  {
                    relation: "location",
                    scope: {
                      fields: { name: true, id: true },
                    },
                  },
                ],
              },
            },
          ],
        };
        dispatch('getPhysicians', physicianFilter)
      }
      commit(loadingTypes.END_LOADING);
    })
    .catch(({ error }: any) => {
      alert.methods.appToaster({ message: error.message, type: "ERROR" });
      router.push({
        name: "meeting.list"
      }).catch();
      commit(types.GET_MEETING_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const AMPMvalue = (response: any) => {
  const starttime = moment(response.start_time).format("a");

  const endTime = moment(response.end_time).format("a");

  if (starttime == 'am' && endTime == 'am') {
    return ['AM/PM', 'AM'];
  } else if (starttime == 'pm' && endTime == 'pm') {
    return ['AM/PM', 'PM'];
  } else {
    return ['AM/PM'];
  }

};

const getAllMeetings = ({ commit }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.FETCH_MEETING_LIST);
  new MeetingProxy()
    .getAll()
    .then((response: any) => {
      commit(types.FETCH_MEETING_LIST_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.FETCH_MEETING_LIST_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getMeetingList = ({ commit }: any, data: any) => {
  commit(types.FETCH_MEETING_LIST);
  commit(loadingTypes.START_LOADING);
  new MeetingProxy()
    .getMeetingList(data)
    .then((response: any) => {
      commit(types.FETCH_MEETING_LIST_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.FETCH_MEETING_LIST_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

export const deleteMeeting = ({ commit }: any, { id, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.DELETE_MEETING_INIT);
  new MeetingProxy().delete(id).then(() => {
    commit(types.DELETE_MEETING_SUCCESS, id);
    commit(loadingTypes.END_LOADING);
    callback();
    alert.methods.appToaster({ message: msgConstants.MEETING_DELETED_SUCCESS, type: "SUCCESS" });
  }).catch((error: any) => {
    commit(types.DELETE_MEETING_FAIL, error);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.MEETING_DELETED_FAIL, type: "ERROR" });
  });
};

const setMeeting = ({ commit }: any, meeting: any) => {
  commit(types.SET_MEETING, meeting);
};

const initMeeting = ({ commit }: any) => {
  commit(types.INIT_MEETING);
};

const setActiveTab = ({ commit }: any, tabStatus: any) => {
  commit(types.SET_ACTIVE_TAB, tabStatus);
};

const getMeetingByGuestUser = ({ commit }: any, id: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.GET_MEETING_INIT);
  new MeetingProxy()
    .getMeetingByGuestUser(id)
    .then((response: any) => {
      commit(types.GET_MEETING_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      if (error.error.message === msgConstants.MEETING_ALREADY_COMPLETED) {
        alert.methods.appToaster({ message: msgConstants.MEETING_ALREADY_COMPLETED, type: "ERROR" });
      }
      if (error.error.name === "NotFoundError") {
        alert.methods.appToaster({ message: msgConstants.MEETING_NOT_FOUND, type: "ERROR" });
        router.push({
          name: "login.index"
        }).catch();
      }
      commit(types.GET_MEETING_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
}



// Actions for Brands, Topics and Categories 
const getBrands = ({ commit }: any, data: any) => {
  commit(types.FETCH_BRANDS_INIT);
  new MeetingProxy()
    .getBrands(data)
    .then((response: any) => {
      commit(types.FETCH_BRANDS_SUCCESS, response);
    })
    .catch((error: any) => {
      commit(types.FETCH_BRANDS_FAIL, error);
    });
};

const getTopics = ({ commit }: any, data: any) => {
  commit(types.FETCH_TOPICS_INIT);
  new MeetingProxy()
    .getTopics(data)
    .then((response: any) => {
      commit(types.FETCH_TOPICS_SUCCESS, response);
    })
    .catch((error: any) => {
      commit(types.FETCH_TOPICS_FAIL, error);
    });
};

const getCategories = ({ commit }: any, data: any) => {
  commit(types.FETCH_CATEGORIES_INIT);
  new MeetingProxy()
    .getCategories(data)
    .then((response: any) => {
      commit(types.FETCH_CATEGORIES_SUCCESS, response);
    })
    .catch((error: any) => {
      commit(types.FETCH_CATEGORIES_FAIL, error);
    });
};

// Actions for Location dropdown
const getLocationDropdown = ({ commit }: any, data: any) => {
  commit(types.FETCH_LOCATIONS_INIT);
  new MeetingProxy()
    .getLocationDropdown(data)
    .then((response: any) => {
      commit(types.FETCH_LOCATIONS_SUCCESS, response);
    })
    .catch((error: any) => {
      commit(types.FETCH_LOCATIONS_FAIL, error);
    });
};

const getTenantLocation = ({ commit }: any, data: any) => {
  commit(types.FETCH_LOCATIONS_INIT);
  new MeetingProxy()
    .getTenantLocation(data)
    .then((response: any) => {
      commit(types.FETCH_LOCATIONS_SUCCESS, response);
    })
    .catch((error: any) => {
      commit(types.FETCH_LOCATIONS_FAIL, error);
    });
};

const getPhysicians = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.FETCH_PHYSICIANS_INIT);
  new MeetingProxy()
    .getPhysicians(data)
    .then((response: any) => {
      commit(types.FETCH_PHYSICIANS_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.FETCH_PHYSICIANS_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};


const transferMeeting = ({ commit }: any, { id, data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.TRANSFER_MEETING_INIT);
  new MeetingProxy()
    .transferMeeting(id, data)
    .then((response: any) => {
      commit(types.TRANSFER_MEETING_SUCCESS, response);
      alert.methods.appToaster({ message: msgConstants.MEETING_TRANSFER_SUCCESS, type: "SUCCESS" });
      commit(loadingTypes.END_LOADING);
      callback();
    })
    .catch((error: any) => {
      commit(types.TRANSFER_MEETING_FAIL, error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const generateMeetings = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.TRANSFER_MEETING_INIT);
  new MeetingProxy()
    .generateMeetings(data)
    .then((response: any) => {
      commit(types.TRANSFER_MEETING_SUCCESS, response);
      alert.methods.appToaster({ message: msgConstants.MEETING_GENERATE_SUCCESS, type: "SUCCESS" });
      commit(loadingTypes.END_LOADING);
      callback();
    })
    .catch((error: any) => {
      commit(types.TRANSFER_MEETING_FAIL, error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    });
};

const setMeetingId = ({ commit }: any, meetingId: any) => {
  commit(types.SET_MEETING_ID, meetingId);
};

const meetingRuleValidation = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.MEETING_RULE_VALID_INIT);
  new MeetingProxy()
    .meetingRuleValidation(data)
    .then((response: any) => {
      commit(types.MEETING_RULE_VALID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      callback(true);
    })
    .catch((error: any) => {
      commit(types.MEETING_RULE_VALID_FAIL, error);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      if(error.error.message.includes("This appointment has already been booked by someone else.")){        
        callback(false, true);      
      }else{
        callback(false);
      }      
      commit(loadingTypes.END_LOADING);
    });
};

const cancelMeetingWithReason = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.DELETE_MEETING_INIT);
  new MeetingProxy().cancelMeeting(data).then(() => {
    commit(types.DELETE_MEETING_SUCCESS, data.id);
    commit(loadingTypes.END_LOADING);
    callback();
    alert.methods.appToaster({ message: msgConstants.MEETING_DELETED_SUCCESS, type: "SUCCESS" });
  }).catch((error: any) => {
    commit(types.DELETE_MEETING_FAIL, error);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.MEETING_DELETED_FAIL, type: "ERROR" });
  });
};

const getRecommendedMeetingList = ({ commit }: any, data: any): void => {
  commit(types.HIDE_RECOMMENDATION_MODAL);
  commit(loadingTypes.START_LOADING);
  new MeetingProxy()
    .getRecommendationList(data)
    .then((response: any) => {
      commit(types.GET_RECOMMENDATION_LIST_SUCCESS, response);
      commit(types.SHOW_RECOMMENDATION_MODAL);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_RECOMMENDATION_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const createTransferMeeting = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new MeetingProxy()
    .approveRequestMeetingTransferRequest(data)
    .then((response: any) => {
      commit(types.APPROVE_REJECT_MEETING_TRANSFER_REQUEST_SUCCESS, response);
      callback();
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(types.APPROVE_REJECT_MEETING_TRANSFER_REQUEST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const updateTransferMeetingRequest = ({ commit }: any, { data, callback }: any): void => {
  commit(loadingTypes.START_LOADING);
  new MeetingProxy()
    .updateTransferMeetingRequest(data)
    .then(() => {
      callback();
      alert.methods.appToaster({ message: `Selected meeting transfer requests ${data.transfer_status} successfully.`, type: 'SUCCESS' })
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    })
};

const hideRecommendationModal = ({ commit }: any): void => {
  commit(types.HIDE_RECOMMENDATION_MODAL);
}

const setRecommendedPhysicians = ({ commit }: any, data: any): void => {
  commit(types.SET_RECOMMENDED_PHYSICIANS, data);
}

const messageMeetingOrganizer = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new MeetingProxy()
    .messageMeetingOrganizer(data)
    .then(() => {
      alert.methods.appToaster({ message: 'Message sent to organizer successfully.', type: "SUCCESS" });
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
      commit(loadingTypes.END_LOADING);
    })
};

const updatePastMeetingById = ({ commit }: any, {data,callback}:any) => {
  commit(loadingTypes.START_LOADING);
  new MeetingProxy()
    .updatePastMeetingById(data)
    .then((response: any) => {
      commit(types.MEETING_UPDATE_SUCCESS, response);
      alert.methods.appToaster({ message: msgConstants.MEETING_UPDATED, type: "SUCCESS" });
      callback();
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.MEETING_UPDATE_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};


export default {
  createMeeting,
  updateMeeting,
  getMeeting,
  getMeetingList,
  getAllMeetings,
  deleteMeeting,
  setMeeting,
  initMeeting,
  publishMeetingById,
  confirmMeetingById,
  getMeetingByGuestUser,
  getBrands,
  getTopics,
  getCategories,
  getLocationDropdown,
  getPhysicians,
  setActiveTab,
  transferMeeting,
  getTenantLocation,
  generateMeetings,
  setMeetingId,
  meetingRuleValidation,
  cancelMeetingWithReason,
  getRecommendedMeetingList,
  setRecommendedPhysicians,
  hideRecommendationModal,
  createTransferMeeting,
  updateTransferMeetingRequest,
  messageMeetingOrganizer,
  updatePastMeetingById
};
