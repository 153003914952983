






























































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class OrderModal extends Vue {
  @Prop({ default: "md" }) public size!: any;
  @Prop() public cost!: number;

  show() {
    this.$bvModal.show("order-modal");
  }

  hide() {
    this.$bvModal.hide("order-modal");
  }

  @Emit('onProceedPayment')
  onPayment() {
    this.hide();
    return true;
  }

  cancelOrder() {
    this.hide();
    return false;
  }
}
