




























import { Vue, Component } from "vue-property-decorator";
import constants from "@/constants/app.properties";

@Component({})
export default class ConfirmationPopup extends Vue {
  defaultTitle = "";
  title = "";
  message = "";
  confirmModalRef = this.$refs.confirmationModalRef as HTMLFormElement;
  primaryBtn = {};
  secondaryBtn = {};
  isDontShowCheck = false;
  isChecked = true;
  onSuccess:any = () => {};
  onFail = () => {};
  mounted() {
    Vue.prototype.$eventBus.$on(
      constants.CONFIRM_ALERT,
      this.handleConfirmation
    );
  }
  destroyed() {
    if (Vue.prototype.$event) {
      Vue.prototype.$event.$off(constants.CONFIRM_ALERT);
    }
  }
  handleConfirmation({ content, onPrimaryCb, onSecondaryCb }: any): void {
    this.onSuccess = onPrimaryCb;
    this.onFail = onSecondaryCb;
    this.title = content.title || this.defaultTitle;
    this.message = content.message;
    this.primaryBtn = content.primaryBtn
    this.secondaryBtn = content.secondaryBtn
    this.isDontShowCheck = content.isDontShowCheck;
    this.showModal();
  }
  onConfirm(): void {
    this.onSuccess(this.isChecked);
    this.hideModal();
  }
  onCancel(): void {
    this.onFail();
    this.hideModal();
  }
  showModal(): void {
    this.$bvModal.show("confirmation-modal");
  }
  hideModal(): void {
    this.$bvModal.hide("confirmation-modal");
  }
  handleConditionalClose(event: any): any {
    if (this.message === "Your password has been changed" 
      && (event.trigger === "backdrop" || event.trigger === "headerclose") ) {
      this.onConfirm();
    }
  }
}
