/* ============
 * Mutation  for the Appointment Type module
 * ============
 *
 * The mutation types that are available
 * on the Appointment Type module.
 */

import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";
import array from '@/utils/array';

export default {
  [types.APPOINTMENT_TYPE_CREATE_INIT](state: State) {
    state.appointmentTypesLoading = true;
  },
  [types.APPOINTMENT_TYPE_CREATE_SUCCESS](state: State) {
    state.appointmentTypesLoading = false;
    state.appointmentType = state.appointmentTypeInitialData;
  },
  [types.APPOINTMENT_TYPE_CREATE_FAIL](state: State, error: any) {
    state.appointmentTypesLoading = false;
    state.error = error;
  },

  [types.APPOINTMENT_TYPE_UPDATE_INIT](state: State) {
    state.appointmentTypesLoading = true;
  },
  [types.APPOINTMENT_TYPE_UPDATE_SUCCESS](state: State) {
    state.appointmentTypesLoading = false;
    state.appointmentType = state.appointmentTypeInitialData;
  },
  [types.APPOINTMENT_TYPE_UPDATE_FAIL](state: State, error: any) {
    state.appointmentTypesLoading = false;
    state.error = error;
  },

  [types.GET_APPOINTMENT_TYPE_INIT](state: State) {
    state.appointmentType = state.appointmentTypeInitialData;
    state.appointmentTypesLoading = true;
  },
  [types.GET_APPOINTMENT_TYPE_SUCCESS](state: State, response: any) {
    state.appointmentTypesLoading = false;
    state.appointmentType = response;
  },
  [types.GET_APPOINTMENT_TYPE_FAIL](state: State, error: any) {
    state.appointmentTypesLoading = false;
    state.appointmentType = state.appointmentTypeInitialData;
    state.error = error;
  },

  [types.FETCH_APPOINTMENT_TYPE_LIST](state: State) {
    state.appointmentTypesLoading = true;
  },
  [types.FETCH_APPOINTMENT_TYPE_LIST_SUCCESS](state: State, response: any) {
    state.appointmentTypesLoading = false;
    state.appointmentTypes = response;
  },
  [types.FETCH_APPOINTMENT_TYPE_LIST_FAIL](state: State, error: any) {
    state.appointmentTypesLoading = false;
    state.error = error;
    state.appointmentTypes = [];
  },
  [types.SET_APPOINTMENT_TYPE](state: State, appointmentType: any) {
    state.appointmentType = appointmentType;
  },

  [types.INIT_APPOINTMENT_TYPE](state: State) {
    state.appointmentType = state.appointmentTypeInitialData;
  },


  [types.GET_APPOINTMENT_TYPES_COUNT_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_APPOINTMENT_TYPES_COUNT_SUCCESS](state: State, appointmentTypesCount: any) {
    state.appointmentTypesCount = appointmentTypesCount.count;
  },
  [types.DELETE_APPOINTMENT_TYPE_INIT](state: State) {
    state.appointmentTypesLoading = true;
  },
  [types.DELETE_APPOINTMENT_TYPE_SUCCESS](state: State, id: any) {
    state.appointmentTypesLoading = false;
    state.appointmentTypes.splice(array.findIndex(state.appointmentTypes, { id }), 1);
  },
  [types.DELETE_APPOINTMENT_TYPE_FAIL](state: State, error: any) {
    state.appointmentTypesLoading = false;
    state.error = error;
  },

  
} as MutationTree<State>;
