export class State {
  public error = '';
  public physicianInitData = {
    physicianId: 0,
    locationId: 0,
    meeting_at: "",
    appointmentTypeId: 0,
    description: "",
    status: true
  };
  public physicianRoom = {};
  public physicianRooms = [];
  public physicianRoomCount = 0;
  public physiciansForDropdown = [];
}