import keyMirror from "keymirror";

export default keyMirror({
  AUTH: null,
  MEETING: null,
  USER: null,
  PROFILE: null,
  TENANTLOCATIONS: null,
  APPOINTMENTTYPES: null,
  POLICIES: null,
  PREFERENCES: null,
  TIME_SLOTS: null,
  TEAMS: null,
  PHYSICIANS: null,
  RESTAURANTS: null,
  NEWSANNOUNCEMENTS: null
});
