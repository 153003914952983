import _ from "lodash";

const isEmpty = (value: any) => {
  return _.isEmpty(value);

};

const isNotEmpty = (value: any) =>  !isEmpty(value);

const clone = (value: any) => {
  if (!value) return value;
  const isObject = typeof value === "object";
  const isArray = Array.isArray(value);
  if (!isArray && !isObject) return value;
  return _.cloneDeep(value);
};

export { isEmpty, clone, isNotEmpty };
