



















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class AppModal extends Vue {
  @Prop() public title!: any;
  @Prop({ default: "md" }) public size!: any;
  @Prop({default: ""}) contentClass!: any;
  @Prop({default: false}) hideHeader!: boolean;
  @Prop({default: false}) public noCloseOnBackdrop!: boolean;
  @Prop({default: false}) public noCloseOnEsc!: boolean;

  show() {
    this.$bvModal.show("app-modal");
  }

  hide() {
    this.$bvModal.hide("app-modal");
  }
}
