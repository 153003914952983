import NewsAnnouncementProxy from "@/proxies/NewsAnnouncementProxy";
import loadingTypes from "@/store/mutation-types";
import types from "./mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const initNewsAnnouncement = ({ commit }: any): void => {
    commit(types.INIT_NEWS_ANNOUNCEMENTS);
}

const createNewsAnnouncement = ({ commit }: any, { data, callback }: any): void => {
    commit(loadingTypes.START_LOADING);
    new NewsAnnouncementProxy().create(data).then((response) => {
        commit(types.CREATE_NEWS_ANNOUNCEMENT_SUCCESS, response);
        commit(loadingTypes.END_LOADING);
        alert.methods.appToaster({ message: msgConstants.NEWS_ANNOUNCEMENT_CREATE_SUCCESS, type: "SUCCESS"});
        callback();
    }).catch((error) => {
        commit(types.CREATE_NEWS_ANNOUNCEMENT_FAIL, error.error);
        alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
        commit(loadingTypes.END_LOADING);
    })
}
const getNewsAnnouncements = ({ commit }: any, data: any) => {
    commit(loadingTypes.START_LOADING);
    new NewsAnnouncementProxy().getNewsAnnouncements(data).then((response) => {
        commit(types.GET_NEWS_ANNOUNCEMENTS_LIST_SUCCESS, response);
        commit(loadingTypes.END_LOADING);
    }).catch(error => {
        commit(types.GET_NEWS_ANNOUNCEMENTS_LIST_FAIL, error.error);
        alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
        commit(loadingTypes.END_LOADING);
    });
}

const getNewsAnnouncementCount = ({ commit }: any, data: any): void => {
    commit(loadingTypes.START_LOADING);
    new NewsAnnouncementProxy().getNewsAnnouncementCount(data).then((response) => {
        commit(loadingTypes.END_LOADING);
        commit(types.GET_NEWS_ANNOUNCEMENTS_COUNT, response);
    }).catch(err => {
        commit(types.GET_NEWS_ANNOUNCEMENTS_LIST_FAIL, err.error);
        commit(loadingTypes.END_LOADING);
    });
}

const deleteNewsAnnouncement = ({ commit }: any, { data, callback }: any) => {
    commit(loadingTypes.START_LOADING);
    new NewsAnnouncementProxy().delete(data).then(() => {
        commit(loadingTypes.END_LOADING);
        alert.methods.appToaster({ message: msgConstants.NEWS_ANNOUNCEMENT_DELETE_SUCCESS, type: "SUCCESS"});
        callback();
    }).catch(err => {        
        commit(types.REMOVE_NEWS_ANNOUNCEMENT_FAIL, err.error);
        alert.methods.appToaster({ message: err.error.message, type: "ERROR" });
        commit(loadingTypes.END_LOADING);
    });
}

const getNewsAnnouncementById = ({ commit }: any, id: string) => {
    commit(loadingTypes.START_LOADING);
    new NewsAnnouncementProxy().getById(id).then((response: any) => {
        commit(types.GET_NEWS_ANNOUNCEMENT_BY_ID_SUCCESS, response);
        commit(loadingTypes.END_LOADING);
    }).catch((err: any) => {
        commit(types.GET_NEWS_ANNOUNCEMENT_BY_ID_FAIL, err.error);
        commit(loadingTypes.END_LOADING);
    });
}

const updateNewsAnnouncement = ({ commit }: any, { data, callback }: any) => {
    commit(loadingTypes.START_LOADING);
    new NewsAnnouncementProxy().updateNewsAnnouncement(data).then(() => {
        commit(loadingTypes.END_LOADING);
        alert.methods.appToaster({ message: msgConstants.NEWS_ANNOUNCEMENT_UPDATE_SUCCESS, type: "SUCCESS"});
        callback();
    }).catch(err => {
        commit(types.GET_NEWS_ANNOUNCEMENTS_LIST_FAIL, err.error);
        alert.methods.appToaster({ message: err.error.message, type: "ERROR" });
        commit(loadingTypes.END_LOADING);
    });
}


export default {
    getNewsAnnouncements,
    getNewsAnnouncementCount,
    deleteNewsAnnouncement,
    createNewsAnnouncement,
    initNewsAnnouncement,
    getNewsAnnouncementById,
    updateNewsAnnouncement
}