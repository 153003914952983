/* ============
 * Mutation types for the Tenant module
 * ============
 *
 * The mutation types that are available
 * on the Tenant module.
 */
import keyMirror from "keymirror";

export default keyMirror({

  GET_TENANTS_SUCCESS: null,
  GET_TENANTS_FAIL: null,

});
