
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

  [types.INIT_NEW_PHYSICIAN_USER](state: State) {
    state.physician = {};
  },
  [types.CREATE_NEW_PHYSICIAN_USER_SUCCESS](state: State, response: any) {
    state.physician = response.data;
  },
  [types.GET_PHYSICIAN_USER_LIST_SUCCESS](state: State, response: any) {
    state.physicians = response;
  },
  [types.CREATE_NEW_PHYSICIAN_USER_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_PHYSICIAN_USER_BY_ID_SUCCESS](state: State, response: any) {
    state.physician = response;
  },
  [types.GET_PHYSICIAN_USER_BY_ID_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.PHYSICIAN_USER_UPDATE_SUCCESS](state: State, response: any) {
    state.physician = response;
  },
  [types.PHYSICIAN_USER_UPDATE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_PHYSICIAN_USER_COUNT](state: State, response: any) {
    state.physicianCount = response.count;
  },
  [types.SET_PHYSICIAN_USER](state: State, response: any) {
    state.physician = response;
  },
} as MutationTree<State>;