














































import { Vue, Prop, Component, Emit } from "vue-property-decorator";
import { CoolSelect } from "vue-cool-select";
import "vue-cool-select/dist/themes/bootstrap.css";

@Component({
  components: {
    CoolSelect
  }
})
export default class FormSearchSelect extends Vue {
  @Prop() private rules!: string;
  @Prop() private inputValue!: string;
  @Prop() private label!: string;
  @Prop() public options!: any;
  @Prop() private placeholder!: string;
  @Prop({ default: "text" }) textField!: string;
  @Prop({ default: "value" }) valueField!: string;
  @Prop() isSubmitted!: boolean;
  @Prop({ default: false }) isLocationFilter!: boolean;

  value: any = this.inputValue;

  @Emit("getInputValue")
  public onChangeInput() {
    return this.value;
  }

  @Emit("getInputValue")
  onSelect() {
    return this.value;
  }

  @Emit("getInputValue")
  onClose() {
    this.value = null;
    const select = this.$refs.select as any;
    select.hideMenu();
    return null;
  }

  getSelectItemClass(item: any) {
    return "select__item " + (item.disabled ? "disabled" : "");
  }
}
