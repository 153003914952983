import Proxy from "./Proxy";

class HolidayProxy extends Proxy {
  /**
   * Constructor for Holiday proxy
   */
  constructor() {
    super(`/holidays`);
  }

  public updateHoliday(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }

  public getHolidays(data: any) {
    return this.getList(data);
  }

  public createHoliday(data: any) {
    return this.submit("post", `${this.endpoint}`, undefined, data);
  }

  public getListCount(data: any) {
    return this.submit("get", `${this.endpoint}/count`, {where: JSON.stringify(data)});
  }

}

export default HolidayProxy;
