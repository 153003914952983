import Proxy from "./Proxy";

class MessageParticipantsProxy extends Proxy {
  /**
   * Constructor for Auth proxy
   */
  constructor() {
    super(`/message-participants`);
  }

  // public getMessageParticipants(data: any) {
  //   return this.getList(data);
  // }

  public getMessageParticipants(filter: any) {
    return this.submit("get", this.endpoint,  {filter: JSON.stringify(filter)});
  }

  public getMessageParticipantsContacts() {
    return this.submit("get", `${this.endpoint}/contacts`);
  }


  public findMessageParticipants(filter: any) {
    return this.submit("get", this.endpoint,  {filter: JSON.stringify(filter)});
  }

  public createMessageParticpants(data: any) {
    return this.submit("post", this.endpoint, undefined, data);
  }

}

export default MessageParticipantsProxy;
