import { GetterTree } from "vuex";
import { State } from "./state";
import { clone } from "@/utils/object";

export default {
  // messageParticpantsInitialData(state: State) {
  //   return clone(state.messageParticpantsInitialData);
  // },
  message(state: State) {
    return clone(state.messageInitialData);
  },

  messageParticipant(state: State) {
    return clone(state.messageParticipantInitialData);
  },

  messageParticipants(state: State) {
    return state.messageParticipants;
  },

  newUsersContactList(state: State) {
    return state.newUsersContactList;
  },

  messages(state: State) {
    return state.messages;
  },

  unreadTotalCount(state: State) {
    return state.unreadTotalCount;
  },

  repName(state: State) {
    return state.repNameToMessage;
  }
} as GetterTree<State, any>;
