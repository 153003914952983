export class State {
  public meetingRule: any = {};
  public error = '';
  public meetingRuleInitData = {
    applies_to: "",
    no_of_appointments: null,
    duration: null,
    location_ids: [],
    status: true
  };
  public meetingRules = [];
  public meetingRuleCount = 0;
}