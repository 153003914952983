






























































import { Vue, Prop, Component, Emit, Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import "vue-cool-select/dist/themes/bootstrap.css";

@Component({
  components: {
    Multiselect,
  },
})
export default class MultiSelect extends Vue {
  @Prop() private rules!: string;
  @Prop() private inputValue!: string;
  @Prop() private label!: string;
  @Prop() public options!: any;
  @Prop() private placeholder!: string;
  @Prop({ default: "text" }) textField!: string | string[];
  @Prop({ default: "value" }) valueField!: string;
  @Prop() isSubmitted!: boolean;
  @Prop({ default: false }) isLocationFilter!: boolean;
  @Prop({ default: false }) isMultiSelect!: boolean;
  @Prop() hideTag!: boolean;
  @Prop({ default: false }) isFromModal!: boolean;
  @Prop({ default: false }) isCustomLabel!: boolean;
  @Prop({ default: true }) allowEmpty!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 3 }) limit!: number;
  @Prop({ default: false }) meetingRestaurant!: boolean;

  value: any;
  restaurantLocation: any;

  beforeMount() {
    this.value = !this.isMultiSelect
      ? this.options.filter((option: any) => {
          return option[this.valueField] === this.inputValue;
        })
      : this.inputValue;
  }

  @Watch("inputValue")
  onValueChanged(value: any) {
    this.value = !this.isMultiSelect
      ? this.options.filter((option: any) => {
          return option[this.valueField] == value;
        })
      : value;
  }

  @Emit("getInputValue")
  public onChangeInput() {
    return this.value;
  }

  @Emit("getInputValue")
  onSelect(value: any) {
    if (!this.isMultiSelect) {
      this.value = this.options.filter((option: any) => {
        return option[this.valueField] === value[this.valueField];
      });
    } else {
      this.value.push(value);
    }
    return !this.isMultiSelect ? value[this.valueField] : this.value;
  }

  @Emit("getInputValue")
  onClose(removedValue: any) {
    this.value = this.isMultiSelect
      ? this.value.filter((selectedValue: any) => {
          return (
            selectedValue[this.valueField] !== removedValue[this.valueField]
          );
        })
      : null;
    return this.value;
  }

  nameWithEmail(option: any) {
    this.restaurantLocation = "";
    if (this.isCustomLabel) {
      return option[this.textField[0]][this.textField[1]]
        ? `${option[this.textField[0]][this.textField[1]]}`
        : `${option[this.textField[0]][this.textField[2]]}`;
    } else {
      if (this.meetingRestaurant && option.id != 9999) {
        this.restaurantLocation = `${option.city}, ${option.state} ${option.zipcode}`;        
      }
      return this.isLocationFilter
        ? `${option[this.textField as string]}`
        : `${option.first_name} ${option.last_name} (${option.email})`;
    }
  }
}
