import { State } from "./state";
import { GetterTree } from "vuex";
import { clone } from "@/utils/object";
import constants from "@/constants/app.properties";

export default {
  isAuthenticated: (state: State) => state.isAuthenticated,
  authToken: (state: State) => state.authToken,
  loginErrMsg: (state: State) => state.loginErrMsg,
  currentUser: (state: State) => state.currentUser,
  user: (state: State) => clone(state.user),
  pendingUsers: (state: State) => state.pendingUsers,
  role(state: State) {
    return state.userRoles[state.currentUser.userTypesId] || "USER";
  },
  defaultRoute(state: State, GetterTree: any) {
    return constants.DEFAULT_ROUTE_PATH[GetterTree.role];
  },

  permissions(state: State) {
    return state.modulePermissions;
  },

  rolePermissions(state: State) {
    return state.rolePermissions;
  },
  billingHistories: (state: State) => state.billingHistories,
  subscriptionPlans: (state: State) => state.subscriptionPlans,
  selectedPlan: (state: State) => state.selectedPlan,
  users: (state: State) => state.users,
  userCount: (state: State) => state.userCount,
  allUsers: (state: State) => state.allUsers,
  isShowModal: (state: State) => state.showUnSubscribeModal,
  healthcareDomains: (state: State) => state.healthcareDomains,
  isCurrentTenantBlocked: (state: State) => state.isCurrentTenantBlocked,
  isSubscribed: (state: State) => state.isSubscribed,
  order: (state: State) => state.order,
  practiceLocationsByUser: (state: State) => state.practiceLocationsByUser,
  organizationTypes: (state: State) => state.organizationTypes,
  settingValue: (state: State) => state.settingValue,
  powerbiRes: (state: State) => state.powerbiRes,
  powerbiReports: (state: State) => state.powerbiReports,
  isShowHIPAAAgreementModal: (state: State) => state.isShowHIPAAAgreementModal,
  showVerificationModal: (state: State) => state.showVerificationModal,
} as GetterTree<State, any>;
