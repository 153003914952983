import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {
    [types.INIT_NEWS_ANNOUNCEMENTS](state: State){
        state.newsAnnouncement = {};
    },
    [types.GET_NEWS_ANNOUNCEMENTS_LIST_SUCCESS](state: State, response: any){
        state.newsAnnouncements = response;
    },
    [types.GET_NEWS_ANNOUNCEMENTS_COUNT](state: State, response: any){
        state.newsAnnouncementCount = response.count;
    },
    [types.REMOVE_NEWS_ANNOUNCEMENT_FAIL](state: State, error: any){
        state.error = error;
    },
    [types.GET_NEWS_ANNOUNCEMENTS_LIST_FAIL](state: State, error:any){
        state.error = error;
        state.newsAnnouncements = [];
    },
    [types.CREATE_NEWS_ANNOUNCEMENT_SUCCESS](state: State, response:any){
        state.newsAnnouncement = response;
    },
    [types.CREATE_NEWS_ANNOUNCEMENT_FAIL](state: State, error:any){
        state.error = error;
    },
    [types.GET_NEWS_ANNOUNCEMENT_BY_ID_SUCCESS](state: State, response: any){
        state.newsAnnouncement = response;
    },
    [types.GET_NEWS_ANNOUNCEMENT_BY_ID_FAIL](state: State, error: any){
        state.error = error;
    }
} as MutationTree<State>;