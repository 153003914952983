import { State } from "./state";
import { GetterTree } from "vuex";
import { clone } from "@/utils/object";

export default {
  meeting(state: State) {
    return clone(state.meeting);
  },
  meetingInitialData(state: State) {
    return clone(state.meetingInitialData);
  },
  meetings(state: State) {
    return state.meetings;
  },
  meetingsTotalCount(state: State) {
    return state.meetingsTotalCount;
  },
  // Getters for Brands, Topics and Categories 
  brands(state: State) {
    return state.brands;
  },
  topics(state: State) {
    return state.topics;
  },
  categories(state: State) {
    return state.categories;
  },
  physicians(state: State) {
    return state.physicians;
  },
  locations(state: State) {
    return state.locations;
  },
  meetingId(state: State) {
    return state.meetingId;
  },
  isShowRecommendationModal: (state: State) => state.showRecommendationModal,
  recommendations: (state: State) => state.recommendations,
  recommendedPhysicians: (state: State) => state.recommendedPhysicians,

} as GetterTree<State, any>;
