export const lettersColor = {
    A: { "background-color": "#6282D4"  },
    B: { "background-color": "#2AC179" },
    C: { "background-color": "#D462D0" },
    D: {  "background-color": "#2AC1AF" },
    E: { "background-color": "#62B9D4" },
    F: { "background-color": "#D2AE2E" },
    G: { "background-color": "#7262D4" },
    H: { "background-color": "#D47762" },
    I: { "background-color": "#2AA6C1" },
    J: { "background-color": "#B062D4" },
    K: {  "background-color": "#E54C42" },
    L: { "background-color": "#94B92A" },
    M: {  "background-color": "#D79261" },
    N: { "background-color": "#44AA3B" },
    O: { "background-color": "#ABC510" },
    P: { "background-color": "#6B62D4" },
    Q: { "background-color": "#2AB8C1" },
    R: { "background-color": "#68B883" },
    S: { "background-color": "#D2912E" },
    T: {  "background-color": "#52B3D2" },
    U: { "background-color": "#D8B021" },
    V: { "background-color": "#1574CC" },
    W: { "background-color": "#BCB623" },
    X: { "background-color": "#34B596" },
    Y: { "background-color": "#CD7433" },
    Z: { "background-color": "#C134C3" }
  };