




































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
// import momenttz from "moment-timezone";

@Component({
  components: {},
})
export default class PhysicianRoomForm extends Vue {
  @Action public createPhysicianRoom: any;
  @Action public initNewPhysicianRoom: any;
  @Action private getPhysicianRoomById: any;
  @Action private updatePhysicianRoom: any;
  @Getter public physicianRoom: any;
  @Getter public currentUser: any;
  @Action public getLocationDropdown: any;
  @Getter public locations: any;
  @Action public getAppointmentTypes: any;
  @Getter public appointmentTypes: any;
  @Action public getPhysiciansForDropDown: any;
  @Getter public physiciansForDropdown: any;

  action = "add";
  isSubmitted = false;
  $refs!: {
    physicianRoomForm: InstanceType<typeof ValidationObserver>;
  };
  timezone = "";

  beforeMount() {
    // this.timezone = momenttz.tz.guess();
    this.initNewPhysicianRoom();
    const id = this.$route.params.id;
    this.getAppointmentTypes({ where: { status: { eq: true } } });
    this.getPhysiciansForDropDown({where: { status: { eq: true } }});
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    if (id) {
      this.action = "edit";
      this.getPhysicianRoomById(id);
    }
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.physicianRoomForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.physicianRoomForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.physicianRoomForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        if (this.action === "add") {
          const {
            physicianId,
            locationId,
            meeting_at,
            appointmentTypeId,
            description,
            status,
          } = this.physicianRoom;
          this.createPhysicianRoom({
            data: {
            physicianId,
            locationId,
            meeting_at,
            appointmentTypeId,
            description,
            status
            },
            callBack: this.back,
          });
        } else {
          let { 
            id,
            physicianId,
            locationId,
            meeting_at,
            appointmentTypeId,
            description,
            status 
            } = this.physicianRoom;
          description = description ? description: '',
          this.updatePhysicianRoom({
            data: {
              id,
            physicianId,
            locationId,
            meeting_at,
            appointmentTypeId,
            description,
            status
            },
            callBack: this.back,
          });
        }
      }
    });
  }

  back() {
    this.$router.push({ name: "physicianRoom.index" }).catch();
    this.$refs.physicianRoomForm.reset();
  }

  onCancel(): void {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.$router.push({ name: "physicianRoom.index" }).catch();
      },
      () => {}
    );
  }
}
