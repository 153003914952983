import types from "./mutation-types";
import LocationTimeslotProxy from "@/proxies/LocationTimeslotProxy";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const initNewLocationTimeslot = ({ commit }: any) => {
  commit(types.INIT_NEW_LOCATION);
};

const createLocationTimeslot = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationTimeslotProxy().createLocationTimeslot(data).then((response: any) => {
    commit(types.CREATE_NEW_LOCATION_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.LOCATION_TIMESLOT_CREATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.CREATE_NEW_LOCATION_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getLocationTimeslotById = ({ commit }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  new LocationTimeslotProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_LOCATION_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_LOCATION_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getLocationTimeslots = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationTimeslotProxy().getLocationTimeslots(data).then((response: any) => {
    commit(types.GET_LOCATION_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.CREATE_NEW_LOCATION_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const updateLocationTimeslot = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationTimeslotProxy().updateLocationTimeslot(data).then((response: any) => {
    commit(types.LOCATION_UPDATE_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.LOCATION_TIMESLOT_UPDATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.GET_LOCATION_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    callBack();
    commit(loadingTypes.END_LOADING);
  });
};

const getLocationTimeslotCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new LocationTimeslotProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_LOCATION_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_LOCATION_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const deleteTimeSlot = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  commit(types.TIME_SLOT_DELETED_INIT);
  new LocationTimeslotProxy().deleteLocationTimeslot(data).then(() => {
    commit(types.TIME_SLOT_DELETED_SUCCESS, data.id);
    commit(loadingTypes.END_LOADING);
    callback();
    alert.methods.appToaster({ message: msgConstants.TIME_SLOT_DELETED_SUCCESS, type: "SUCCESS" });
  }).catch((error: any) => {
    commit(types.TIME_SLOT_DELETED_FAIL, error);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.MEETING_DELETED_FAIL, type: "ERROR" });
  });
};

const setLocationTimeslot = ({ commit }: any, locationTimeslot: any) => {
  commit(types.SET_LOCATION_TIMESLOT, locationTimeslot);
};

export default {
  initNewLocationTimeslot,
  createLocationTimeslot,
  getLocationTimeslots,
  getLocationTimeslotById,
  updateLocationTimeslot,
  getLocationTimeslotCount,
  setLocationTimeslot,
  deleteTimeSlot
};