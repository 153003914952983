export class State {
    public error = '';
    public medicalRepInitialData: any = {
        first_name: "",
        last_name: "",
        email: "",
        pharma_company: "",
        phone: "",
        password: "",
        products: "",
        speciality: "",
        address: "",
        zipcode: "",
        territory: "",
        comment: ""
    };

    public medicalRep = {};
}