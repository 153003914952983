











































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import LocationForm from "@/components/locations/LocationForm.vue";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    LocationForm,
  },
})
export default class PhysicianRoomList extends Vue {
  @Action public initNewPhysicianRoom: any;
  @Action public getPhysicianRooms: any;
  @Action public updatePhysicianRoom: any;
  @Action public getPhysicianRoomCount: any;
  @Action public setPhysicianRoom: any;
  @Getter public PhysicianRoomInitData: any;
  @Getter public physicianRooms: any;
  @Getter public physicianRoomCount: any;
  @Action public getPhysiciansForDropDown: any;
  @Getter public physiciansForDropdown: any;
  @Action public getLocationDropdown: any;
  @Getter public locations: any;
  @Getter public rolePerm: any;
  perm = _permissions;

  physicianRoomFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      status: { eq: true },
    },
    include: [
      {
        relation: "tenant",
        scope: {
          fields: { id: true, name: true },
        },
      },
      {
        relation: "physician",
        scope: {
          fields: { id: true, first_name: true, last_name: true, prefix: true},
        },
      },
      {
        relation: "location",
        scope: {
          fields: { id: true, name: true },
        },
      },
      {
        relation: "appointmentType",
        scope: {
          fields: { id: true, appointment_type: true },
        },
      },
    ],
  };
  pageOptions = [10, 20];
  currentPage = 1;
  searchText = "";
  searchPhysician = null;
  searchLocation = null;

  beforeMount() {
    this.getPhysicianRoomList();
    this.getPhysiciansForDropDown({ where: { status: { eq: true } } });
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
  }

  setPhysician(id: any) {
    if(!id && !this.searchLocation) {
      this.physicianRoomFilter.where = {
      status: { eq: true },
    };
    } else if(!this.searchLocation && id ) {
        this.physicianRoomFilter.where.and = [{ physicianId: { eq: id } }];
    } else if(!id && this.searchLocation) { 
            this.physicianRoomFilter.where.and = [{ locationId: { eq: this.searchLocation } }];
    } else {
      this.physicianRoomFilter.where.and = [{ physicianId: { eq: id } },
        { locationId: { eq: this.searchLocation } }];
    }
    this.physicianRoomFilter.skip = 0;
    this.searchPhysician = id;
    this.getPhysicianRoomList();
  }

  setLocation(id: any) {
    if(!id && !this.searchPhysician) {
      this.physicianRoomFilter.where = {};
    } else if(!this.searchPhysician && id) {
      this.physicianRoomFilter.where.and = [
        { locationId: { eq: id } },
      ];
    } else if(!id && this.searchPhysician) {
      this.physicianRoomFilter.where.and = [
        { physicianId: { eq: this.searchPhysician } },
      ];
    } else {
      this.physicianRoomFilter.where.and = [
        { locationId: { eq: id } },
        { physicianId: { eq: this.searchPhysician } },
      ];
    }
    this.physicianRoomFilter.skip = 0;
    this.searchLocation = id;
    this.getPhysicianRoomList();
  }

  clearSearch() {
    this.searchText = "";
    this.setPhysician(null);
    this.setLocation(null);
  }

  getPhysicianRoomList() {
    this.getPhysicianRooms(this.physicianRoomFilter);
    this.getPhysicianRoomCount(this.physicianRoomFilter.where);
  }

  addPhysicianRoom() {
    this.$router.push({ path: `/physician-log/add` });
  }

  editPhysicianRoom(id: number): void {
    this.$router.push({ path: `/physician-log/update/${id}` });
  }

  pageSizeChanged() {
    this.physicianRoomFilter.skip = 0;
    this.getPhysicianRoomList();
  }

  onPageChange(currentPage: any) {
    this.physicianRoomFilter.skip =
      (currentPage - 1) * this.physicianRoomFilter.limit;
    this.getPhysicianRoomList();
  }
}
