
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {
  [types.GET_PRACTICE_LIST_SUCCESS](state: State, response: any) {
    state.practices = response;
  },
  [types.GET_PRACTICE_LIST_FAIL](state: State, error: any) {
    state.error = error;
    state.practices = [];
  },
  [types.GET_DROPDOWN_PRACTICE_LIST_SUCCESS](state: State, response: any) {
    state.dropdownPracticeList = response;
  },
  [types.GET_DROPDOWN_PRACTICE_LIST_FAIL](state: State, error: any) {
    state.error = error;
    state.dropdownPracticeList = [];
  },

  [types.GET_PRACTICE_COUNT](state: State, response: any) {
    state.practiceCount = response.count;
  },
  [types.REMOVE_PRACTICE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.CHANGE_PRACTICE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.ENABLE_DISABLE_PRACTICE_FAIL](state: State, error: any) {
    state.error = error;
  },
} as MutationTree<State>;