/* ============
 * Mutation  for the Meeting module
 * ============
 *
 * The mutation types that are available
 * on the Meeting module.
 */

import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";
import array from '@/utils/array';

export default {
  [types.MEETING_CREATE_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.MEETING_CREATE_SUCCESS](state: State) {
    state.meetingsLoading = false;
    state.meeting = state.meetingInitialData;
  },
  [types.MEETING_CREATE_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.error = error;
  },

  [types.MEETING_UPDATE_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.MEETING_UPDATE_SUCCESS](state: State) {
    state.meetingsLoading = false;
    state.meeting = state.meetingInitialData;
  },
  [types.MEETING_UPDATE_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    // state.meeting = state.meetingInitialData;
    state.error = error;
  },

  [types.GET_MEETING_INIT](state: State) {
    state.meeting = state.meetingInitialData;
    state.meetingsLoading = true;
  },
  [types.GET_MEETING_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    state.meeting = response;
  },
  [types.GET_MEETING_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.meeting = state.meetingInitialData;
    state.error = error;
  },

  [types.FETCH_MEETING_LIST](state: State) {
    state.meetingsLoading = true;
  },
  [types.FETCH_MEETING_LIST_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    state.meetings = response;
  },
  [types.FETCH_MEETING_LIST_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.error = error;
    state.meetings = [];
  },
  [types.SET_MEETING](state: State, meeting: any) {
    state.meeting = meeting;
  },

  [types.INIT_MEETING](state: State) {
    state.meeting = state.meetingInitialData;
  },

  [types.SET_ACTIVE_TAB](state: State, tabStatus: any) {
    state.activeTab = tabStatus ? "UPCOMING" : "DRAFT";
  },

  [types.PUBLISH_MEETING_SUCCESS](state: State) {
    state.activeTab = "UPCOMING";
  },

  [types.PUBLISH_MEETING_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.DELETE_MEETING_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.DELETE_MEETING_SUCCESS](state: State, id: any) {
    state.meetingsLoading = false;
    state.meetings.splice(array.findIndex(state.meetings, { id }), 1);
  },
  [types.DELETE_MEETING_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.error = error;
  },

  // Mutations for Brands, Topics and Categories  
  [types.FETCH_BRANDS_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.FETCH_BRANDS_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    state.brands = response;
  },
  [types.FETCH_BRANDS_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.brands = [];
    state.error = error;
  },

  [types.FETCH_TOPICS_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.FETCH_TOPICS_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    state.topics = response;
  },
  [types.FETCH_TOPICS_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.topics = [];
    state.error = error;
  },

  [types.FETCH_CATEGORIES_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.FETCH_CATEGORIES_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    state.categories = response;
  },
  [types.FETCH_CATEGORIES_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.categories = [];
    state.error = error;
  },

  [types.FETCH_LOCATIONS_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.FETCH_LOCATIONS_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    // const cust_response = [{id: 0, name: 'All'}];    
    state.locations = response;
  },
  [types.FETCH_LOCATIONS_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.locations = [];
    state.error = error;
  },

  [types.FETCH_PHYSICIANS_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.FETCH_PHYSICIANS_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    state.physicians = response;
  },
  [types.FETCH_PHYSICIANS_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.physicians = [];
    state.error = error;
  },

  [types.TRANSFER_MEETING_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.TRANSFER_MEETING_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    state.meeting = response;
  },
  [types.TRANSFER_MEETING_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.error = error;
  },
  [types.SET_MEETING_ID](state: State, response: any) {
    state.meetingId = response;
  },
  [types.MEETING_RULE_VALID_INIT](state: State) {
    state.meetingsLoading = true;
  },
  [types.MEETING_RULE_VALID_SUCCESS](state: State, response: any) {
    state.meetingsLoading = false;
    state.meeting = response;
  },
  [types.MEETING_RULE_VALID_FAIL](state: State, error: any) {
    state.meetingsLoading = false;
    state.error = error;
  },

  [types.SHOW_RECOMMENDATION_MODAL](state: State) {
    state.showRecommendationModal = true;
  },
  [types.HIDE_RECOMMENDATION_MODAL](state: State) {
    state.showRecommendationModal = false;
  },
  [types.GET_RECOMMENDATION_LIST_SUCCESS](state: State, response: any) {
    state.recommendations = response;
  },
  [types.GET_RECOMMENDATION_LIST_FAIL](state: State, error: any) {
    state.recommendations = [];
    state.error = error;
  },
  [types.SET_RECOMMENDED_PHYSICIANS](state: State, response: any) {
    state.recommendedPhysicians = response;
  }
} as MutationTree<State>;
