




















import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import VerificationForm from "./VerificationForm.vue";

@Component({
  components: {
    VerificationForm
  }
})
export default class VerificationModal extends Vue {

  @Action public hideVerificationModal: any;
  @Getter public showVerificationModal!: boolean;
  

  @Prop({default: ''}) public verificationMail!: string;
  @Prop({default: false}) public verificationMailEntered!: boolean;
  

  verified(value: any) {
    this.$emit("verified", value);
  }
}
