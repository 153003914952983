import types from "./mutation-types";
import LocationProxy from "@/proxies/LocationProxy";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const initNewLocation = ({ commit }: any) => {
  commit(types.INIT_NEW_LOCATION);
};

const createLocation = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy().create(data).then((response: any) => {
    commit(types.CREATE_NEW_LOCATION_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.LOCATION_CREATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.CREATE_NEW_LOCATION_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getLocationById = ({ commit }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_LOCATION_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_LOCATION_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getLocations = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy().getLocations(data).then((response: any) => {
    commit(types.GET_LOCATION_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.CREATE_NEW_LOCATION_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const updateLocation = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy().updateLocation(data).then(() => {
    commit(loadingTypes.END_LOADING);
    if (data.policies && data.policies.length !== 0) {
      alert.methods.appToaster({ message: msgConstants.LOCATION_POLICIES_UPDATE_SUCCESS, type: "SUCCESS" });
    } else if (data.preferences && data.preferences.length !== 0) {
      alert.methods.appToaster({ message: msgConstants.LOCATION_PREFERENCES_UPDATE_SUCCESS, type: "SUCCESS" });
    } else {
      alert.methods.appToaster({ message: msgConstants.LOCATION_UPDATE_SUCCESS, type: "SUCCESS" });
    }
    callBack();
  }).catch((error: any) => {
    commit(types.GET_LOCATION_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const getLocationCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_LOCATION_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_LOCATION_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const setLocation = ({ commit }: any, location: any) => {
  commit(types.SET_LOCATION, location);
};

/**
 * It is used in meeeting to get policies and preferences based on location name.
 * 
 * @param data - is a location name
 */
const getPoliciesAndPreferencesByLocationId = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy()
    .getById(data)
    .then((response: any) => {
      commit(types.GET_POLICY_PREFERENCE_LIST_BY_LOCATION_NAME_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      callBack();
    }).catch((error: any) => {
      commit(types.GET_POLICY_PREFERENCE_LIST_BY_LOCATION_NAME_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};


const suspendOrRevokeLocation = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy().updateLocation(data).then(() => {
    commit(loadingTypes.END_LOADING);
    if (data.suspend_appointments) {
      alert.methods.appToaster({ message: msgConstants.LOCATION_SUSPEND_SUCCESS, type: "SUCCESS" });
    } else {
      alert.methods.appToaster({ message: msgConstants.LOCATION_REVOKE_SUCCESS, type: "SUCCESS" });
    }
    callBack();
  }).catch((error: any) => {
    commit(types.GET_LOCATION_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const cancelAndDeleteAppointments = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy().cancelAndDeleteAppointments(data).then(() => {
    commit(loadingTypes.END_LOADING);
    if (data.is_delete_appointments) {
      alert.methods.appToaster({ message: msgConstants.LOCATION_APPOINTMENTS_CANCEL_AND_DELETE_SUCCESS, type: "SUCCESS" });
    } else {
      alert.methods.appToaster({ message: msgConstants.LOCATION_APPOINTMENT_CANCEL_SUCCESS, type: "SUCCESS" });
    }
    callBack();
  }).catch((error: any) => {
    commit(types.GET_LOCATION_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const updatePracticeLocation = ({ commit }: any, {data,callback}: any): void => {
  commit(loadingTypes.START_LOADING);
  new LocationProxy()
    .updatePracticeLocation(data)
    .then(() => {
      commit(loadingTypes.END_LOADING);
      callback();
      let msg = msgConstants.UNBLOCK_ALL_REPS;
      if(typeof data.block_reps === 'boolean' && data.block_reps){
        msg = msgConstants.BLOCK_ALL_REPS;
      }
      alert.methods.appToaster({ message: msg, type: "SUCCESS" });
    }).catch((error: any) => {
      commit(loadingTypes.END_LOADING);
      alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    });
}

export default {
  initNewLocation,
  createLocation,
  getLocations,
  getLocationById,
  updateLocation,
  getLocationCount,
  setLocation,
  getPoliciesAndPreferencesByLocationId,
  suspendOrRevokeLocation,
  cancelAndDeleteAppointments,
  updatePracticeLocation
};