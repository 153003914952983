
import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

  [types.INIT_NEW_LOCATION](state: State) {
    state.location = state.locationInitData;
  },
  [types.CREATE_NEW_LOCATION_SUCCESS](state: State, response: any) {
    state.location = response.data;
  },
  [types.GET_LOCATION_LIST_SUCCESS](state: State, response: any) {
    state.tenantLocations = response;
  },
  [types.CREATE_NEW_LOCATION_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_LOCATION_BY_ID_SUCCESS](state: State, response: any) {
    state.location = response;
  },
  [types.GET_LOCATION_BY_ID_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.LOCATION_UPDATE_SUCCESS](state: State, response: any) {
    state.location = response;
  },
  [types.LOCATION_UPDATE_FAIL](state: State, error: any) {
    state.error = error;
  },
  [types.GET_LOCATION_COUNT](state: State, response: any) {
    state.locationCount = response.count;
  },
  [types.SET_LOCATION](state: State, location: any) {
    state.location = location;
  },
  [types.GET_POLICY_PREFERENCE_LIST_BY_LOCATION_NAME_SUCCESS](state: State, response: any) {
    state.policyPreferenceList = response;
  },
  [types.GET_POLICY_PREFERENCE_LIST_BY_LOCATION_NAME_FAIL](state: State, error: any) {
    state.error = error;
  },
} as MutationTree<State>;