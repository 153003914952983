





























import { Component, Vue } from "vue-property-decorator";
import alert from "@/mixins/alert";
import { Action, Getter } from "vuex-class";
import constants from "@/constants/app.properties";

@Component({
  components: {
  },
})
export default class PaymentForm extends Vue {
  @Action addSubscriptionPlan!: any;
  @Action startLoading!: any;
  @Action endLoading!: any;
  @Action getSubscriptionPlanById!: any;
  @Getter selectedPlan: any;
  @Action createPayment!: any;
  @Action createAgreementAudit!: any;
  @Getter currentUser!: any;
  @Getter order: any;

  appId = process.env.VUE_APP_SQUARE_APP_ID;
  locationId = process.env.VUE_APP_SQUARE_LOCATION_ID;
  statusContainerClass = '';
  isButtonDisable = false;
  card = '';

  async beforeMount() {
    if (!(window as any).Square) {
      this.$router.back();
      alert.methods.appToaster({
        message: 'Unable to load payment screen. Please refresh and try after sometime.',
        type: "WARNING",
      });
      throw new Error('Square.js failed to load properly');
    }
    this.getSubscriptionPlanById(this.$route.params.plan);
    if(!this.order.totalMoney) {
      this.$router.push({ path: `/subscription` });
    }
    let payments;
    try {
      payments = (window as any).Square.payments(this.appId, this.locationId);
    } catch(e: any) {
      console.error(e);
      this.statusContainerClass = 'missing-credentials visible';
      return;
    }

    try {
      this.card = await this.initializeCard(payments)
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
  }

  getPlanCostWithTax() {
    return (this.order.totalMoney/100).toFixed(2);
  }

  async initializeCard(payments: any) {
    this.startLoading();
    const card = await payments.card();
    await card.attach('#card-container');
    this.endLoading();
    return card;
  }

  async initiatePayment(token: any) {
    const body = JSON.stringify({
      locationId: this.locationId,
      sourceId: token,
      amount: +this.order.totalMoney,
      orderId: this.order.id
    });
    this.createPayment({data: body, callback: (response: any) => this.displayPaymentResults(response)});
  }

  async tokenize(paymentMethod: any) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }

      throw new Error(errorMessage);
    }
  }

  // status is either SUCCESS or FAILURE;
  async displayPaymentResults(paymentResponse: {
    payment: { id: string; sourceType: string; orderId: string; receiptUrl: string; }}) {
    const userId = localStorage.getItem(constants.USER_ID);
    await this.addSubscriptionPlan({data:{ userId,
      subscription_id: this.selectedPlan.id,
      payment_id: paymentResponse.payment.id,
      source_type: paymentResponse.payment.sourceType,
      order_id: paymentResponse.payment.orderId,
      receipt_url: paymentResponse.payment.receiptUrl,
      amount: +this.getPlanCostWithTax()
    },callback: ()=>{this.$router.push({path: '/subscription'})}});
    await this.createAgreementAudit({ id: this.currentUser.id, is_agreed_subscription: false });
    this.endLoading();
    // this.$router.push({path: '/subscription'})
  }

  async handlePaymentMethodSubmission() {
    try {
      // disable the submit button as we await tokenization and make a payment request.
      this.startLoading();
      this.isButtonDisable = true;
      const token = await this.tokenize(this.card);
      this.endLoading();
      await this.initiatePayment(token);
    } catch (e: any) {
      console.error(e);
      this.endLoading();
      this.isButtonDisable = false;
    }
  }

  back(): void {
    this.$router.push({ name: "subscription.index" }).catch();
  }
}
