import Proxy from "./Proxy";

class TenantPreferenceProxy extends Proxy {
  /**
   * Constructor for Tenant Preference proxy
   */
  constructor() {
    super(`/preferences`);
  }

  
  public updateTenantPreference(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }


  public getTenantPreferences(filter: any) {
    return this.submit("get", this.endpoint, {filter: JSON.stringify(filter)});
  }

  public getListCount(data: any) {
    return this.submit("get", `${this.endpoint}/count`, {where: JSON.stringify(data)});
  }
}

export default TenantPreferenceProxy;
