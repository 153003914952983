/* ============
 * Mutation types for the Meeting module
 * ============
 *
 * The mutation types that are available
 * on the Meeting module.
 */
import keyMirror from "keymirror";

export default keyMirror({
  MEETING_CREATE_INIT: null,
  MEETING_CREATE_SUCCESS: null,
  MEETING_CREATE_FAIL: null,

  MEETING_UPDATE_INIT: null,
  MEETING_UPDATE_SUCCESS: null,
  MEETING_UPDATE_FAIL: null,

  GET_MEETING_INIT: null,
  GET_MEETING_SUCCESS: null,
  GET_MEETING_FAIL: null,

  FETCH_MEETING_LIST: null,
  FETCH_MEETING_LIST_SUCCESS: null,
  FETCH_MEETING_LIST_FAIL: null,

  DELETE_MEETING_INIT: null,
  DELETE_MEETING_SUCCESS: null,
  DELETE_MEETING_FAIL: null,

  SET_MEETING: null,
  INIT_MEETING: null,

  SET_ACTIVE_TAB: null,

  PUBLISH_MEETING_SUCCESS: null,
  PUBLISH_MEETING_FAIL: null,

  FETCH_BRANDS_INIT: null,
  FETCH_BRANDS_SUCCESS: null,
  FETCH_BRANDS_FAIL: null,

  FETCH_TOPICS_INIT: null,
  FETCH_TOPICS_SUCCESS: null,
  FETCH_TOPICS_FAIL: null,

  FETCH_CATEGORIES_INIT: null,
  FETCH_CATEGORIES_SUCCESS: null,
  FETCH_CATEGORIES_FAIL: null,

  FETCH_LOCATIONS_INIT: null,
  FETCH_LOCATIONS_SUCCESS: null,
  FETCH_LOCATIONS_FAIL: null,

  FETCH_PHYSICIANS_INIT: null,
  FETCH_PHYSICIANS_SUCCESS: null,
  FETCH_PHYSICIANS_FAIL: null,

  TRANSFER_MEETING_INIT: null,
  TRANSFER_MEETING_SUCCESS: null,
  TRANSFER_MEETING_FAIL: null,

  SET_MEETING_ID: null,

  MEETING_RULE_VALID_INIT: null,
  MEETING_RULE_VALID_SUCCESS: null,
  MEETING_RULE_VALID_FAIL: null,

  SHOW_RECOMMENDATION_MODAL: null,
  HIDE_RECOMMENDATION_MODAL: null,

  GET_RECOMMENDATION_LIST_SUCCESS: null,
  GET_RECOMMENDATION_LIST_FAIL: null,

  SET_RECOMMENDED_PHYSICIANS: null,

  APPROVE_REJECT_MEETING_TRANSFER_REQUEST_SUCCESS: null,
  APPROVE_REJECT_MEETING_TRANSFER_REQUEST_FAIL: null,
});
