import types from "./mutation-types";
import PhysicianProxy from "@/proxies/PhysicianProxy";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const initNewPhysicianUser = ({ commit }: any) => {
  commit(types.INIT_NEW_PHYSICIAN_USER);
};

const createPhysician = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianProxy().createPhysician(data).then((response: any) => {
    commit(types.CREATE_NEW_PHYSICIAN_USER_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.PHYSICIAN_USER_CREATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.CREATE_NEW_PHYSICIAN_USER_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getPhysicianById = ({ commit }: any, { id, filter, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianProxy()
    .getUserById(id, filter)
    .then((response: any) => {
      commit(types.GET_PHYSICIAN_USER_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
      callBack();
    })
    .catch((error: any) => {
      commit(types.GET_PHYSICIAN_USER_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getPhysicianList = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianProxy().getPhysicians(data).then((response: any) => {
    commit(types.GET_PHYSICIAN_USER_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.CREATE_NEW_PHYSICIAN_USER_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const updatePhysician = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianProxy().updatePhysician(data).then(() => {
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.PHYSICIAN_USER_UPDATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.GET_PHYSICIAN_USER_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const getPhysicianCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new PhysicianProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_PHYSICIAN_USER_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_PHYSICIAN_USER_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const getPhysicianByUserId = ({ commit }: any, id: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianProxy()
    .getPhysicianByUserId(id)
    .then((response: any) => {
      commit(types.GET_PHYSICIAN_USER_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_PHYSICIAN_USER_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};


const updatePhysicianProfile = ({ dispatch, commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianProxy().updatePhysician(data).then(() => {
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.PROFILE_UPDATED_SUCCESS, type: "SUCCESS" });
    dispatch('getLoggedInUser');
    callBack();
  }).catch((error: any) => {
    commit(types.GET_PHYSICIAN_USER_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const deletePhysicianById = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new PhysicianProxy().delete(data).then(() => {
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.PHYSICIAN_DELETE_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.GET_PHYSICIAN_USER_BY_ID_FAIL, error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

export default {
  initNewPhysicianUser,
  createPhysician,
  getPhysicianList,
  getPhysicianById,
  updatePhysician,
  getPhysicianCount,
  getPhysicianByUserId,
  updatePhysicianProfile,
  deletePhysicianById
};