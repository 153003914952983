import keyMirror from "keymirror";

export default keyMirror({
  GET_TEAM_BY_ID_SUCCESS: null,
  GET_TEAM_BY_ID_FAIL: null,
  GET_TEAM_LIST_SUCCESS: null,
  GET_TEAM_LIST_FAIL: null,
  GET_TEAM_COUNT: null,
  SET_TEAM: null,
  CREATE_NEW_TEAM_SUCCESS: null,
  CREATE_NEW_TEAM_FAIL: null,
  DELETE_TEAM_SUCCESS: null,
  DELETE_TEAM_FAIL: null,
});