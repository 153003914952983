export class State {
  public error = '';
  public restaurantInitData = {
    name: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    phone: "",
    status: true,
    email: ""
  };
  public restaurant = {};
  public restaurants = [];
  public restaurantCount = 0;
  public policyPreferenceList = [];
}