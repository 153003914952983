import Proxy from "./Proxy";

class AppointmentTypeProxy extends Proxy {
  /**
   * Constructor for Appointment Type proxy
   */
  constructor() {
    super(`/appointmenttypes`);
  }

  public updateAppointmentType(data: any) {
    const id = data.id;
    let body: any = JSON.stringify(data);
    body = JSON.parse(body);
    delete body.id;
    return this.submit("patch", `${this.endpoint}/${id}`, undefined, body);
  }


  public getAppointmentTypes(filter: any) {
    return this.getList(filter)
  }

  public getListCount(data: any) {
    return this.submit("get", `${this.endpoint}/count`,  {where: JSON.stringify(data)});
  }

  public getAppointmentTypesByLocation(id: any, filter: any) {
    return this.submit("get", `${this.endpoint}/${id}/location`,  {filter: JSON.stringify(filter)});
  }

}

export default AppointmentTypeProxy;
