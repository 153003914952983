
import keyMirror from "keymirror";

export default keyMirror({
  GET_USER_TYPES_INIT: null,
  GET_USER_TYPES_SUCCESS: null,
  GET_USER_TYPES_FAIL: null,
  GET_USER_PERMISSIONS_SUCCESS: null,
  GET_USER_ROLE_PERMISSION_SUCCESS: null,
  GET_USER_ROLE_PERMISSIONS_SUCCESS: null
});