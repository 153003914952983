







































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class CancelMeetingModal extends Vue {
  @Prop({ default: "md" }) public size!: any;
  @Prop() public meeting!: any;
  @Prop() public title = '';
  reason = '';

  show() {
    this.$bvModal.show("cancel-meeting-modal");
  }

  hide() {
    this.$bvModal.hide("cancel-meeting-modal");
  }

  hideModal() {
    this.reason = '';
    this.hide();
  }

  cancelMeeting() {
    this.$emit("onCancelMeeting", {id: this.meeting.id, reason: this.reason});
    this.hideModal();
  }

}
