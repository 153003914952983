/* ============
 * Mutation types for the Auth module
 * ============
 *
 * The mutation types that are available
 * on the Auth module.
 */
import keyMirror from "keymirror";

export default keyMirror({
  LOGIN_INIT: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAIL: null,

  LOGOUT_SUCCESS: null,

  REGISTER_USER_INIT: null,
  REGISTER_USER_SUCCESS: null,
  REGISTER_USER_FAIL: null,
  INIT_REGISTER_USER: null,
  GET_PENDING_USERS_SUCCESS: null,
  GET_PENDING_USERS_FAIL: null,
  BULK_UPDATE_SUCCESS: null,
  BULK_UPDATE_FAIL: null,


  PREFERENCE_UPDATE_INIT: null,
  PREFERENCE_UPDATE_SUCCESS: null,
  PREFERENCE_UPDATE_FAIL: null,
  PASSWORD_RESET_INIT: null,
  PASSWORD_RESET_SUCCESS: null,
  PASSWORD_RESET_FAIL: null,
  SET_CURRENT_USER: null,
  GET_PREFERENCE_SUCCESS: null,
  GET_PREFERENCE_FAIL: null,

  SET_AUTHENTICATED: null,

  SET_USER: null,

  SET_CURRENT_MODULE_PERMISSIONS: null,

  VERIFY_TOKEN_SUCCESS: null,
  UPDATE_USER_DETAILS: null,

  // subscription 
  GET_BILLING_HISTORY_BY_ID_SUCCESS: null,
  GET_BILLING_HISTORY_BY_ID_FAIL: null,

  GET_SUBSCRIPTIONS_PLANS_SUCCESS: null,
  GET_SUBSCRIPTIONS_PLANS_FAIL: null,

  GET_SUBSCRIPTION_PLAN_SUCCESS: null,
  GET_SUBSCRIPTION_PLAN_FAIL: null,

  PAYMENT_SUCCESS: null,
  PAYMENT_FAIL: null,

  ADD_SUBSCRPTION_PLAN_SUCCESS: null,
  ADD_SUBSCRPTION_PLAN_FAIL: null,

  GET_USERS: null,
  DELETE_USER_INIT: null,
  DELETE_USER_SUCCESS: null,
  DELETE_USER_FAIL: null,
  GET_USERS_COUNT: null,

  REP_ACTIVITES_FAIL: null,

  GET_USERS_FOR_TRANSFER: null,
  GET_USERS_FOR_TRANSFER_SUCCESS: null,
  GET_USERS_FOR_TRANSFER_FAIL: null,

  GET_HEALTH_CARE_DOMAINS: null,

  CREATE_SUBSCRIPTION_AGREEMENT_AUDIT_SUCCESS: null,
  CREATE_SUBSCRIPTION_AGREEMENT_AUDIT_FAIL: null,

  CREATE_ORDER_SUCCESS: null,
  CREATE_ORDER_FAIL: null,

  SHOW_LICENSE_INFO_MODAL: null,
  CLOSE_LICENSE_INFO_MODAL: null,

  SHOW_DEFAULT_TENANT_BLOCKED_MODAL: null,
  CLOSE_DEFAULT_TENANT_BLOCKED_MODAL: null,

  SET_SUBSCRIPTION_TRUE: null,

  GET_PRACTICE_LOCATIONS_BY_USER: null,

  GET_ORGANIZATION_TYPES_SUCCESS: null,
  GET_ORGANIZATION_TYPES_FAIL: null,

  SET_USER_VACCINATION_STATUS: null,
  GET_SETTING_VALUE_SUCCESS: null,
  GET_SETTING_VALUE_FAIL: null,

  GET_POWERBI_SUCCESS: null,
  GET_POWERBI_FAIL: null,

  GET_POWERBI_REPORT_SUCCESS: null,
  GET_POWERBI_REPORT_FAIL: null,
  SHOW_HIPAA_AGREEMENT_MODAL: null,
  HIDE_HIPAA_AGREEMENT_MODAL: null,

  SHOW_VERIFICATION_MODAL: null,
  HIDE_VERIFICATION_MODAL: null,
});
