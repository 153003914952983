/**
 * ==============
 * Initial state of Appointment Type module
 */
export class State {
  public appointmentTypeInitialData = {
    appointment_type: "",
    start_time: "",
    end_time: "",
  };
  public appointmentType = this.appointmentTypeInitialData;
  public appointmentTypes: any = [];
  public error: any;
  public appointmentTypesLoading = false;
  public appointmentTypesCount = 0;
}
