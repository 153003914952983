import types from "./mutation-types";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";
import PracticeProxy from "@/proxies/PracticeProxy";
import authTypes from "@/store/modules/auth/mutation-types";
import appConstant from '@/constants/app.properties';

const addPractice = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new PracticeProxy().create(data).then(() => {
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.PRACTICE_ADD_SUCCESS, type: "SUCCESS" });
    callback();
  }).catch((error: any) => {
    commit(types.ADD_PRACTICE_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getPractices = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new PracticeProxy().getPractices(data).then((response: any) => {
    if (data.callback) {
      data.callback(response);
    }
    commit(types.GET_PRACTICE_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.GET_PRACTICE_LIST_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const fetchPracticesForDropdown = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new PracticeProxy().getPractices(data).then((response: any) => {
    commit(types.GET_DROPDOWN_PRACTICE_LIST_SUCCESS, response);
    const blockedPractice = response.filter((practice: any) => practice.default && practice.is_blocked)
    // if default tenant is blocked and same as current tenant show alert
    if (blockedPractice.length && blockedPractice[0].tenantId === Number(localStorage.getItem(appConstant.TENANT_ID))) {
      commit(authTypes.SHOW_DEFAULT_TENANT_BLOCKED_MODAL);
    }
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(loadingTypes.END_LOADING);
    commit(types.GET_DROPDOWN_PRACTICE_LIST_FAIL, error.error);
  });
};

const closePracticeBlockModal = ({ commit }: any) => {
  commit(authTypes.CLOSE_DEFAULT_TENANT_BLOCKED_MODAL);
}


const getPracticeCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new PracticeProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_PRACTICE_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_PRACTICE_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

const removePractice = ({ commit }: any, { data }: any) => {
  commit(loadingTypes.START_LOADING);
  new PracticeProxy().remove(data.id).then(() => {
    commit(types.REMOVE_PRACTICE_SUCCESS, data.id);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.REMOVE_PRACTICE_SUCCESS_MSG, type: "SUCCESS" });
  }).catch((error: any) => {
    commit(types.REMOVE_PRACTICE_FAIL, error);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
  });
};

const changePractice = ({ commit }: any, { data, callback }: any) => {
  new PracticeProxy().changePractice(data.tenantId).then((response: any) => {
    commit(authTypes.VERIFY_TOKEN_SUCCESS, response);    
    commit(authTypes.UPDATE_USER_DETAILS, response);
    callback();
    // Finally, call autoRefresh to set up the new timeout
    //dispatch('autoRefresh');
    // dispatch('getUnreadTotalCount');
  }).catch((error: any) => {
    commit(types.CHANGE_PRACTICE_FAIL, error);
  });
};

const enableDisablePractice = ({ commit }: any, { data, callback }: any) => {
  commit(loadingTypes.START_LOADING);
  new PracticeProxy().create(data).then(() => {
    callback();
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.ENABLE_DISABLE_PRACTICE_FAIL, error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
  });
};

export default {
  getPractices,
  getPracticeCount,
  addPractice,
  removePractice,
  changePractice,
  fetchPracticesForDropdown,
  enableDisablePractice,
  closePracticeBlockModal
};