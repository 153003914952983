























































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import constants from "@/constants/_message";
import CancelMeetingModal from "@/components/cancelMeetingModal/CancelMeetingModal.vue";

import {
  approveAllDomainEmailAlert,
  approveEmailAlert,
  rejectEmaillAlert,
} from "@/utils/notificationContent";

@Component({
  components: {
    CancelMeetingModal
  },
})
export default class RegisterApproval extends Vue {
  @Action public getPendingUsers: any;
  @Getter public pendingUsers: any;
  @Action public bulkUpdate: any;
  @Getter public currentUser: any;
  @Action public downloadVaccinationCertification: any;

  selected: any = [];
  allSelected = false;
  filter: any = {
    where: {
      is_medical_rep: true,
      status: {
        "eq": 0
      },
      userTypesId: {
        "eq": 3
      },
    },
    include: [
          {
            relation: "userOrganizationTypes",
            scope: {
              fields: [
                "organizationTypeId",
                "userId"
              ],
              include: [
                {
                  relation: "organization_type",
                  scope: {
                    fields: [
                      "id",
                      "name"
                    ]
                  }
                }
              ]
            }
          },
          {
            relation: "userPreferences",
            scope: {
              fields: [
                "userId",
                "is_email_verified",
                "is_phone_verified"
              ],
            }
          }
        ],
    order: ["first_name ASC"],
  };
  searchText = "";
  activeTabName = "UNAPPROVED_USERS";
  $refs!: {
    cancelMeetingModalRef: HTMLFormElement;
  };

  beforeMount() {
    this.getPendingUsers(this.filter);
  }

  toggleOption(checked: any) {
    this.selected = checked
      ? this.pendingUsers &&
        this.pendingUsers.map((option: any) => {
          return option;
        })
      : [];
  }

  repOrganizationTypes(orgTypes: any) {
    let repOrgType: any = [];
    orgTypes.forEach((orgType: any) => {
      repOrgType.push(orgType.organization_type.name);
    });
    return repOrgType.toString();
  }

  openReasonModal() {
    const cancelMeetingModal = this.$refs.cancelMeetingModalRef;
    cancelMeetingModal.show();
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.filter.where.and = [
        {
          or: [
            { first_name: { ilike: `%${this.searchText}%` } },
            { last_name: { ilike: `%${this.searchText}%` } },
            { email: { ilike: `%${this.searchText}%`} },
          ]
        }
      ];
      this.getPendingUsers(this.filter);
    }
  }

  refresh(){
    this.getPendingUsers(this.filter);
  }

  onApprove() {
    if (this.selected && this.selected.length > 0) {
      const msg = approveEmailAlert.message.replace(
        "approve of",
        `approve ${this.selected.length} of`
      );
      const domains = this.selected.map((selectedUser: any) => {
        return selectedUser.email.split("@")[1];
      });
      const uniqueDomains = [...new Set(domains)];
      const selectedIds = this.selected.map((selectedUser: any) => {
        return selectedUser.id;
      });
      const domainMessage = approveAllDomainEmailAlert.message.replace(
        "?",
        ` (${uniqueDomains.toString()}) ?`
      );
      alert.methods.conFirmAlert(
        { ...approveEmailAlert, message: msg },
        () => {
          setTimeout(() => {
            alert.methods.conFirmAlert(
              { ...approveAllDomainEmailAlert, message: domainMessage },
              () => {
                const data = {
                  ids: selectedIds,
                  status: 1,
                  allow_all_reps: false,
                  is_rejected_reps: this.activeTabName === "REJECTED_USERS",
                };
                this.bulkUpdate({data,callback: ()=>{
                  this.getPendingUsers(this.filter);
                }});
                this.selected = [];
              },
              () => {
                const data = {
                  ids: selectedIds,
                  status: 1,
                  allow_all_reps: true,
                  is_rejected_reps: this.activeTabName === "REJECTED_USERS",
                };
                this.bulkUpdate({data,callback: ()=>{
                  this.getPendingUsers(this.filter);
                }});
                this.selected = [];
              }
            );
          }, 300);
        },
        () => {}
      );
    } else {
      alert.methods.appToaster({
        message: constants.PLEASE_SELECT_USER_TO_PROCEED,
        type: "WARNING",
      });
    }
  }

  // onRejectWithReason(reason: string) {
  //   this.onReject();
  // }

  onReject(reason: any) {
    if (this.selected && this.selected.length > 0) {
      const msg = rejectEmaillAlert.message.replace(
        "reject of",
        `reject ${this.selected.length} of`
      );
      const selectedIds = this.selected.map((selectedUser: any) => {
        return selectedUser.id;
      });
      alert.methods.conFirmAlert(
        { ...rejectEmaillAlert, message: msg },
        () => {
          const data = {
            ids: selectedIds,
            status: 9,
            reason: reason.reason
          };
          this.bulkUpdate({data,callback: ()=>{
                  this.getPendingUsers(this.filter);
                }});
          this.selected = [];
        },
        () => {}
      );
    } else {
      alert.methods.appToaster({
        message: constants.PLEASE_SELECT_USER_TO_PROCEED,
        type: "WARNING",
      });
    }
  }

  onClear() {
    this.searchText = '';
    this.onChangeFilterInput();
  }

  @Watch("selected")
  onSelected(newValue: any): void {
    if (newValue.length === this.pendingUsers.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  @Watch("pendingUsers")
  onPendingUsersChange(newValue: any): void {
    if (this.$route.query.e && newValue) {
      this.selected = newValue
        .filter((val: any) => {
          if (this.$route.query.e === val.email) return val;
        })
        .map((obj: any) => {
          return obj;
        });
    }
  }

  changeTab(tab: string): void {
    this.activeTabName = tab;
    if(this.selected && this.selected.length > 0) {
      this.selected = [];
      this.allSelected = false;
    }
    this.searchText = '';
    delete this.filter.where.and;
    if(tab === "UNAPPROVED_USERS") {
      this.filter.where.status = { "eq": 0 };
      this.getPendingUsers(this.filter);
    } else if(tab === "REJECTED_USERS") {
      this.filter.where.status = { "eq": 9 };
      this.getPendingUsers(this.filter);
    }
  }

  downloadVaccinationCertificate(certificateUrl: any) {
    this.downloadVaccinationCertification(certificateUrl);
  }
}
