import { IMeeting } from "@/model/index";

/**
 * ==============
 * Initial state of Meeting module
 */
export class State {
  public meetingInitialData: IMeeting = {
    meeting_name: "",
    start_time: "",
    end_time: "",
    supporting_material: "",
    description: "",
    topicsId: null,
    brandsId: null,
    categoriesId: null,
    location: "",
    meeting_type: "",
    is_notify: false,
    status: true,
    participants: [],
    file: null,
    zoom_nonzoom_meeting: null,
    nonzoom_meeting_link: "",
    restaurantsId: null
  };

  public meeting: IMeeting = this.meetingInitialData;
  public meetings: any = [];
  public meetingsLoading = false;
  public meetingsTotalCount = 0;
  public brands = [];
  public topics = [];
  public categories = [];
  public locations: any = [];
  public physicians = [];
  public error: any;
  public activeTab = "UPCOMING";
  public meetingId = 0;
  public showRecommendationModal = false;
  public recommendations = [];
  public recommendedPhysicians = [];
}
