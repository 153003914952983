// import { IMessageParticipants, IMessage } from "@/model/index";

/**
 * ==============
 * Initial state of Message module
 */

export class State {

  // public messageParticpantsInitialData: IMessageParticipants {
  //   lastMessage: "",
  //   senderName: "",
  //   updatedAt: "",
  //   unreadCount: null,
  //   participantId: null,
  //   msgTo: null
  // };
  // public messageParticpant: IMessageParticipants = this.messageParticpantsInitialData;

  public messageInitialData = {
    body: "",
    messageParticipantsId: "",
    msg_to: ""
  };
  public message = this.messageInitialData;

  public messageParticipantInitialData = {
    user_id1: "",
    user_id2: ""
  };
  public messageParticipant = this.messageParticipantInitialData;

  public error = '';
  public existingChats = [];
  public chatMessages = [];
  public messages = [];
  public messageParticipantsLoading = false;
  public messagesLoading = false;
  public messageParticipants: any = [];
  public newUsersContactList: any = [];
  public unreadTotalCount = 0;
  public repNameToMessage = '';
}
