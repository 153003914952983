import types from "./mutation-types";
import RestaurantProxy from "@/proxies/RestaurantProxy";
import loadingTypes from "@/store/mutation-types";
import alert from "@/mixins/alert";
import msgConstants from "@/constants/_message";

const initNewRestaurant = ({ commit }: any) => {
  commit(types.INIT_NEW_RESTAURANT);
};

const createRestaurant = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new RestaurantProxy().create(data).then((response: any) => {
    commit(types.CREATE_NEW_RESTAURANT_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.RESTAURANT_CREATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.CREATE_NEW_RESTAURANT_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
}

const getRestaurantById = ({ commit }: any, id: string) => {
  commit(loadingTypes.START_LOADING);
  new RestaurantProxy()
    .getById(id)
    .then((response: any) => {
      commit(types.GET_RESTAURANT_BY_ID_SUCCESS, response);
      commit(loadingTypes.END_LOADING);
    })
    .catch((error: any) => {
      commit(types.GET_RESTAURANT_BY_ID_FAIL, error);
      commit(loadingTypes.END_LOADING);
    });
};

const getRestaurants = ({ commit }: any, data: any) => {
  commit(loadingTypes.START_LOADING);
  new RestaurantProxy().getRestaurants(data).then((response: any) => {
    commit(types.GET_RESTAURANT_LIST_SUCCESS, response);
    commit(loadingTypes.END_LOADING);
  }).catch((error: any) => {
    commit(types.CREATE_NEW_RESTAURANT_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const updateRestaurant = ({ commit }: any, { data, callBack }: any) => {
  commit(loadingTypes.START_LOADING);
  new RestaurantProxy().updateRestaurant(data).then(() => {
    commit(loadingTypes.END_LOADING);
    alert.methods.appToaster({ message: msgConstants.RESTAURANT_UPDATE_SUCCESS, type: "SUCCESS" });
    callBack();
  }).catch((error: any) => {
    commit(types.GET_RESTAURANT_BY_ID_FAIL, error.error);
    alert.methods.appToaster({ message: error.error.message, type: "ERROR" });
    commit(loadingTypes.END_LOADING);
  });
};

const getRestaurantCount = ({ commit }: any, data: any): void => {
  commit(loadingTypes.START_LOADING);
  new RestaurantProxy()
    .getListCount(data)
    .then((response: any) => {
      commit(types.GET_RESTAURANT_COUNT, response);
      commit(loadingTypes.END_LOADING);
    }).catch((error: any) => {
      commit(types.GET_RESTAURANT_LIST_FAIL, error.error);
      commit(loadingTypes.END_LOADING);
    })
};

export default {
  initNewRestaurant,
  createRestaurant,
  getRestaurants,
  getRestaurantById,
  updateRestaurant,
  getRestaurantCount,
};