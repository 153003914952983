

















































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import moment from "moment";
import _ from "lodash";

@Component({
  components: {},
})
export default class PhysicianForm extends Vue {
  @Action public createPhysician: any;
  @Action public initNewPhysicianUser: any;
  @Action public setUser: any;
  @Action private getPhysicianById: any;
  @Action private updatePhysician: any;
  @Action public getLocationDropdown: any;
  @Getter public physician: any;
  @Getter public locations: any;
  @Getter public currentUser: any;
  @Getter public role!: any;
  notification = false;
  action = "add";
  isSameAsEmail = false;
  isSubmitted = false;
  isDisabled = false;
  $refs!: {
    physicianForm: InstanceType<typeof ValidationObserver>;
  };
  isScheduleReady = false;
  showSchedule = true;
  activeTabName = "BASIC_INFO";
  weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  physicianSchedule = [
    {
      locationId: 0,
      weekday: 0,
      available: "AM"
    },
    {
      locationId: 0,
      weekday: 0,
      available: "PM"
    },
    {
      locationId: 0,
      weekday: 1,
      available: "AM"
    },
    {
      locationId: 0,
      weekday: 1,
      available: "PM"
    },
    {
      locationId: 0,
      weekday: 2,
      available: "AM"
    },
    {
      locationId: 0,
      weekday: 2,
      available: "PM"
    },
    {
      locationId: 0,
      weekday: 3,
      available: "AM"
    },
    {
      locationId: 0,
      weekday: 3,
      available: "PM"
    },
    {
      locationId: 0,
      weekday: 4,
      available: "AM"
    },
    {
      locationId: 0,
      weekday: 4,
      available: "PM"
    },
    {
      locationId: 0,
      weekday: 5,
      available: "AM"
    },
    {
      locationId: 0,
      weekday: 5,
      available: "PM"
    },
    {
      locationId: 0,
      weekday: 6,
      available: "AM"
    },
    {
      locationId: 0,
      weekday: 6,
      available: "PM"
    },
  ];
  physicianUserType = [
    {
      name: 'User/Physician',
      value: true
    },
    {
      name: 'Physician',
      value: false
    },
  ];
  isCheckedSameLocationAllDay = false;
  isCheckedSameLocationAllAM = false;
  isCheckedSameLocationAllPM = false;
  switchTimeSelect = 'AM';
  switchTimeOptions = [
    { text: 'AM', value: 'AM' },
    { text: 'PM', value: 'PM' },
  ];
  AMPMSelected = [
    { weekday: 0, selectedOption: ''},
    { weekday: 1, selectedOption: ''},
    { weekday: 2, selectedOption: ''},
    { weekday: 3, selectedOption: ''},
    { weekday: 4, selectedOption: ''},
    { weekday: 5, selectedOption: ''},
    { weekday: 6, selectedOption: ''},
  ]
  AMPMOptions = [
    { value: 'AM', text: 'AM' },
    { value: 'PM', text: 'PM' },
    { value: 'AM/PM', text: 'AM/PM Both' },
  ]
  isCreatingUserComePhysician = false;

  beforeMount() {
    this.setUser({});
    this.initNewPhysicianUser();
    this.getLocationDropdown({
      fields: { id: true, name: true },
      where: { status: { eq: true } },
      order: ["name ASC"],
    });
    const userId = this.$route.params.id;
    if (userId) {
      this.action = "edit";
      this.getPhysicianById({
        id: userId,
        filter: {
          fields: {
            created_at: false,
            updated_at: false,
            created_by: false,
            updated_by: false,
          },
          include: [
            {
              relation: "physiciansAvailabilities",
              scope: { fields: ["id", "weekday", "locationId", "physicianId", "available"] },
            },
          ],
        },
        callBack: this.setLocation
      });
      this.isDisabled = true;
    }
    setTimeout(() => {
        this.isScheduleReady = true;
    }, 500);
  }

  changeTab(tab: string): void {
    if (tab === "BASIC_INFO") {
      this.activeTabName = "BASIC_INFO";
    } else {
      this.activeTabName = "SCHEDULE";
    }
  }

  setPhysicianSchedule(locationId: number, weekday: number, available: string) {
    this.physicianSchedule.forEach((physicianSchedule: any) => {
      if (physicianSchedule.weekday === weekday && physicianSchedule.available === available) {
        physicianSchedule.locationId = locationId !== null ? locationId : 0;
      }
    });
    if (this.isCheckedSameLocationAllDay) {
      this.isCheckedSameLocationAllDay = false;
    }
    if(this.isCheckedSameLocationAllAM && available == 'AM') {
      this.isCheckedSameLocationAllAM = false;
    }
    if(this.isCheckedSameLocationAllPM && available == 'PM') {
      this.isCheckedSameLocationAllPM = false;
    }
  }

  getPhysicianSchedule(weekday: number, available: string) {
    let availability = {}
      availability =  this.physicianSchedule.filter((physicianSchedule: any) => { return physicianSchedule.weekday === weekday && physicianSchedule.available == available });
      return availability[0] ? availability[0].locationId : 0;
  }

  selectSameForAllDay(checked: any) {
    this.isCheckedSameLocationAllDay = checked
    if (this.isCheckedSameLocationAllDay) {
      this.physicianSchedule.forEach((physicianScheduleData: any) => {
        if (physicianScheduleData.locationId > 0) {
          this.physicianSchedule.forEach((physicianData: any) => {
            physicianData.locationId = physicianScheduleData.locationId
          })
        }
      });
      this.refreshSchedule();
    }
  }

  sameLocationForAM(checked: any) {
    this.isCheckedSameLocationAllAM = checked
    if (checked) {
      this.physicianSchedule.forEach((schedule: any, index: number) => {
        if (index % 2 == 0 && schedule.locationId > 0) {
          this.physicianSchedule.forEach((availability: any, index1: number) => {
            if(index1 % 2 == 0) {
              availability.locationId = schedule.locationId;
            }
          });
          this.refreshSchedule();
          return;
        }
      })
    }
  }

  sameLocationForPM(checked: any) {
    this.isCheckedSameLocationAllPM = checked;
    if (checked) {
      this.physicianSchedule.forEach((schedule: any, index: number) => {
        if (index % 2 !== 0 && schedule.locationId > 0) {
          this.physicianSchedule.forEach((availability: any, index1: number) => {
            if(index1 % 2 !== 0) {
              availability.locationId = schedule.locationId;
            }
          });
          this.refreshSchedule();
          return;
        }
      })
    }
  }

  refreshSchedule() {
    this.showSchedule = false;
    setTimeout(() => {
      this.showSchedule = true;
    }, 100);
  }

  setPhysicianUserType(value: any) {
    this.isCreatingUserComePhysician = value;
  }

  setLocation() {
    if(this.physician.physiciansAvailabilities && this.physician.physiciansAvailabilities.length > 0) {
      this.physician.physiciansAvailabilities.forEach((availability: any) => { 
        if(availability.available == 'AM/PM') {
          this.setPhysicianSchedule(availability.locationId, availability.weekday -1, 'AM');
          this.setPhysicianSchedule(availability.locationId, availability.weekday -1, 'PM');
        } else {
          this.setPhysicianSchedule(availability.locationId, availability.weekday -1, availability.available);
        }
      });
    }
    setTimeout(() => {
      this.isScheduleReady = true;
      this.refreshSchedule();
    }, 800);
  }

  destroyed() {
    this.initNewPhysicianUser();
  }

  clearAllEntries() {
    const msg = 'Are you sure you want to clear all schedule of this physician?';
    alert.methods.conFirmAlert(
      { ...cancelMeetingAlert, message: msg },
      () => {
        for (let index = 0; index < 14; index++) {
          this.physicianSchedule[index].locationId = 0;
        }
        this.refreshSchedule();
      },
      () => {}
    );
    
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.physicianForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }
        const errors = Object.entries(this.$refs.physicianForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.physicianForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        this.changeTab("BASIC_INFO");
        return;
      } else {
        if (this.action === "add") {
          this.physician.tenantId = this.currentUser.tenantId;
          if(this.isCreatingUserComePhysician) {
            this.physician.userTypesId = 5;
            this.physician.availability = this.physicianSchedule;
            this.physician.usertypes = true;
          } else {
            delete this.physician.userTypesId;
            this.physician.usertypes = false;
          }
          this.physician.optout_topic_ids = [];
          this.physician.optout_brand_ids = [];
          this.physician.optout_category_ids = [];
          this.physician.created_at = moment().format();
          let availability;
          let temp  = this.physicianSchedule.filter((schedule: any) => { 
            if (schedule.locationId !== 0) {
              schedule.weekday = schedule.weekday + 1;
              return schedule
            }
          });
          if(this.currentUser.tenant.allow_weekend_meetings) {
            availability =  temp;
          } else {
            temp = temp.filter((schedule: any) => { return schedule.weekday !== 6 });
            availability = temp.filter((schedule: any) => { return schedule.weekday !== 7 });
          }
          const grouped = Object.values(_.groupBy(availability, (value) => value.weekday));
          availability = [];
          grouped.forEach((groupedValue: any) => {
            if(groupedValue.length > 1) {
              if(groupedValue[0].locationId === groupedValue[1].locationId) {
                let data = {
                  locationId: groupedValue[0].locationId,
                  weekday: groupedValue[0].weekday,
                  available: "AM/PM"
                }
                availability = availability.concat(data);
              } else {
                availability = availability.concat(groupedValue[0]);
                availability = availability.concat(groupedValue[1]);
              }
            } else {
              availability = availability.concat(groupedValue[0]);
            }
          });
          this.physician.availability = availability;
          this.createPhysician({
            data: this.physician,
            callBack: () => {
              this.$router.push({ name: "physicians.index" }).catch();
            },
          });
        } else {
          if (this.physician.address === null) {
            this.physician.address = "";
          }
          if (this.physician.zipcode === null) {
            this.physician.zipcode = "";
          }
          if (this.physician.contact_no === null) {
            this.physician.contact_no = "";
          }
          if (this.physician.optout_topic_ids === null) {
            this.physician.optout_topic_ids = [];
          }
          if (this.physician.optout_brand_ids === null) {
            this.physician.optout_brand_ids = [];
          }
          if (this.physician.optout_category_ids === null) {
            this.physician.optout_category_ids = [];
          }
          if (this.physician.required_email_notification === null) {
            this.physician.required_email_notification = false;
          }
          const {
            id,
            prefix,
            first_name,
            last_name,
            email,
            contact_no,
            npi_no,
            department_name,
            location,
            address,
            zipcode,
            optout_topic_ids,
            optout_brand_ids,
            optout_category_ids,
            required_email_notification
          } = this.physician;
          let availability;
          let temp = this.physicianSchedule.filter((schedule: any) => { 
             if (schedule.locationId !== 0) {
              schedule.weekday = schedule.weekday + 1;
              return schedule
            }
           });
          if(this.currentUser.tenant.allow_weekend_meetings) {
            availability =  temp;
          } else {
            temp = temp.filter((schedule: any) => { return schedule.weekday !== 6 });
            availability = temp.filter((schedule: any) => { return schedule.weekday !== 7 });
          }
          const grouped = Object.values(_.groupBy(availability, (value) => value.weekday));
          availability = [];
          grouped.forEach((groupedValue: any) => {
            if(groupedValue.length > 1) {
              if(groupedValue[0].locationId === groupedValue[1].locationId) {
                let data = {
                  locationId: groupedValue[0].locationId,
                  weekday: groupedValue[0].weekday,
                  available: "AM/PM"
                }
                availability = availability.concat(data);
              } else {
                availability = availability.concat(groupedValue[0]);
                availability = availability.concat(groupedValue[1]);
              }
            } else {
              availability = availability.concat(groupedValue[0]);
            }
          });
          this.updatePhysician({
            data: {
              id,
              prefix,
              first_name,
              last_name,
              email,
              contact_no,
              npi_no,
              department_name,
              location,
              address,
              zipcode,
              availability,
              optout_topic_ids,
              optout_brand_ids,
              optout_category_ids,
              required_email_notification
            },
            callBack: () => {
              this.$router.push({ name: "physicians.index" }).catch();
            },
          });
        }
      }
    });
  }

  changeStatus(value:any) {
  this.physician.required_email_notification = value;
  this.notification = value;
  }

  onCancel(): void {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.$router.push({ name: "physicians.index" }).catch();
      },
      () => {}
    );
  }
}
