/* ============
 * Mutation  for the Tenant module
 * ============
 *
 * The mutation types that are available
 * on the Tenant module.
 */

import { MutationTree } from "vuex";
import { State } from "./state";
import types from "./mutation-types";

export default {

  [types.GET_TENANTS_SUCCESS](state: State, response: any) {
    state.tenants = response;
  },
  [types.GET_TENANTS_FAIL](state: State, error: any) {
    state.error = error;
  },

} as MutationTree<State>;
