






























































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component({})
export default class AgreementModal extends Vue {
  @Prop({ default: "md" }) public size!: any;

  show() {
    this.$bvModal.show("agreement-modal");
  }

  hide() {
    this.$bvModal.hide("agreement-modal");
  }

  @Emit("agreementAgreed")
  agreeAgreement() {
    this.hide();
    return true;
  }

  @Emit("agreementAgreed")
  cancelAgreement() {
    this.hide();
    return false;
  }
}
